import { Component, OnInit } from "@angular/core";
import Chart from "chart.js/auto";
import * as moment from "moment";
import { DoctorDashboardService } from "src/app/services/doctor-dashboard.service/doctor-dashboard.service";

@Component({
  selector: "app-chart-patient",
  templateUrl: "./chart-patient.component.html",
  styleUrls: ["./chart-patient.component.css"],
})
export class ChartPatientComponent implements OnInit {
  constructor(private doctorDashboardService: DoctorDashboardService) { }
  public chart!: Chart;
  public patients: Array<number> = [];
  debutSemaine: number = 0;
  finSemaine: number = 0;

  ngOnInit() {
    const semaine = this.inetrval()
    this.debutSemaine = semaine[0];
    this.finSemaine = semaine[6]
    this.staticPatient();

  }

  dateApresDayActule(): number {
    const dateActuelle = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    // Calculez la date de fin de la semaine en ajoutant 7 jours à la date actuelle
    var dateFinSemaine = new Date(dateActuelle);
    var dateFinSemaineInter = new Date(dateActuelle);

    const startDate = dateFinSemaineInter.setDate(dateActuelle.getDate() - 7);

    return startDate;
  }
  dateActule(): number {
    const endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime();
    return endDate;
  }
  inetrval(): any {
    const dateActuelle = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    var dateFinSemaine = new Date(dateActuelle);
    const intervialle = [
      dateFinSemaine.setDate(dateActuelle.getDate() - 7),
      new Date(dateActuelle).setDate(dateActuelle.getDate() - 6),
      new Date(dateActuelle).setDate(dateActuelle.getDate() - 5),
      new Date(dateActuelle).setDate(dateActuelle.getDate() - 4),
      new Date(dateActuelle).setDate(dateActuelle.getDate() - 3),
      new Date(dateActuelle).setDate(dateActuelle.getDate() - 2),
      new Date(dateActuelle).setDate(dateActuelle.getDate() - 1),
      new Date(dateActuelle).setDate(dateActuelle.getDate()),
    ];
    return intervialle;
  }
  staticPatient() {
    const startDate = this.dateApresDayActule();
    const endDate = this.dateActule();
    const intervialle = this.inetrval();
    this.doctorDashboardService.staticPatient(startDate, endDate).subscribe((res) => {
      for (let date of intervialle) {
        let found = false;
        if (res !== null) {
          for (let item of res) {
            if (item.creationDate === date) {
              this.patients.push(item.pCount);
              found = true;
              break;
            }
          }
        }
        if (!found) {
          this.patients.push(0);
        }
      }
      this.chart = new Chart("canvas", {
        type: "line",

        data: {
          labels: intervialle.map((elm) => moment(new Date(elm)).format("DD/MM/YYYY")),
          datasets: [
            {
              label: "Nombre de Patients",
              data: this.patients,
              fill: true,

              tension: 0.6,

              backgroundColor: [
                "transparent"

              ],
              borderColor: [
                "rgba(168, 50, 117,1)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",

              ],
              borderWidth: 1,
            },
          ],
        },
        options: {
          color: "#a83275"
        },
      });
    });
  }
}