<div *ngIf="dir=='fr'">
  <ng-container *ngIf="doctor.appointmentTiming==0">
    <h4 class="appointment-form-title">Choisissez votre type de consultation :</h4>

    <div class="consultation-settings">
      <!-- <select [(ngModel)]="patientAppointment.location" (ngModelChange)="onAvailabilityLocationChange()" name="location" class="form-control">
                <option [value]="0" *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2">En
                    Cabinet</option>
                <option [value]="1" *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2">A
                    Distance</option>
            </select> -->
      <!-- <div class="form-group" style="text-align: center;">
        <label for="gender" style="color: black;">En Cabinet </label>
        <input
          type="radio"
          name="gender"
          [(ngModel)]="patientAppointment.location"
          (ngModelChange)="onAvailabilityLocationChange()"
          *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2"
          [value]="0"
          style="margin: 8px;"
        />
        <label for="gender" style="color: black;">A Distance</label>
        <input
          type="radio"
          name="gender"
          [(ngModel)]="patientAppointment.location"
          (ngModelChange)="onAvailabilityLocationChange()"
          [value]="1"
          *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2"
          style="margin: 8px;"
        />
      </div> -->
      <div class="container">
        <form>
          <label>
            <input type="radio"  type="radio"
            name="gender"
            [(ngModel)]="patientAppointment.location"
            (ngModelChange)="onAvailabilityLocationChange()"
            *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2"
            [value]="0"/>
            <span>En Cabinet</span>
          </label>
          <label>
            <input type="radio"  type="radio"
            name="gender"
            [(ngModel)]="patientAppointment.location"
            (ngModelChange)="onAvailabilityLocationChange()"
            [value]="1"
            *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2"/>
            <span>A Distance</span>
          </label>
         
        </form>
      </div>
      <select [(ngModel)]="patientAppointment.motif" name="motif" class="form-control">
        <option [value]="'Consultation Régulière'" selected>Consultation Régulière</option>
        <option [value]="'Consultation Controle'" selected>Consultation Controle</option>
        <option *ngFor="let motif of consultationMotifs" [value]="motif.text" selected [ngStyle]="{'color':motif.color || '#820040'}"
          ><span [ngStyle]="{'color':motif.color || '#820040'}">{{motif.text}}</span></option
        >
      </select>
    </div>
    <h4 class="appointment-form-title">Veuillez choisir la date et l'heure du rendez-vous:</h4>
    <div class="card">
      <app-doctor-availabilities-calender
        [doctor]="doctor"
        [location]="patientAppointment.location"
        [disponibility]="patientAppointment.doctorAvailability"
        [privacy]="false"
        (disponibilityChange)="onAvailabilityChange($event)"
        [availabilitiesRequest]="availabilitiesRequest"
      ></app-doctor-availabilities-calender>
      <span *ngIf="validating && !(patientAppointment.startDateTime.getTime()>currentDate.getTime())" class="invalid-feedback"
        >Merci de spécifier l'heure de rendez-vous</span
      >
    </div>
  </ng-container>
  <ng-container *ngIf="doctor.appointmentTiming==1">
    <h4 class="appointment-form-title">Choisissez votre type de consultation :</h4>
    <div class="consultation-settings">
      <!-- <select [(ngModel)]="patientAppointment.location" name="location" class="form-control">
                <option [value]="0" *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2">En
                    Cabinet</option>
                <option [value]="1" *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2">A
                    Distance</option>
            </select> -->
      <div class="form-group" style="text-align: center;">
        <label for="gender" style="color: black;">En Cabinet </label>
        <input
          type="radio"
          name="gender"
          [(ngModel)]="patientAppointment.location"
          *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2"
          [value]="0"
          style="margin: 8px;"
        />
        <label for="gender" style="color: black;">A Distance</label>
        <input
          type="radio"
          name="gender"
          [(ngModel)]="patientAppointment.location"
          [value]="1"
          *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2"
          style="margin: 8px;"
        />
      </div>
      <select [(ngModel)]="patientAppointment.motif" name="motif" class="form-control">
        <option [value]="'Consultation Régulière'" selected>Consultation Régulière</option>
        <option [value]="'Consultation Controle'" selected>Consultation Controle</option>
        <option *ngFor="let motif of consultationMotifs" [value]="motif.text" selected [ngStyle]="{'color':motif.color || '#820040'}"
          ><span [ngStyle]="{'color':motif.color || '#820040'}">{{motif.text}}</span></option
        >
      </select>
    </div>
    <h4 class="appointment-form-title">Veuillez choisir la date du rendez-vous:</h4>
    <div class="card">
      <app-doctor-days-calander
        [mode]="'DATE'"
        [doctor]="doctor"
        [dir]="dir"
        (dayChange)="patientAppointment.date = $event"
      ></app-doctor-days-calander>
    </div>
  </ng-container>
  <ng-container *ngIf="doctor.appointmentTiming==2">
    <h4 class="appointment-form-title">Choisissez votre type de consultation :</h4>
    <div class="consultation-settings">
      <!-- <select [(ngModel)]="patientAppointment.location" name="location" class="form-control">
                <option [value]="0" *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2">En
                    Cabinet</option>
                <option [value]="1" *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2">A
                    Distance</option>
            </select> -->
      <div class="form-group" style="text-align: center;">
        <label for="gender" style="color: black;">En Cabinet </label>
        <input
          type="radio"
          name="gender"
          [(ngModel)]="patientAppointment.location"
          *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2"
          [value]="0"
          style="margin: 8px;"
        />
        <label for="gender" style="color: black;">A Distance</label>
        <input
          type="radio"
          name="gender"
          [(ngModel)]="patientAppointment.location"
          [value]="1"
          *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2"
          style="margin: 8px;"
        />
      </div>
      <select [(ngModel)]="patientAppointment.motif" name="motif" class="form-control">
        <option [value]="'Consultation Régulière'" selected>Consultation Régulière</option>
        <option [value]="'Consultation Controle'" selected>Consultation Controle</option>
        <option *ngFor="let motif of consultationMotifs" [value]="motif.text" selected [ngStyle]="{'color':motif.color || '#820040'}"
          ><span [ngStyle]="{'color':motif.color || '#820040'}">{{motif.text}}</span></option
        >
      </select>
    </div>
    <h4 class="appointment-form-title">Veuillez choisir la date du rendez-vous:</h4>
    <div class="card">
      <app-doctor-days-calander
        [mode]="'DATE_TIME_SLOT'"
        [doctor]="doctor"
        (dayTimeSlotsChange)="dayTimeSlots = $event"
        (dayTimeSlotsChange)="patientAppointment.startTime  = (dayTimeSlots || [])[0]?.startTime || 0"
        [day]="calenderDate"
        (dayChange)="onCalanderDaySelected($event)"
      ></app-doctor-days-calander>
    </div>
    <h4 *ngIf="calenderDate" class="appointment-form-title">Veuillez choisir l'heure du rendez-vous:</h4>
    <app-time-input [(time)]="patientAppointment.startTime" (timeChange)="validateTime()"></app-time-input>
    <span *ngIf="validating && !validateTime()" style="color: red;">Date heur n 'existe pas dans le plage horaire de ce jour </span>
  </ng-container>
</div>

<div *ngIf="dir=='ar'" dir="rtl">
  <ng-container *ngIf="doctor.appointmentTiming==0">
    <h4 class="appointment-form-title">اختر نوع الاستشارة الخاصة بك:</h4>

    <div class="consultation-settings">
      <select
        [(ngModel)]="patientAppointment.location"
        (ngModelChange)="onAvailabilityLocationChange()"
        name="location"
        class="form-control"
      >
        <option [value]="0" *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2">في المكتب</option>
        <option [value]="1" *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2">عن بعد</option>
      </select>
      <select [(ngModel)]="patientAppointment.motif" name="motif" class="form-control">
        <option [value]="'Consultation Régulière'" selected>استشارة منتظمة</option>
        <option [value]="'Consultation Controle'"> استشارة مراقبة</option>
      </select>
    </div>
    <h4 class="appointment-form-title">الرجاء اختيار تاريخ ووقت الموعد:</h4>
    <div class="card">
      <!-- [disponibility]="patientAppointment.doctorDisponibility" -->
      <app-doctor-availabilities-calender
        [doctor]="doctor"
        [dir]="dir"
        [location]="patientAppointment.location"
        [disponibility]="patientAppointment.doctorAvailability"
        [privacy]="false"
        (disponibilityChange)="onAvailabilityChange($event)"
        [availabilitiesRequest]="availabilitiesRequest"
      ></app-doctor-availabilities-calender>
      <span *ngIf="validating && !(patientAppointment.startDateTime.getTime()>currentDate.getTime())" class="invalid-feedback"
        >يرجى تحديد وقت الموعد</span
      >
    </div>
  </ng-container>
  <ng-container *ngIf="doctor.appointmentTiming==1">
    <h4 class="appointment-form-title">اختر نوع الاستشارة الخاصة بك:</h4>
    <div class="consultation-settings">
      <select [(ngModel)]="patientAppointment.location" name="location" class="form-control">
        <option [value]="0" *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2">في المكتب</option>
        <option [value]="1" *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2">عن بعد</option>
      </select>
      <select [(ngModel)]="patientAppointment.motif" name="motif" class="form-control">
        <option [value]="'استشارة منتظمة'" selected>استشارة منتظمة</option>
        <option [value]="' استشارة مراقبة'" selected> استشارة مراقبة</option>
      </select>
    </div>
    <h4 class="appointment-form-title">الرجاء اختيار تاريخ الموعد</h4>
    <div class="card">
      <app-doctor-days-calander
        [mode]="'DATE'"
        [doctor]="doctor"
        [dir]="dir"
        (dayChange)="patientAppointment.date = $event"
      ></app-doctor-days-calander>
    </div>
  </ng-container>
  <ng-container *ngIf="doctor.appointmentTiming==2">
    <h4 class="appointment-form-title">اختر نوع الاستشارة الخاصة بك:</h4>
    <div class="consultation-settings">
      <select [(ngModel)]="patientAppointment.location" name="location" class="form-control">
        <option [value]="0" *ngIf="doctor.timeSlotLocalSetting==0 || doctor.timeSlotLocalSetting==2">في المكتب</option>
        <option [value]="1" *ngIf="doctor.timeSlotLocalSetting==1 || doctor.timeSlotLocalSetting==2">عن بعد</option>
      </select>
      <select [(ngModel)]="patientAppointment.motif" name="motif" class="form-control">
        <option [value]="'استشارة منتظمة'" selected>استشارة منتظمة</option>
        <option [value]="' استشارة مراقبة'" selected> استشارة مراقبة</option>
      </select>
    </div>
    <h4 class="appointment-form-title">الرجاء اختيار تاريخ الموعد:</h4>
    <div class="card">
      <app-doctor-days-calander
        [mode]="'DATE_TIME_SLOT'"
        [doctor]="doctor"
        (dayTimeSlotsChange)="dayTimeSlots = $event"
        [dir]="dir"
        (dayTimeSlotsChange)="patientAppointment.startTime  = (dayTimeSlots || [])[0]?.startTime || 0"
        [day]="calenderDate"
        (dayChange)="onCalanderDaySelected($event)"
      ></app-doctor-days-calander>
    </div>
    <h4 *ngIf="calenderDate" class="appointment-form-title">الرجاء اختيار وقت الموعد:</h4>
    <app-time-input [(time)]="patientAppointment.startTime" (timeChange)="validateTime()"></app-time-input>
    <span *ngIf="validating && !validateTime()" style="color: red;">التاريخ والوقت غير موجود في النطاق الزمني لهذا اليوم </span>
  </ng-container>
</div>
