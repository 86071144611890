<nav class="navbar rounded navbar-expand-lg   shadow-5-strong mb-2 ">
  <!-- Container wrapper -->
  <div class="container-fluid">

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Left links -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" [ngClass]="{'active':url.endsWith('antecedents')}" [routerLink]="['/doctor/appointments/', this.appointment.id,
            'consultation',this.appointment.patient.id,'antecedent']">Antécédents</a>
        </li>
        <!-- Navbar dropdown -->
        <li class="nav-item dropdown">
          <a class="nav-link  dropdown-toggle" [ngClass]="{'active':url.endsWith('consultation')}"
            data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Consultation</a>
          <ul class="dropdown-menu">

            <li><a class="dropdown-item" [routerLink]="['/doctor/appointments',
          appointment.id,
           'consultation'
           ]">Creation</a></li>
            <li>
              <a class="dropdown-item" [routerLink]="[
                '/doctor/appointments',
                this.appointment.id,
                'consultation',
                'List'
              ]">Historique</a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link  dropdown-toggle" [ngClass]="{'active':url.endsWith('prescriptions')}"
            data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Prescription</a>
          <ul class="dropdown-menu">

            <li><a class="dropdown-item" [routerLink]="[
          '/doctor/appointments/',
          appointment.id,
          'assign-prescription'
        ]">Creation</a></li>
            <li>
              <a class="dropdown-item"
                [routerLink]="['/doctor/appointments/', this.appointment.id,'consultation',this.appointment.patient.id,'prescription']">Historique</a>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link  dropdown-toggle" [ngClass]="{'active':url.endsWith('Documents')}"
            data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">Documents</a>
          <ul class="dropdown-menu">

            <li><a class="dropdown-item">Creation</a></li>
            <li>
              <a class="dropdown-item"
                [routerLink]="['/doctor/appointments',this.appointment.id,'consultation','List']">Historique</a>
            </li>
          </ul>
        </li>

        <li class="nav-item">
          <a class="nav-link " [ngClass]="{'active':url.endsWith('biologie')}"
            [routerLink]="['/doctor/appointments',this.appointment.id,'consultation','listBiologie']">Biologie</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " [ngClass]="{'active':url.endsWith('Radio')}"
            [routerLink]="['/doctor/appointments',this.appointment.id,'consultation','listRadio']">Radio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link " [ngClass]="{'active':url.endsWith('Vaccin')}"
            [routerLink]="['/doctor/appointments',this.appointment.id,'consultation','listVaccin']">Vaccin</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link " [ngClass]="{'active':url.endsWith('Actes')}"
            [routerLink]="['/doctor/appointments',this.appointment.id,'consultation','listActe']">Actes</a>
        </li>
      </ul>
      <!-- Left links -->
    </div>
    <div class="d-flex align-items-center">
      <a class="nav-link "> {{appointment.patient.getFullName(true)}}</a>
      <!-- Avatar -->
      <div class=" dropdown">
        <a class="dropdown-toggle  d-flex align-items-center hidden-arrownav-link" data-bs-toggle="dropdown" href="#"
          role="button" aria-expanded="false" id="navbarDropdownMenuAvatar" role="button" data-mdb-toggle="dropdown"
          aria-expanded="false"> <img class="avatar-img rounded-circle avatar avatar-sm"
            [src]="appointment.patient | avatarSrc" alt="User Image" />
        </a>
        <div class="dropdown-menu dropdown-menu-end " data-bs-popper="none">
          <div class="user-header">
            <img class="avatar-img rounded-circle avatar avatar-sm" [src]="appointment.patient | avatarSrc"
              alt="User Image" />
            <div class="user-text">
              <h6></h6>
              <p class="text-muted mb-0">{{appointment.patient.getFullName(true)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>