import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TextFillings } from 'src/app/entities/text-fillings.entity/text-fillings';

@Component({
  selector: 'app-list-fillings',
  templateUrl: './list-fillings.component.html',
  styleUrls: ['./list-fillings.component.css']
})
export class ListFillingsComponent implements OnInit {
  @Input() listeQuestionsConsultations: Array<TextFillings> = new Array()
  @Output() listeQuestionsConsultationsChange: EventEmitter<Array<TextFillings>> = new EventEmitter()
  listeQuestionsConsultationsFilter: Array<TextFillings> = new Array()
  @Input() listeFillings: Array<any> = new Array();
  @Input() listeFilter: Array<any> = new Array();
  @Input() value: string = "";
  @Input() filter: string = "";
  @Output() filterChange: EventEmitter<string> = new EventEmitter()
  @Output() valueChange: EventEmitter<string> = new EventEmitter()
  @Output() textChange: EventEmitter<string> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter()
  @Input() valueTotlal: string = "";
  searchText:string=""
  constructor() { }

  ngOnInit(): void {

  }




  search(event: any) {

    if (event.target.value?.length > 0) {

      this.listeFilter = this.listeFilter.filter((elm) =>

        (elm.label.trim() || "").toLowerCase().includes(this.searchText.toLowerCase()))
    }
    else this.listeFilter = this.listeFillings

  }
  changed(fillings: any, event, index): void {
    if (event.target.checked == true) {

      this.valueChange.emit((this.value?.length == 0) ? this.value.concat(fillings.textContent) : this.value.concat("\n", fillings.textContent))

    } else {
      this.valueChange.emit((this.value?.length == 0) ? this.value.replace(fillings.textContent, '') : this.value.replace(fillings.textContent, ''))
    }
  }
  close(): void {
    this.valueChange.emit(this.value)
    this.valueTotlal = this.value
    this.closed.emit()
  }

}
