<div class="table-responsive">
    <table class="table table-hover table-center mb-0">
        <thead>
            <tr>
                <th style="width: 10%;">Patient </th>
                <th style="width: 10%;">Date</th>
                <th>Motif Consultation</th>
                <th style="width: 100% !important">Diagnostic</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngIf="doctorConsultations.length;else empty">
                <tr *ngFor="let consultation of doctorConsultations  | paginate: { itemsPerPage: perPage, currentPage: currentPage } ;let i = index">
                    <td>
                        <h2 class="table-avatar">
                            <a class="avatar avatar-sm me-2"><img class="avatar-img rounded-circle" [src]="consultation.appointment.patient| avatarSrc"
                                    [alt]="consultation.appointment.patient.getFullName()" image /></a>
                            <a style="font-family: 'Gotham Rounded Bold';font-weight: Bold;">
                                {{ consultation.appointment.patient.getFullName()}}
                            </a>
                        </h2>
                    </td>
                    <td>
                        <span class="d-block "> {{consultation.date | date:'dd/MM/YYYY'}}</span>
                        <span class=" apt-time"> {{consultation.startTime | time }}</span>
                    </td>
                    <td style="width:25%">
                        <span *ngFor="let tag of consultation.motifTags" style="display: block;" class="tag-td mx-1 my-1"
                            style="float: left; margin-right: 5px;border-radius: .3rem;color:white;padding: 4px;" [ngStyle]="{'background-color':tag.color || 'gray'}">{{tag.text}}</span>
                    </td>
                    <td [innerHTML]="consultation.diagnostic.split('\n').join('<br>')"
                        style="max-width:200px !important; text-align: flex-start;word-break: break-all;text-overflow: ellipsis;">

                    </td>
                    <td class="text-start">
                        <div class="table-action">
                            <a *ngIf="doctor.speciality.label != 'Ophtalmologue' && doctor.consultationCNAMAP4" title="View ordonnance Consultation"
                                [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ap4']" class="btn btn-sm bg-info-light mx-2">
                                <span>AP4</span>
                            </a>
                            <a *ngIf="doctor.speciality.label != 'Ophtalmologue' && doctor.consultationCNAMAP4" title="View ordonnance Consultation"
                                [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ap3']" class="btn btn-sm bg-danger-light mx-2">
                                <span>AP3</span>
                            </a>
                            <a *ngIf="doctor.speciality.label != 'Ophtalmologue' && doctor.consultationCNAMAP4" title="View ordonnance Consultation"
                                [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ap2']" class="btn btn-sm bg-success-light mx-2">
                                <span>AP2</span>
                            </a>
                            <a *ngIf="doctor.speciality.label=='Ophtalmologue'" title="View ordonnance Consultation"
                                [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'ordonnance']" class="btn btn-sm bg-info-light mx-2">
                                <i class="feather-printer"></i>
                            </a>
                            <a title="Update Consultation" [routerLink]="['patients',consultation.appointment.patient.id,'consultations',consultation.id,'edit']"
                                class="btn btn-sm bg-success-light ">
                                <i class="feather-edit-3"></i>
                            </a>
                            <a title="Delete Consultation" (click)="onDeleteConsultation(i)" class="btn btn-sm bg-danger-light">
                                <i class="feather-trash"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-template #empty>
                <tr>
                    <td colspan="8" style="text-align: center;">
                        <span style="text-align: center; width: 100%;">
                            {{ emptyMessage ||'Pas de consultations !'}}
                        </span>
                    </td>
                </tr>
            </ng-template>
        </tbody>
    </table>
    <app-pagination (content)="doctorConsultations = $event" [pageRequest]="pageRequest"></app-pagination>
</div>