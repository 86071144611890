import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service/auth.service';

@Injectable()
export class TokenExpirtyInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private jwtHelperService: JwtHelperService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = request.headers.get('Authorization');
    const isAltabibEndpoint = new URL(request.url).hostname.includes('siladoc') || new URL(request.url).hostname.includes('altabib') || new URL(request.url).hostname.includes('127.0.0.1') || new URL(request.url).hostname.includes('localhost');
    if (token && isAltabibEndpoint && this.jwtHelperService.isTokenExpired(token)) {
      return this.handleExpiredToken();
    }

    return next.handle(request);
  }

  private handleExpiredToken(): Observable<HttpEvent<any>> {
    this.authService.logOut(); // Client-side logout
    this.router.navigate(['/', 'auth', 'login'], { queryParams: { backTo: new URL(this.router.url).pathname } });
    return throwError('Expired token');
  }

}
