<div class="row row-grid">
  <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let doctorPatient of doctorPatients| paginate: { itemsPerPage: perPage, currentPage: currentPage };let i = index">
    <div class="card widget-profile pat-widget-profile">
      <div class="card-body">
        <div class="pro-widget-content">
          <div class="profile-info-widget">
            <a class="booking-doc-img">
              <img [src]="doctorPatient | avatarSrc" [alt]="doctorPatient.getFullName()" image />
            </a>
            <div class="profile-det-info">
              <h3><a class="label">{{doctorPatient.getFullName()}}</a></h3>
              <div class="patient-details">
                <h5><b>Ref:</b> {{doctorPatient.ref || '..'}}</h5>
                <h5 class="mb-0">
                  <i class="feather-map-pin"></i> {{doctorPatient.address}}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="patient-info">
          <ul>
            <li>Mobile <span>{{ doctorPatient.mobilePhone1 || "---" }}</span></li>
            <li>Age <span>{{doctorPatient.old.years}}</span></li>
            <!-- <li>Blood Group <span>AB+</span></li> -->
          </ul>
        </div>
        <div class="table-action">
          <a *ngIf="actions.edit" title="Modifier Profil" (click)="onUpdateRequest.emit(doctorPatient)" class="btn btn-sm bg-info-light">
            <i class="feather-edit"> </i>
          </a>
          <a *ngIf="actions.assignAppointment" title="Ajouter Rdv" (click)="onAssignAppointmentRequest.emit(doctorPatient)" routerLinkActive="router-link-active"
            class="btn btn-sm bg-success-light">
            <i class="feather-plus"></i>
          </a>
          <a *ngIf="actions.delete" title="Supprimer Patient" (click)="onDelete(i)" routerLinkActive="router-link-active" class="btn btn-sm bg-danger-light">
            <i class="feather-trash"></i>
          </a>
          <app-drop-down-menu *ngIf="(actions.antecentendsView || actions.prescriptionsView || actions.consultationsView)&& doctor.packPro">
            <a class="btn btn-sm bg-success-light" #trigger trigger>
              <i class="feather feather-menu"></i>
            </a>
            <ul class="menu" #content content>
              <li *ngIf="actions.antecentendsView">
                <a (click)="onAntecedentsRequest.emit(doctorPatient)" routerLinkActive="router-link-active"><i class="feather feather-book-open"></i> <span>Ajout
                    Antecedent</span></a>
              </li>
              <li *ngIf="actions.prescriptionsView">
                <a (click)="onPrescriptionsRequest.emit(doctorPatient)"><i class="feather feather-user-plus"></i>
                  <span>Patient prescriptions</span></a>
              </li>
              <li *ngIf="actions.consultationsView">
                <a (click)="onConsultationsRequest.emit(doctorPatient)"><i class="feather feather-activity"></i>
                  <span>Patient consultations</span></a>
              </li>
            </ul>
          </app-drop-down-menu>
        </div>
      </div>
    </div>
  </div>

</div>

<app-pagination [pageRequest]="pageRequest" (content)="doctorPatients = $event"></app-pagination>