import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorAppointment, EAppointmentState } from 'src/app/entities/appointment.entity';
import { ConsultationMotif } from 'src/app/entities/consultation.entity/consultation-motif.entity/consultation-motif';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { DoctorAvailability } from 'src/app/entities/doctor-disponiblity.entity/doctor-availability';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { DoctorSecretaryAppointmentsService } from 'src/app/services/doctor-secretary-appointments.service/doctor-secretary-appointments.service';

@Component({
  selector: 'app-update-rendez-vous-secretary',
  templateUrl: './update-patient-secretary.component.html',
  styleUrls: ['./update-patient-secretary.component.css']
})
export class UpdatePatientSecretaryComponent implements OnInit {
  @Input() doctorPatients: Array<DoctorPatient> = new Array();
  @Input() userTarget!: DoctorPatient;
  @Input() location: EDalyTimeSlotLocation = EDalyTimeSlotLocation.ATSITE;
  @Input() disponibilites: Array<DoctorAvailability> = new Array();
  @Input() disponibiliteDefalut: number = 0;
 @Input() disponibilite: DoctorAvailability | any = new DoctorAvailability({});
  @Input() doctorAppointment: DoctorAppointment = new DoctorAppointment({
    status: EAppointmentState.PENDING,
    motif: "Consultation Régulière",
  });
  @Input() consultationMotifs: Array<ConsultationMotif> = [];
  @Input() date: string = "";
  @Input() dateDisponibilte!: Date;
  constructor(
    private doctorSecretaryAppointmentsService: DoctorSecretaryAppointmentsService,
    private authservice: AuthService,
    private toastrService: ToastrService,
  ) {}
  @Input() creationPatient: boolean = false;
  @Output() creationPatientChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() filter:boolean=false
  changeValueDisponibilte: boolean = false;
  get doctor(): Doctor {
    return this.authservice.getCurrentUser() as Doctor;
  }
  ngOnInit(): void {
    this.filter==false?this.changeValueDisponibilte=false:this.changeValueDisponibilte=true
    
  }

  refresh(event: DoctorPatient) {
    this.userTarget = event;
  }
  change(event: any) {
    this.changeValueDisponibilte = event.isTrusted;
  }
  sumbit() {
    

    this.doctorAppointment.date = new Date(
      this.dateDisponibilte.getFullYear(),
      this.dateDisponibilte.getMonth(),
      this.dateDisponibilte.getDate()
    );
    this.doctorAppointment.startTime = this.disponibilite.startTime!=0? this.disponibilite.startTime:this.disponibilite.startTime = this.doctorAppointment.startTime
    this.doctorAppointment.duration = this.doctorAppointment.duration;

    if (this.userTarget !== undefined) {
      this.doctorSecretaryAppointmentsService.update(this.doctorAppointment.id, this.doctorAppointment).subscribe((res: any) => {
        this.toastrService.success("Rdv mis à jour");
        this.doctorAppointment = new DoctorAppointment({});
        this.changeValueDisponibilte = false;
        this.userTarget = new DoctorPatient();
        this.close.emit();
        window.location.reload();

           });

    } else {
    }
  }

}
