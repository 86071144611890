import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificatedGuard } from './guards/auth.guards/authentificated.guard/authentificated.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'patient',
        canActivateChild: [AuthentificatedGuard],
        loadChildren: () => import('./modules/patient.module/patient.module').then((m) => m.PatientModule)
      },
      {
        path: 'backoffice',
        canActivateChild: [AuthentificatedGuard],
        loadChildren: () => import('./modules/backoffice.module/backoffice.module').then(m => m.BackOfficeModule)
      },
      {
        path: 'doctor',
        canActivateChild: [AuthentificatedGuard],
        loadChildren: () => import('./modules/doctor.module/doctor.module').then((m) => m.DoctorModule)
      },
      {
        path: 'secretary',
        canActivateChild: [AuthentificatedGuard],
        loadChildren: () => import('./modules/secretary.module/doctor-secretary.module').then((m) => m.DoctorSecretaryModule)
      },
      {
        path: 'pharmacist',
        canActivateChild: [AuthentificatedGuard],
        loadChildren: () => import('./modules/pharmacist.module/pharmacist.module').then((m) => m.PharmacistModule)
      },
      {
        path: 'biologist',
        canActivateChild: [AuthentificatedGuard],
        loadChildren: () => import('./modules/biologist.module/biologist.module').then(m => m.BiologistModule)
      },
      {
        path: 'radiologist',
        canActivateChild: [AuthentificatedGuard],
        loadChildren: () => import('./modules/radiologie.module/radiologie.module').then(m => m.RadiologieModule)
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth.module/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'apps',
    loadChildren: () => import('./modules/apps.module/apps.module').then((m) => m.AppsModule),
  },
  {
    path: 'booking',
    loadChildren: () => import('./modules/appointment-booking/appointment-booking.module').then(m => m.AppointmentBookingModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
