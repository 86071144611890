<!-- doctor-appointments-table-rest.component.html -->
<table class="table table-hover">
 
        <thead>
          <tr>
            <th>Dossier N° </th>
            <th>Prénom</th>
            <th>Nom</th>
            <th>Téléphone</th>
            <th>Email</th>
            <th>Consultations</th>
            <th>Actes</th>
            <th>Total</th>
            <th>Payé</th>
         
         
            <th>Reste</th>
            <th>Actions</th>
          </tr>
        </thead>
    <tbody>

    <tbody>
     
      <tr *ngFor="let appointment of appointmentsData">
        <td>{{ appointment.ref }}</td>

        <td>{{ appointment.firstName }}</td>
        <td>{{ appointment.lastName }}</td>
        <td>{{ appointment.mobilePhone1 }}</td>
        <td>{{ appointment.email }}</td>
        <td>{{ appointment.regularConsultationAmount |dashboradPaiement }}</td>
        <td>{{ appointment.regularConsultationEntriesAmount |dashboradPaiement }}</td>
        <td>{{appointment.regularConsultationAmount + appointment.regularConsultationEntriesAmount |dashboradPaiement}}</td>
      
        <td>{{ appointment.paidAmount |dashboradPaiement }}</td>
      
        <td  [ngStyle]="{'color': (appointment.regularConsultationAmount + appointment.regularConsultationEntriesAmount)-appointment.paidAmount < 0 ? 'green' : 'red' }" style="font-family: 'Gotham Rounded Bold'"> {{(appointment.regularConsultationAmount + appointment.regularConsultationEntriesAmount)-appointment.paidAmount |dashboradPaiement}} </td>
        <td>  <a title="View details" [routerLink]="['/doctor/patients',appointment.id,'appointments']" class="btn btn-sm bg-warning-light">
            <i class="feather-eye"></i>
        </a></td>
      </tr>
    </tbody>
  </table>
  