import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EAppointmentState } from 'src/app/entities/appointment.entity';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { ConsultationMotif } from 'src/app/entities/consultation.entity/consultation-motif.entity/consultation-motif';
import { DalyTimeSlot, EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { DoctorAvailability } from 'src/app/entities/doctor-disponiblity.entity/doctor-availability';;;
import { User } from 'src/app/entities/user.entity';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { DoctorsService } from 'src/app/services/doctors.service/doctors.service';
import { PatientAppointmentsService } from 'src/app/services/patient-appointments.service/patient-appointments.service';
import { PublicApisService } from 'src/app/services/public-apis.service/public-apis.service';
import { DoctorAvailabilitiesRequestPromise } from 'src/app/shared/modules/appointment-form.module/doctor-availabilities-calender/doctor-availabilities-calender.component';
import { MILLISECONDS_IN_MINUTE } from 'src/app/utils/date.util';

@Component({
  selector: 'app-appointment-booking-calender',
  templateUrl: './appointment-booking-calender.component.html',
  styleUrls: ['./appointment-booking-calender.component.css']
})
export class AppointmentBookingCalenderComponent implements OnInit {
  @Input() doctor!: Doctor;
  @Input() patientAppointment!: PatientAppointment;
  @Output() change: EventEmitter<PatientAppointment> = new EventEmitter();
  @Output() complete: EventEmitter<PatientAppointment> = new EventEmitter();
  @Input() dir: string = "fr"
  consultationMotifs: Array<ConsultationMotif> = new Array();

  filtre: EDalyTimeSlotLocation.ATSITE | EDalyTimeSlotLocation.ONLINE =
    EDalyTimeSlotLocation.ATSITE;
  dayTimeSlots: Array<DalyTimeSlot> = new Array();
  calenderDate: Date = new Date();
  isLoading: boolean = false;
  validating: boolean = false;


  get isLoggedIn(): boolean {
    return !!this.patient;
  }
  get patient(): User | null {
    return this.authService.getCurrentUser();
  }
  get currentDate(): Date {
    return new Date();
  }

  get selectedTimeSlot(): DalyTimeSlot | undefined {
    return this.dayTimeSlots.find((e) => {
      return this.patientAppointment.startTime >= e.startTime &&
        this.patientAppointment.startTime <
        e.endTime - e.duration * MILLISECONDS_IN_MINUTE
        ? true
        : false;
    });
  }

  constructor(private publicApiService: PublicApisService, private authService: AuthService) { }

  ngOnInit(): void {
    this.doctor.timeSlotLocalSetting == 2
      ? (this.filtre = this.filtre)
      : (this.filtre = this.doctor.timeSlotLocalSetting);

    this.availabilitiesRequest = (date?: Date) => {
      if (!date) date = new Date();
      return new Promise((resolve, reject) => {
        this.publicApiService
          .getDoctoravailabilities(this.doctor!.id, date || new Date())
          .subscribe(
            (data) => {
              resolve({
                date: data.date,
                availabilities: Array.from(data.availabilities, (e) =>
                  DoctorAvailability.fromResponse(e)
                ),
              });
            },
            (error) => reject(error)
          );
      });
    };



    this.publicApiService.getSpecialityAppointmentMotifs(this.doctor.speciality.id!)
      .subscribe(
        (res) =>
        (this.consultationMotifs = res.map((elm) =>
          ConsultationMotif.fromResponse(elm)
        ))
      );

  }

  availabilitiesRequest: DoctorAvailabilitiesRequestPromise = () =>
    Promise.resolve({ date: Date.now(), availabilities: [] });

  onAvailabilityChange(disponibility: DoctorAvailability): void {
    this.patientAppointment.date = disponibility.date;
    this.patientAppointment.startTime = disponibility.startTime;
    this.patientAppointment.duration = disponibility.duration;
  }

  onAvailabilityLocationChange(): void {
    this.patientAppointment.date = new Date(0);
    this.patientAppointment.startTime = 0;
    this.patientAppointment.duration = 0;
  }

  onCalanderDaySelected(day: Date) {
    this.patientAppointment.date = day;
    this.calenderDate = day;
  }

  get valid(): boolean {

    switch (this.doctor?.appointmentTiming) {
      case 0:
        return (this.patientAppointment.startTime + this.patientAppointment.date.getTime()) > Date.now();
      case 1:
        return this.patientAppointment.date.getTime() > Date.now();
      case 2:
        return this.validateTime();
      default:
        return false;
    }
  }

  validate(): boolean {
    this.validating = true;
    return this.valid;
  }

  validateTime(): boolean {
    return !!this.selectedTimeSlot
  }

  onSubmit(): void {
    this.patientAppointment.location = Number(this.filtre);
    if (!this.validate()) return;
    this.change.emit(this.patientAppointment);
    this.complete.emit(this.patientAppointment);

    /*
    this.isLoading = true;
    this.patientAppointmentsService
      .add(this.doctor!.id, this.patientAppointment)
      .subscribe(
        (res: any) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toasterService.error('Problème Création de RDV !');
        }
      );
      */
  }
}
