import { Component, Input, OnInit } from '@angular/core';
import { DoctorDashboardService } from 'src/app/services/doctor-dashboard.service/doctor-dashboard.service';

@Component({
  selector: 'app-dashborad-paiement-card',
  templateUrl: './dashborad-paiement.component.html',
  styleUrls: ['./dashborad-paiement.component.css']
})
export class DashboradPaiementComponent implements OnInit {
  @Input() dashboradPaiement:any
  constructor(private doctorDashboardService:DoctorDashboardService) { }

  ngOnInit(): void {
  }

}
