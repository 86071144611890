<div class="container-fluid cnam">
  <div class="row">
    <div class="col-lg-6">
      <!-- <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 10 TDM cérébrale</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad1"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 20 Scanopelvimétrie</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad2"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 30 TDM de mensuration </label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad3"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 40 TDM de repérage</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad4"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 50 TDM de l’articulation</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad5"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 60 TDM orbitaire</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad6"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 70 TDM thoracique</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad7"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 80 TDM abdominale</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad8"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 100 90 TDM pelvienne</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad9"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>RAD 10140 TDM de simulation</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2ScannerRad10"
              name=""
              id=""
            />
          </div>
        </div>
      </div> -->
      <div class="form-group">
        <div class="row">
          <div class="col-lg-11">
            <label>APCI</label>
          </div>
          <div class="col-lg-1">
            <input
              type="checkbox"
              [(ngModel)]="consultation.ap2.ap2APCI"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-6">
            <label>Code APCI</label>
          </div>
          <div class="col-lg-6">
            <input
              class="text form-input mb-2"
              [(ngModel)]="consultation.ap2.ap2APCICode"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="flex">
          <label for="">Autre scanner Région:</label>
          <div class="flex-button">

          <button
            class="openDialoge open"
            (click)="openDialoge('ScannerRegion')"
          >
            <i class="feather-plus-circle"></i>
          </button>
          <div class="question">
            <app-liste-question-sahred
              [filter]="'ScannerRegion'"
              [(value)]="consultation.ap2.ap2OtherScannerRegion"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
          </div>
        </div>
        <textarea
          name=""
          [(ngModel)]="consultation.ap2.ap2OtherScannerRegion"
          rows="1"
          class="form-control"
          tabindex="13"
        ></textarea>
      </div>
      <div class="form-group">
        <div class="flex">
          <label for="">Autre scanner Code : RAD……: </label>
          <div class="flex-button">

          <button class="openDialoge open" (click)="openDialoge('ScannerCode')">
            <i class="feather-plus-circle"></i>
          </button>

          <div class="question">
            <app-liste-question-sahred
              [filter]="'ScannerCode'"
              [(value)]="consultation.ap2.ap2OtherScannerCode"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
          </div>
        </div>
        <textarea
          name=""
          rows="1"
          [(ngModel)]="consultation.ap2.ap2OtherScannerCode"
          class="form-control"
          tabindex="14"
        ></textarea>
      </div>
      <div class="form-group">
        <div class="flex">
          <label for="">IRM (préciser la région): </label>
          <div class="flex-button">

          <button class="openDialoge open" (click)="openDialoge('IRM-Region')">
            <i class="feather-plus-circle"></i>
          </button>
          <div class="question">
            <app-liste-question-sahred
              [filter]="'IRM-Region'"
              [(value)]="consultation.ap2.irmRegion"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
          </div>
        </div>
        <textarea
          name=""
          rows="1"
          [(ngModel)]="consultation.ap2.irmRegion"
          class="form-control"
          tabindex="15"
        ></textarea>
      </div>
      <div>
        <div class="flex">
          <label for="">Scintigraphie:</label>
          <div class="flex-button">

          <button
            class="openDialoge open"
            (click)="openDialoge('Scintigraphie')"
          >
            <i class="feather-plus-circle"></i>
          </button>

          <div class="question">
            <app-liste-question-sahred
              [filter]="'Scintigraphie'"
              [(value)]="consultation.ap2.seintigraphie"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
          </div>
        </div>
        <textarea
          name=""
          rows="1"
          [(ngModel)]="consultation.ap2.seintigraphie"
          class="form-control"
          tabindex="16"
        ></textarea>
      </div>
      <div class="form-group">
        <div class="flex">
          <label for="">Autre Act:</label>
          <div class="flex-button">

          <button class="openDialoge open" (click)="openDialoge('otherAct')">
            <i class="feather-plus-circle"></i>
          </button>
          <div class="question">
            <app-liste-question-sahred
              [filter]="'otherAct'"
              [(value)]="consultation.ap2.otherAct"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
          </div>
        </div>
        <textarea
          name=""
          rows="1"
          class="form-control"
          [(ngModel)]="consultation.ap2.otherAct"
          tabindex="17"
        ></textarea>
      </div>
     
    </div>
    <div class="col-lg-6">

      <div class="form-group">
        <div class="flex">
          <label for="">Données cliniques et paracliniques:</label>
      <div class="flex-button">
        <button class="openDialoge open" [title]="'Données Cliniques'" (click)="donneeCliniques()">
          <i class="feather-arrow-down-circle"></i>
          </button>
        <button
        class="openDialoge open"
        (click)="openDialoge('ap2Question1')"
      >
        <i class="feather-plus-circle"></i>
      </button>
      <div class="question">
        <app-liste-question-sahred
          [filter]="'ap2Question1'"
          [(value)]="consultation.ap2.ap2Question1"
          [(listeQuestionsConsultations)]="tableauTextFillings"
        ></app-liste-question-sahred>
      </div>
      </div>
        </div>
        <textarea
          name=""
          rows="1"
          class="form-control"
          [(ngModel)]="consultation.ap2.ap2Question1"
          tabindex="10"
        ></textarea>
      </div>
      <div class="form-group">
        <div class="flex">
          <label for=""> Diagnostic (établi ou probable) :</label>
          <div class="flex-button">
            <div class="flex-button">
              <button class="openDialoge open" [title]="'Diagnostic'" (click)="diagnostic()">
                <i class="feather-arrow-down-circle"></i>
                </button>
          <button
            class="openDialoge open"
            (click)="openDialoge('ap2Question2')"
          >
            <i class="feather-plus-circle"></i>
          </button>
          <div class="question">
            <app-liste-question-sahred
              [filter]="'ap2Question2'"
              [(value)]="consultation.ap2.ap2Question2"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
            </div>
          </div>
        </div>
        <textarea
          name=""
          rows="1"
          class="form-control"
          [(ngModel)]="consultation.ap2.ap2Question2"
          tabindex="11"
        ></textarea>
      </div>
      <div class="form-group">
        <div class="flex">
          <label for="">Thérapeutique (envisagée ou en cours) </label>
          <div class="flex-button">

          <button
            class="openDialoge open"
            (click)="openDialoge('ap2Question3')"
          >
            <i class="feather-plus-circle"></i>
          </button>
          <div class="question">
            <app-liste-question-sahred
              [filter]="'ap2Question3'"
              [(value)]="consultation.ap2.ap2Question3"
              [(listeQuestionsConsultations)]="tableauTextFillings"
              [options]="{right:'-19px',top:'24px'}"
            ></app-liste-question-sahred>
          </div>
          </div>
        </div>
        <textarea
          name=""
          rows="1"
          class="form-control"
          [(ngModel)]="consultation.ap2.ap2Question3"
          tabindex="12"
        ></textarea>
      </div>
      
      <div class="row">
        <div class="col-lg-6">
          <label class="label">Nature de l’examen</label>
          <input
            class="text form-input mb-2"
            [(ngModel)]="consultation.ap2.ap2ExamNature1"
            tabindex="4"
          />
          <input
            class="text form-input mb-2"
            [(ngModel)]="consultation.ap2.ap2ExamNature2"
            tabindex="6"
          />
          <input
            class="text form-input"
            [(ngModel)]="consultation.ap2.ap2ExamNature3"
            tabindex="8"
          />
        </div>
        <div class="col-lg-6">
          <label class="label">Date</label>

          <div class="mb-1">
            <date-picker
              [(ngModel)]="consultation.ap2.ap2ExamDate1 "
              tabindex="5"
            ></date-picker>
          </div>
          <div class="mb-1">
            <date-picker
              [(ngModel)]="consultation.ap2.ap2ExamDate2 "
              tabindex="7"
            ></date-picker>
          </div>
          <div>
            <date-picker
              [(ngModel)]="consultation.ap2.ap2ExamDate3"
              tabindex="9"
            ></date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modal
  [title]="questions.id==''?'Ajouter nouvelle Question':'Mise a jour  Question'"
  #dialog
>
  <app-text-fillings-form
    *ngIf="questions.id!==''"
    [submitName]="'Mise a jour  Question'"
    [textFilligns]="questions"
    (submit)="callUpdateQuestion($event)"
  ></app-text-fillings-form>
  <app-text-fillings-form
    *ngIf="questions.id==''"
    [submitName]="'Ajouter Question'"
    [textFilligns]="questions"
    (submit)="callAddQuestion($event)"
  ></app-text-fillings-form>
</app-modal>
