<div class="container-fluid py-4">
  <div class="row">
    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="card">
            <div class="card-header">
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Montant Payé</p>
                <h4 class="mb-0">{{dashboradPaiement.paidAmounts| dashboradPaiement}} DT</h4>
              </div>
            </div>
            <!-- <hr class="dark horizontal my-0" />
            <div class="card-footer p-3">
              <p class="mb-0"><span class="text-success text-sm font-weight-bolder">+55% </span>than lask week</p>
            </div> -->
          </div>
    </div>
    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
        <div class="card">
            <div class="card-header">
              <div class="text-end pt-1">
                <p class="text-sm mb-0 text-capitalize">Montant Consultation</p>
                <h4 class="mb-0">{{dashboradPaiement.regularConsultationAmounts | dashboradPaiement}} DT</h4>
              </div>
            </div>
            <!-- <hr class="dark horizontal my-0" />
            <div class="card-footer p-3">
              <p class="mb-0"><span class="text-success text-sm font-weight-bolder">+55% </span>than lask week</p>
            </div> -->
          </div>
    </div>

   
    <div class="col-xl-3 col-sm-6 mb-xl-0">
      <div class="card">
        <div class="card-header">
          <div class="text-end pt-1">
            <p class="text-sm mb-0 text-capitalize">Montant Actes</p>
            <h4 class="mb-0">{{dashboradPaiement.regularConsultationEntriesAmounts | dashboradPaiement}} DT</h4>
          </div>
        </div>
        <!-- <hr class="dark horizontal my-0" />
        <div class="card-footer p-3">
          <p class="mb-0"><span class="text-success text-sm font-weight-bolder">+55% </span>than lask week</p>
        </div> -->
      </div>
    </div>
    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
      <div class="card">
          <div class="card-header">
            <div class="text-end pt-1">
              <p class="text-sm mb-0 text-capitalize">Reste</p>
              <h4 class="mb-0">{{(dashboradPaiement.regularConsultationAmounts+dashboradPaiement.regularConsultationEntriesAmounts)-dashboradPaiement.paidAmounts | dashboradPaiement}} DT</h4>
            </div>
          </div>
          
        </div>
  </div>
  </div>
</div>
