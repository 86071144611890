import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';

import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { User } from './entities/user.entity';
import { SharedModule } from './shared/shared.module';

import { TokenExpirtyInterceptor } from './interceptors/token-expirty.interceptor/token-expirty.interceptor';
import { CacheService } from './services/cache.service/cache.service';
import { environment } from 'src/environments/environment';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { NgxQRCodeModule } from 'ngx-qrcode2';

FullCalendarModule.registerPlugins([
  interactionPlugin,
  dayGridPlugin,
  timeGridPlugin,

]);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ToastNoAnimationModule.forRoot(),
    FullCalendarModule,
    HttpClientModule,
    NgxQRCodeModule,
    AngularEditorModule,
    NgxIndexedDBModule.forRoot(CacheService.getConfig()),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem("current-user") ? (JSON.parse(localStorage.getItem("current-user")!) as User)!.accessToken! : null,
        allowedDomains: [environment.API_HOST_NAME + (environment.PORT ? `:${environment.PORT}` : '')],
        authScheme: "Bearer ",
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenExpirtyInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
