import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EUserRole, User } from 'src/app/entities/user.entity';
import { environment } from 'src/environments/environment';
import { CacheService } from '../cache.service/cache.service';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { Biologist } from 'src/app/entities/user.entity/biologist.entity';
import { DoctorSecretary } from 'src/app/entities/user.entity/doctor.entity/secretary.entity';

export class UserRegisterModel {
  firstName: string;
  lastName: string;
  email: string;
  password?: string | undefined;
  country: string;
  phone: string;
  government: string;
  postalCode: number;
  address: string;
  birthDate: number;

  constructor(other?: Partial<UserRegisterModel>) {
    this.firstName = other?.firstName || '';
    this.lastName = other?.lastName || '';
    this.email = other?.email || '';
    this.country = other?.country || '';
    this.phone = other?.phone || '';
    this.password = other?.password || '';
    this.government = other?.government || '';
    this.postalCode = other?.postalCode || 0;
    this.address = other?.address || '';
    this.birthDate = other?.birthDate || 0;
  }
}


export class OrganizationRegisterModel extends UserRegisterModel {

  name: string;
  type: number;
  address: string;
  constructor(other?: Partial<OrganizationRegisterModel>) {
    super(other);
    this.name = other?.name || '';
    this.type = other?.type || 0;
    this.address = other?.address || '';
  }

}

export class PharmcistRegisterModel extends UserRegisterModel {

  name: string;
  type: number = 4;
  address: string;
  constructor(other?: Partial<OrganizationRegisterModel>) {
    super(other);
    this.name = other?.name || '';
    this.address = other?.address || '';
  }

}

export class DoctorRegisterModel extends UserRegisterModel {

  specialityId: string;
  address: string;
  packStart:boolean
  packPro:boolean
  constructor(other?: Partial<DoctorRegisterModel>) {
    super(other);
    this.phone = null as any;
    this.specialityId = other?.specialityId || '';
    this.address = other?.address || '';
    this.packPro=other?.packPro || false;
    this.packStart=other?.packStart || true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  clearTimeout: any;

  baseUrl: string = environment.API_URL + '/account';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  constructor(private http: HttpClient, private cacheService: CacheService) { }
  login(login: { email: string, password: string }): Observable<any> {
    return this.http.post(`${environment.API_URL}/account/login`, { email: login.email.toLocaleLowerCase().trim(), password: login.password }, { headers: { 'content-type': 'application/json' } });
  }
  resetForgottenPassword(data: { secretCode: string, newPassword: string }): Observable<any> {
    return this.http.post<any>(`${environment.API_URL}/account/auth/reset-password`, data);
  }

  registerAsUser(userRegisterModel: UserRegisterModel): Observable<any> {
    return this.http.post(`${environment.API_URL}/account/register`, userRegisterModel);
  }

  registerAsDoctor(doctorUserRegisterModel: DoctorRegisterModel): Observable<any> {
    return this.http.post(`${environment.API_URL}/account/doctor/register`, doctorUserRegisterModel);
  }

  registerAsOrganization(organizationUserRegisterModel: OrganizationRegisterModel): Observable<any> {
    return this.http.post(`${environment.API_URL}/account/organization/register`, organizationUserRegisterModel);
  }

  registerAsPharmacist(organizationUserRegisterModel: PharmcistRegisterModel): Observable<any> {
    return this.http.post(`${environment.API_URL}/account/organization/register`, organizationUserRegisterModel);
  }

  getCurrentUser(): User | null {
    const userObject: User = JSON.parse(localStorage.getItem('current-user')!);
    if (userObject)
      switch (userObject.role) {
        case EUserRole.DOCTOR: return new Doctor(userObject);
        case EUserRole.BIOLOGIST: return new Biologist(userObject);
        case EUserRole.DOCTOR_SECRETARY:return new DoctorSecretary(userObject)
        default: return new User(userObject);
      }
    else return null
  }

  setLangue(langue: 'fr' | 'ar'): void {
    localStorage.setItem('dir', langue)
  }

  getLangue(): string {
    return localStorage.getItem('dir')! || 'fr'
  }

  isLogedIn(): boolean {
    return this.getCurrentUser() != null;
  }
  setCurrentUser(user: User): void {
    localStorage.setItem('current-user', JSON.stringify(user));
  }
  setCurrentUserDoctor(user: User): void {
    localStorage.setItem('current-user', JSON.stringify(user));
  }

  logOut(): void {
    localStorage.removeItem('current-user');
    this.cacheService.clear().subscribe(res => { })
  }
  getUserByEmail(email: string): Observable<User> {
    return this.http.request<User>('GET', `${environment.API_URL}api/account/find/byEmail/${email}`, { responseType: 'json' })
  }

  confirmAccount(code: string): Observable<any> {
    return this.http.get(`${environment.API_URL}/account/confirm?secretCode=${code}`, { responseType: 'json' });
  }

  sendForgotPasswordRequest(userName: string): Observable<any> {
    return this.http.patch(`${environment.API_URL}/account/request/forgot?userName=${userName}`, { responseType: 'json' });
  }

  autoLogout(expirationDate: number) {
    this.clearTimeout = setTimeout(() => {
      this.logOut();
    }, expirationDate);
  }
}
