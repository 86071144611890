import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { DoctorAppointment, EAppointmentState } from "src/app/entities/appointment.entity";
import { ConsultationMotif } from "src/app/entities/consultation.entity/consultation-motif.entity/consultation-motif";
import { EDalyTimeSlotLocation } from "src/app/entities/daly-time-slot.entity/daly-time-slot";
import { DoctorAvailability } from "src/app/entities/doctor-disponiblity.entity/doctor-availability";
import { DoctorPatient } from "src/app/entities/doctor-patient.entity/doctor-patient";
import { Doctor } from "src/app/entities/user.entity/doctor.entity";
import { AuthService } from "src/app/services/auth.service/auth.service";
import { DoctorAppointmentService } from "src/app/services/doctor-appointments.service/doctor-appointments.service";
import { DoctorSecretaryAppointmentsService } from "src/app/services/doctor-secretary-appointments.service/doctor-secretary-appointments.service";
import { PublicApisService } from "src/app/services/public-apis.service/public-apis.service";

@Component({
  selector: "app-create-rendez-vous",
  templateUrl: "./create-rendez-vous.component.html",
  styleUrls: ["./create-rendez-vous.component.css"],
})
export class CreateRendezVousComponent implements OnInit {
  @Input() doctorPatients: Array<DoctorPatient> = new Array();
  @Input() userTarget!: DoctorPatient;
  @Input() location: EDalyTimeSlotLocation = EDalyTimeSlotLocation.ATSITE;
  @Input() disponibilites: Array<DoctorAvailability> = new Array();
  @Input() disponibiliteDefalut: number = 0;
  disponibilite: DoctorAvailability | any = new DoctorAvailability({});
  doctorAppointment: DoctorAppointment = new DoctorAppointment({
    status: EAppointmentState.PENDING,
    motif: "Consultation Régulière",
  });
  @Input() consultationMotifs: Array<ConsultationMotif> = [];
  @Input() date: string = "";
  @Input() dateDisponibilte!: Date;
  constructor(
    private doctorAppointmentsService: DoctorAppointmentService,
    private authservice: AuthService,
    private toastrService: ToastrService,
  ) {}
  @Input() creationPatient: boolean = false;
  @Output() creationPatientChange: EventEmitter<boolean> = new EventEmitter();
  @Output() close: EventEmitter<void> = new EventEmitter();
  @Input() filter:boolean=false
  changeValueDisponibilte: boolean = false;
  get doctor(): Doctor {
    return this.authservice.getCurrentUser() as Doctor;
  }
  ngOnInit(): void {
    this.filter==false?this.changeValueDisponibilte=false:this.changeValueDisponibilte=true

  }

  refresh(event: DoctorPatient) {
    this.userTarget = event;
  }
  change(event: any) {
    this.changeValueDisponibilte = event.isTrusted;
  }
  sumbit() {
    
    if (this.changeValueDisponibilte == false && this.disponibiliteDefalut != 0) {
      
      this.disponibilite.startTime = this.disponibiliteDefalut

      let disponibiliteExits= this.disponibilites.filter(elm=>elm.startTime==this.disponibiliteDefalut);
        
      if(disponibiliteExits.length>0)this.disponibilite.duration=disponibiliteExits[0].duration ;else this.disponibilite.duration=30
      
      
      
    };

    this.doctorAppointment.date = new Date(
      this.dateDisponibilte.getFullYear(),
      this.dateDisponibilte.getMonth(),
      this.dateDisponibilte.getDate()
    );
    this.doctorAppointment.startTime = this.disponibilite.startTime!=0? this.disponibilite.startTime:this.disponibilites[0].startTime;
    this.doctorAppointment.duration = this.disponibilite.duration;

    if (this.userTarget !== undefined) {
      this.doctorAppointmentsService.add(this.userTarget.id, this.doctorAppointment).subscribe((res: any) => {
        this.toastrService.success("Rdv créé avec succès");
        this.doctorAppointment = new DoctorAppointment({});
        this.changeValueDisponibilte = false;
        this.userTarget = new DoctorPatient();
        this.close.emit();
        window.location.reload()
      });
    } else {
    }
  }
}
