interface IEnvironment {
    VIDEOSDK_API_TOKEN: string,
    VIDEOSDK_API_KEY: string,
    VIDEO_CALL_MAX_DURATION: number,
    PAYME_API_KEY: string,
    PAYME_API_URL: string;
    KONNECT_API_URL: string,
    KONNECT_API_KEY: string,
    KONNECT_RECEIVER_WALLET_ID: string,
    PAYMENT_SERVICE: 'KONNECT' | 'PAYME',
    API_HOST_NAME: string,
    PORT: number,
    API_URL: string,
    STORAGE_ENDPOINT: string,
    DOMAINE_NAME: string,
    API_SSL: boolean,
    ENV: 'production' | 'development',
    addsPannels: boolean
}

export class Environment implements IEnvironment {
    VIDEOSDK_API_TOKEN: string;
    VIDEOSDK_API_KEY: string;
    VIDEO_CALL_MAX_DURATION: number;
    PAYME_API_KEY: string;
    PAYME_API_URL: string;
    KONNECT_API_URL: string;
    KONNECT_API_KEY: string;
    KONNECT_RECEIVER_WALLET_ID;
    PAYMENT_SERVICE: 'KONNECT' | 'PAYME';
    API_HOST_NAME: string;
    PORT: number;
    API_URL: string;
    STORAGE_ENDPOINT: string;
    DOMAINE_NAME: string;
    API_SSL: boolean;
    ENV: 'production' | 'development';
    VIDEOSDK_API_URL: string = 'https://api.videosdk.live';
    addsPannels: boolean;
    constructor(other: IEnvironment) {
        this.VIDEOSDK_API_TOKEN = other.VIDEOSDK_API_TOKEN;
        this.VIDEOSDK_API_KEY = other.VIDEOSDK_API_KEY;
        this.VIDEO_CALL_MAX_DURATION = other.VIDEO_CALL_MAX_DURATION,
            this.PAYME_API_KEY = other.PAYME_API_KEY;
        this.PAYMENT_SERVICE = other.PAYMENT_SERVICE;
        this.PAYME_API_URL = other.PAYME_API_URL;
        this.KONNECT_API_KEY = other.KONNECT_API_KEY;
        this.KONNECT_API_URL = other.KONNECT_API_URL;
        this.KONNECT_RECEIVER_WALLET_ID = other.KONNECT_RECEIVER_WALLET_ID;
        this.API_HOST_NAME = other.API_HOST_NAME;
        this.PORT = other.PORT;
        this.API_URL = other.API_URL;
        this.STORAGE_ENDPOINT = other.STORAGE_ENDPOINT;
        this.DOMAINE_NAME = other.DOMAINE_NAME;
        this.API_SSL = other.API_SSL;
        this.ENV = other.ENV;
        this.addsPannels = other.addsPannels;
    }

    getVideoSDKApiHostName(): string {
        return new URL(this.VIDEOSDK_API_URL).hostname;
    }
}