import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ITagInputItem } from 'src/app/common/components/tags-input.component/tags-input.component';
import { APCIS } from 'src/app/common/data/apcis';
import countries from 'src/app/common/data/countries';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
import { EUserGender, User } from 'src/app/entities/user.entity';
import { DoctorPatientsService } from 'src/app/services/doctor-patients.service/doctor-patients.service';

@Component({
  selector: 'app-create-patient-shared',
  templateUrl: './create-patient.component.html',
  styleUrls: ['./create-patient.component.css']
})
export class CreatePatientComponent implements OnInit {
  patientProfileForm!: FormGroup;
  patientAssuranceForm!: FormGroup;
  patientAccompanistForm!: FormGroup;
  imageUrl: string = "";
  isLoading: boolean = false;
  @Input() isSubmitted: boolean = false;
  @Output() isSubmittedChange:EventEmitter<boolean>= new EventEmitter();
  @Input() isFilter: boolean = false;
  @Output() isFilterChange:EventEmitter<boolean>= new EventEmitter()
  @Output() completed: EventEmitter<DoctorPatient> = new EventEmitter();
  public imageFile!: File;
  file: any;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private doctorPatientsService: DoctorPatientsService,
    private router: Router
  ) { }

  get countries(): Array<any> {
    return countries;
  }
  get PhonePrependHtml(): string {
    return `<span>${this.currentCountry?.dialCode || "+216"}</span>`;
  }
  get currentCountry(): any {
    return this.patientProfileForm.controls.country.value;
  }
  set currentCountry(value: any) {
    this.patientProfileForm.controls.country.setValue(value); // Mettez à jour la valeur du contrôle du pays
  }
  hasAssurance: boolean = false;
  hasAccompanist: boolean = false;

  get currentDate(): Date {
    return new Date();
  }

  get apicsSource(): Array<ITagInputItem> {
    return APCIS.map((e) => {
      return {
        id: String(e.index),
        value: e.index,
        text: `${e.index + 1}.${e.value}`,
      };
    });
  }

  ngOnInit(): void {
    window.scrollBy(100, 0);

    this.patientProfileForm = this.formBuilder.group({
      ref: [""],
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      country: [
        {
          name: "Tunisie",
          dialCode: "+216",
          isoCode: "TN",
          flag: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
        },
        [Validators.required],
      ],
      address: [""],
      email: [null],
      birthDate: [null, [Validators.required]],
      gender: [EUserGender.HOMME],
      mobilePhone1: [""],
      mobilePhone2: [""],

      socialCivility: [0],
      patientNote: [""],
      sanguineGroup: [0],
      cnamCode: [""],
      cnamAssociation: [0],
      government: [""],
      apcis: [[]],
      note: [""],
    });

    this.patientAssuranceForm = this.formBuilder.group({
      //assuranceType: ['STAR', [Validators.required]],
      assuranceType: [""],
      assuranceName: [""],
      assuranceReference: [""],
    });
    this.patientAccompanistForm = this.formBuilder.group({
      accompanistUserName: [""],
    });
  }

  get isValid(): boolean {
    return (
      this.patientProfileForm.valid &&
      (this.hasAccompanist ? this.patientAccompanistForm.valid : true) &&
      (this.hasAssurance ? this.patientAssuranceForm.valid : true)
    );
  }

  onFileSelect(event: any) {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) =>
      (this.imageUrl = event.target?.result?.toString()!);
    this.imageFile = event.target.files[0];
  }
  get f(): any {
    return this.patientProfileForm.controls;
  }

  get patientModel(): any {
    var patientModal: Object = {
      ref: this.patientProfileForm.controls.ref.value,
      firstName: this.patientProfileForm.controls.firstName.value,
      lastName: this.patientProfileForm.controls.lastName.value,
      email: this.patientProfileForm.controls.email.value
        ? this.patientProfileForm.controls.email.value.toLocaleLowerCase()
        : null,
      mobilePhone1: this.patientProfileForm.controls.mobilePhone1.value,
      mobilePhone2: this.patientProfileForm.controls.mobilePhone2.value,

      gender: this.patientProfileForm.controls.gender.value,
      sanguineGroup: this.patientProfileForm.controls.sanguineGroup.value,
      birthDate: this.patientProfileForm.controls.birthDate.value.getTime(),
      socialCivility: this.patientProfileForm.controls.socialCivility.value,
      country: this.patientProfileForm.controls.country.value.name,
      address: this.patientProfileForm.controls.address.value,
      government: this.patientProfileForm.controls.government.value,
      cnamCode: this.patientProfileForm.controls.cnamCode.value,
      cnamAssociation: this.patientProfileForm.controls.cnamAssociation.value,
      note: this.patientProfileForm.controls.note.value,
      apcis: (this.patientProfileForm.controls.apcis.value as Array<
        ITagInputItem
      >).map((e) => e.id),
    };

    if (this.hasAssurance) {
      patientModal = {
        assuranceType: this.patientAssuranceForm.controls.assuranceType.value,
        assuranceName: this.patientAssuranceForm.controls.assuranceName.value,
        assuranceReference: this.patientAssuranceForm.controls
          .assuranceReference.value,
        assuranceOrganization: this.patientAssuranceForm.controls.assuranceType
          .value,
        ...patientModal,
      };
    }

    return patientModal;
  }

  onFormChange(): void {
    this.patientAccompanistForm.controls.accompanistUserName.setErrors({
      notFound: false,
    });
    this.patientProfileForm.controls.email.setErrors({ exists: false });
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.patientAssuranceForm.controls.assuranceType.value != "Autre")
      this.patientAssuranceForm.controls.assuranceName.setValue(
        this.patientAssuranceForm.controls.assuranceType.value
      );
    if (!this.isValid) return;
    const formData = new FormData();
    formData.append("photo", this.imageFile);
    formData.append("patient", JSON.stringify(this.patientModel));
    this.isLoading = true;
    this.doctorPatientsService
      .add(
        formData,
        !this.patientAccompanistForm.controls.accompanistUserName.errors &&
          String(this.patientAccompanistForm.controls.accompanistUserName.value)
            .length > 0
          ? this.patientAccompanistForm.controls.accompanistUserName.value
          : null
      )
      .subscribe(
        (res) => {
          this.toastrService.success("Patient créé avec succès");
          this.completed.emit(DoctorPatient.fromResponse(res));
          this.isLoading = false;
          this.isFilter = false
          this.isFilterChange.emit(this.isFilter)
        },
        (error) => {
          this.isLoading = false;
          if (error.kind == "EntityNotFoundException") {
            this.patientAccompanistForm.controls.accompanistUserName.setErrors({
              notFound: true,
            });
          } else if (error.kind == "EntityExistsException") {
            this.onEixistingPatientError(User.fromResponse(error.data));
          } else this.toastrService.error("Problème de Création de patient !");
        }
      );
  }

  onEixistingPatientError(user: User): void {
    if (
      confirm(
        `Voulez vous ajouter ${user.getFullName()} à la liste des patients ?`
      )
    ) {
      return this.assign(user);
    }
  }

  assign(user: User): void {
    this.doctorPatientsService.assign(user.id).subscribe(
      (res) => {
        this.toastrService.info("Patient a ete bien affecté");
        this.completed.emit(DoctorPatient.fromResponse(res));
        this.router.navigate(["/doctor/patients"]);
      },
      (error) => {
        this.toastrService.warning(
          `${user.getFullName()} is already in your list patients`
        );
        this.router.navigate(["/doctor/patients/" + user.id]);
      }
    );
  }
  /*
    selectedIndex: number = -1;
   
    onDropdownKeydown(event: KeyboardEvent) {
      if (event.key === 'ArrowDown' && this.selectedIndex < this.countries.length - 1) {
        // Navigate down
        this.selectedIndex++;
      } else if (event.key === 'ArrowUp' && this.selectedIndex > 0) {
        // Navigate up
        this.selectedIndex--;
      } 
      else if (event.key === 'Enter' && this.selectedIndex !== -1) {
        // Enter key to select the item
        this.currentCountry= this.countries[this.selectedIndex]; // Utilisez le setter
        this.selectedIndex = -1;
      }
    }
  */

}
