export const APCIS: Array<{ index: number, value: string }> = [
    {
        index: 0,
        value: "DIABETE_INSULINODEPENDANT_OU_NON_INSULINODEPENDANT"
    },
    {
        index: 1,
        value: "DYSTHYROIDIES"
    },
    {
        index: 2,
        value: "AFFECTIONS_HYPOPHYSAIRES"
    },
    {
        index: 3,
        value: "AFFECTIONS_SURRENALIENNES"
    },
    {
        index: 4,
        value: "HTA_SEVERE"
    },
    {
        index: 5,
        value: "CARDIOPATHIES_CONGENITALES_ET_VALVULOPATHIES"
    },
    {
        index: 6,
        value: "INSUFFISANCE_CARDIAQUE_ET_TROUBLES_DU_RYTHME"
    },
    {
        index: 7,
        value: "AFFECTIONS_CORONARIENNES_ET_LEURS_COMPLICATIONS"
    },
    {
        index: 8,
        value: "PHLEBITES"
    },
    {
        index: 9,
        value: "TUBERCULOSE_ACTIVE"
    },
    {
        index: 10,
        value: "INSUFFISANCE_RESPIRATOIRE_CHRONIQUE"
    },
    {
        index: 11,
        value: "SCLEROSE_EN_PLAQUES"
    },
    {
        index: 12,
        value: "EPILEPSIE"
    },
    {
        index: 13,
        value: "MALADIE_DE_PARKINSON"
    },
    {
        index: 14,
        value: "PSYCHOSES_ET_NEVROSES"
    },
    {
        index: 15,
        value: "INSUFFISANCE_RENALE_CHRONIQUE"
    },
    {
        index: 16,
        value: "RHUMATISMES_INFLAMMATOIRES_CHRONIQUES"
    },
    {
        index: 17,
        value: "MALADIES_AUTO_IMMUNES"
    },
    {
        index: 18,
        value: "TUMEURS_ET_HEMOPATHIES_MALIGNES"
    },
    {
        index: 19,
        value: "LES_MALADIES_INFLAMMATOIRES_CHRONIQUES_DE_INTESTIN"
    },
    {
        index: 20,
        value: "HEPATITES_CHRONIQUES_ACTIVES"
    },
    {
        index: 21,
        value: "CIRRHOSES_ET_INSUFFISANCE_HEPATIQUE"
    },
    {
        index: 22,
        value: "GLAUCOME"
    },
    {
        index: 23,
        value: "MUCOVISCIDOSE"
    }
]