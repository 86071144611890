import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './services/auth.service/auth.service';
import { EUserRole } from './entities/user.entity';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): any {
    if (this.authService.isLogedIn()) {
      switch (this.authService.getCurrentUser()?.role || 0) {
        case EUserRole.DOCTOR:
          return this.router.navigate(['/doctor']);
        case EUserRole.DOCTOR_SECRETARY:
          return this.router.navigate(['/secretary']);
        case EUserRole.PHARMACIST:
          return this.router.navigate(['/pharmacist']);
        case EUserRole.BIOLOGIST:
          return this.router.navigate(['/biologist']);
        case EUserRole.RADIOLOGIST:
          return this.router.navigate(['/radiologist']);
        case EUserRole.ADMIN:
          return this.router.navigate(['/backoffice']);
        default:
          return this.router.navigate(['/patient/appointments']);
      }
    }
  }
}
