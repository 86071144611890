import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-avatar-form',
  templateUrl: './user-avatar-form.component.html',
  styleUrls: ['./user-avatar-form.component.css']
})
export class UserAvatarFormComponent implements OnInit {
  @Input() label: string = 'click to uplaod';
  @Input() src: string | ArrayBuffer = '';
  @Output() change: EventEmitter<String | ArrayBuffer> = new EventEmitter();
  @Output() load: EventEmitter<ProgressEvent<FileReader>> = new EventEmitter();
  @Output() fileListChange: EventEmitter<FileList> = new EventEmitter();
  @Output() loadResult: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {
  }

  onFileSelected(event: Event): void {
    const fileList: FileList = (event.target as HTMLInputElement).files || new FileList();
    this.fileListChange.emit(fileList)
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.load.emit(e);
        this.src = e.target!.result!;
        this.change.emit(e.target!.result!)
      };
      reader.readAsDataURL(file);
    }

  }

}
