import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ModalComponent } from 'src/app/common/modules/modal.module/modal/modal.component';
import { Consultation } from 'src/app/entities/consultation.entity';
import { TextFillings } from 'src/app/entities/text-fillings.entity/text-fillings';
import { TextFillingsService } from 'src/app/services/fillings.service/text-fillings.service';

@Component({
  selector: 'app-consultation-forms-cnam-ap3',
  templateUrl: './consultation-forms-cnam-ap3.component.html',
  styleUrls: ['./consultation-forms-cnam-ap3.component.css']
})
export class ConsultationFormsCnamAp3Component implements OnInit {
  @Input() consultation: Consultation = new Consultation
  @Output() consultationChange: EventEmitter<Consultation> = new EventEmitter()
  @Output() Question1Ap3: EventEmitter<void> = new EventEmitter();

  open: boolean = false
  tableauTextFillings!: Array<TextFillings>
  questions: TextFillings | any = new TextFillings({});
  @ViewChild(ModalComponent, { read: ModalComponent, static: false })


  dialog!: ModalComponent; 
  constructor(private textFillingsServices: TextFillingsService, private toastrService: ToastrService) { }

  ngOnInit(): void {
  }
  openDialoge(text: string): void {
    this.questions = this.tableauTextFillings.find(elm => elm.label == String(text))
    this.questions==undefined?this.questions=new TextFillings({label:text}):"";

    this.dialog.open()
  }
  valueParDefautQuestion1() {
    this.Question1Ap3.emit()
  }
  callUpdateQuestion(updateQuestion: TextFillings) {
    this.textFillingsServices.updateTextFillingsDoctor(String(updateQuestion.id), this.questions).subscribe(
      res => {
        this.toastrService.info('Mise a jour  Question')
        this.questions = res
        this.dialog.close()
      }
    )
  }
  callAddQuestion(question:TextFillings):void{
    this.questions=question
    this.textFillingsServices.addTextFillings(question).subscribe(
      res=>{
        this.toastrService.success('Question Ajouter')

        window.location.reload()

      }
    )

  }
}