

  <div>
    <nav class="tree-nav scroll" >

      <div class="card" style="padding-bottom: 15px;">
        <div class="card-body" *ngIf="patientProfileForm && patientAssuranceForm">
          <form [formGroup]="patientProfileForm" (submit)="onSubmit()">
           
            <div class="row form-row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Prénom: *</label>
                  <form-input [placeholder]="'Prénom'" [append]="'fi feather-user'" [required]="true" name="firstName" formControlName="firstName"></form-input>
                  <span *ngIf="isSubmitted && patientProfileForm.controls.firstName.errors?.required" class="invalid-input">Prénom Obligatoire</span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Nom: *</label>
                  <form-input [placeholder]="'Nom'" [append]="'fi feather-user'" [required]="true" name="lastName" formControlName="lastName"></form-input>
                  <span *ngIf="isSubmitted && patientProfileForm.controls.lastName.errors?.required" class="invalid-input">Nom
                    Obligatoire</span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Date Naissance: *</label>
                  <date-picker [append]="'fi feather-calendar'" [required]="true" name="birthDate" formControlName="birthDate"></date-picker>
                  <span *ngIf="isSubmitted && patientProfileForm.controls.birthDate.errors?.required" class="invalid-input">Date Naissance Obligatoire</span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Pays:</label>
                  <select-drop-down [items]="countries" [options]="{ filter:{ byKey:'name' } }" [value]="currentCountry" formControlName="country" name="country">
                    <ng-template let-item="item">
                      <div class="country-item">
                        <img [src]="item.flag">
                        <span>{{item.name}}</span>
                      </div>
                    </ng-template>
                  </select-drop-down>
                </div>
              </div>
      
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Ville: </label>
                  <form-input [placeholder]="'Ville'" [type]="'text'" formControlName="government" name="government"></form-input>
                  <div *ngIf="isSubmitted">
                    <span *ngIf="f['government'].hasError('required')" style="color: red"> La Ville est
                      obligatoire</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Adresse: </label>
                  <form-input [placeholder]="'Adresse'" [append]="'fi feather-map-pin'" name="address" formControlName="address"></form-input>
                  <span *ngIf="isSubmitted && patientProfileForm.controls.address.errors?.required" class="invalid-input">Adresse Obligatoire</span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Email: </label>
                  <form-input [placeholder]="'E-mail'" [append]="'fi feather-mail'" name="email" formControlName="email"></form-input>
                  <span *ngIf="isSubmitted && patientProfileForm.controls.email.errors?.email" class="invalid-input">Email
                    invalid format</span>
                  <span *ngIf="isSubmitted && patientProfileForm.controls.email.errors?.exists" class="invalid-input">Utilisateur Déja Existant</span>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Mobile: * </label>
                  <form-input [prependHtml]="PhonePrependHtml" [placeholder]="'Telephone mobile'" [type]="'text'" formControlName="mobilePhone1" name="mobilePhone1"></form-input>
                  <div *ngIf="isSubmitted">
                    <span *ngIf="f['mobilePhone1'].hasError('required')" style="color: red"> Le Téléphone Mobile est
                      obligatoire</span>
                  </div>
                </div>
              </div>
           
              <div class="row">
                <div class="form-group">
                  <label for="gender">Homme </label>
                  <input type="radio" name="gender" formControlName="gender" [value]="0" style="margin:8px">
                  <label for="gender">Femme</label>
                  <input type="radio" name="gender" formControlName="gender" [value]="1" style="margin:8px">
                </div>
              </div>
             
            </div>
          </form>
        
        </div>
        <div class="submit-section" style="margin-bottom: 20px;position: absolute; bottom: 5px;right: 5px;">
          <button (click)="onSubmit()" class="btn btn-primary submit-btn">
            {{ isLoading ? "Charger..." : "Enregistrer" }}
          </button>
        </div>
      </div>
        </nav>
</div>