import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MILLISECONDS_IN_DAY, MILLISECONDS_IN_HOUR, MILLISECONDS_IN_MINUTE } from 'src/app/utils/date.util';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.css']
})
export class TimeInputComponent implements OnInit {

  _min: number = 0;
  _max: number = MILLISECONDS_IN_DAY;

  @Input() time: number = 0;
  @Input() set min(min: number) {
    if (min < this._max) {
      this._min = min;
      if (this.time < min) {
        this.time = min;
        this.timeChange.emit(this.time);
      }
    }

  };
  @Input() set max(max: number) {
    if (max > this._min) {
      this._max = max;
      if (this.time > max) {
        this.time = max;
        this.timeChange.emit(this.time);
      }
    }
  };

  @Output() timeChange: EventEmitter<number> = new EventEmitter();

  get minutes(): number {
    return Math.round((this.time % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE)
  }

  get hours(): number {
    return Math.floor((this.time / MILLISECONDS_IN_HOUR));
  }

  constructor() { }

  ngOnInit(): void {
  }

  _onTimeChange(event: any): void {
    const time = (Number(String(event.target.value).split(':')[0]) * MILLISECONDS_IN_HOUR) + (Number(String(event.target.value).split(':')[1]) * MILLISECONDS_IN_MINUTE)
    if (time <= this._max && time >= this._min) {
      this.time = time;
      this.timeChange.emit(this.time);
    } else
      event.target.value = this._timeFormat(this.time)
  }

  _timeFormat(millseconds: number): string {
    const hours = Math.floor((millseconds / MILLISECONDS_IN_HOUR));
    const minutes = Math.round((millseconds % MILLISECONDS_IN_HOUR) / MILLISECONDS_IN_MINUTE)
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
  }
}
