<div class="booking-schedule schedule-widget  rounded m-3">
  <div class="schedule-header text-white  rounded">
    <div class="row style">
      <div class="col-lg-3 col-sm-12 changeStyleDate">
        <!-- <date-picker (dateChange)="_dateChangeHandler($event)" [min]="currentDate"></date-picker> -->
        <datepicker (change)="_dateChangeHandler($event)"></datepicker>
      </div>
      <div class="col-lg-4 col-sm-12">
        <!-- <select [(ngModel)]="filtre" class="form-control">
          <option [value]="0">En Cabinet</option>
          <option [value]="1">A Distance</option>
        </select> -->
        <div class="row">
          <div class="form-group">
              <label for="gender" class="label">En Cabinet </label>
              <input type="radio" name="gender" [(ngModel)]="filtre"  [value]="0" style="margin:8px">
              <label for="gender" class="label">A Distance</label>
              <input type="radio" name="gender" [(ngModel)]="filtre" [value]="1" style="margin:8px">
          </div>
      </div>
      </div>
      <div class="col-lg-3 col-sm-12">
        <select [(ngModel)]="appointmentDescription" name="patientPays" (change)="motifsSelect($event)" class="change">
          <option [value]="'Consultation Régulière'" selected>Consultation Régulière</option>
          <option [value]="'Consultation Controle'" selected>Consultation Controle</option>
          <option *ngFor="let motif of motifs" [value]="motif.motif" [ngStyle]="{'color':motif.color || '#820040'}">
            <span [ngStyle]="{'color':motif.color || '#820040'}">{{motif.motif}}</span>
          </option>
        </select>
        <span *ngIf="appointmentDescription.length==0" style="color:red">Description RDV Obligatoire</span>

      </div>
      <div class="col-lg-4 col-sm-12 mt-2">
        <span *ngIf="time.length>0" style="color:black"><span style="font-weight: bold;">RDV planifié</span> :{{time[1]
          |frenchDate}} {{ time[0] |time }} </span>
      </div>
    </div>
  </div>
  <div class="schedule-cont">
    <div class="day-slot">
      <ul>
        <div class="row schedule-cont">
          <div style="width:3%">
            <button class="openDialoge" (click)="_moveToLastWeek()"><i class="feather-skip-back"></i></button>
          </div>
          <div style="width:97%">
            <li *ngFor="let x of [].constructor(7); let i = index">
              <div [ngClass]="
                    !disponibility
                      ? 'border day border-primary rounded'
                      : disponibility &&
                        disponibility.date.getTime() == dateInWeek(i).getTime()
                      ? 'border day border-danger rounded'
                      : 'border day border-primary rounded'
                  ">
                <span class="font-weight-bold">{{ getLiteralDay(dateInWeek(i)) }}</span>
                <span class="slot-date">{{ dateInWeek(i) | date: "dd.MM" }}</span>
              </div>
            </li>
            <button class="openDialoge" (click)="__moveToNextWeek()"><i class="feather-skip-forward"></i></button>
          </div>
        </div>
      </ul>
    </div>
    <div class="time-slot">
      <ul class="clearfix">
        <li *ngFor="let day of dataSource" style="cursor: pointer" style="text-align: center">
          <a *ngFor="
                  let disponibilityItem of day.availabilities
                    | doctorAvailabilitiesFilter: filtre;
                  let disponibilityIndex = index
                " class="timing" [ngClass]="{
                  disabled: false,
                  selected: isSelected(disponibilityItem),
                  deleted: false
                }" (click)="onDsiponibilityChange(disponibilityItem)">
            <span>{{ disponibilityItem.startTime | time }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>