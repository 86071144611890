import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { User, EUserRole } from 'src/app/entities/user.entity';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { DoctorSecretary } from 'src/app/entities/user.entity/doctor.entity/secretary.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';

@Component({
  selector: 'app-appointment-patient-login',
  templateUrl: './appointment-patient-login.component.html',
  styleUrls: ['./appointment-patient-login.component.css']
})
export class AppointmentPatientLoginComponent implements OnInit {

  submited: boolean = false;
  isLoading: boolean = false;
  loginForm: FormGroup;
  @Input() dir:string="fr"
  @Input() patientAppointment!: PatientAppointment;

  get valid(): boolean {
    return this.loginForm.valid;
  }

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthService,

  ) {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      userPassword: ['', [Validators.required, Validators.minLength(6)]],
    });
  }

  ngOnInit(): void {

  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit(): Promise<PatientAppointment> {
    return new Promise((resolve, reject) => {
      this.submited = true;
      this.isLoading = true;
      if (!this.loginForm.valid) return;
      this.authService.login({ email: this.f['userName'].value, password: this.f['userPassword'].value }).subscribe(
        (response) => {
          this.patientAppointment.requested = false;
          let user: User | Doctor | DoctorSecretary = new User();
          const stringUserRole = (response.data.user.role as any);

          if (stringUserRole == 'Medecin') {
            user = Doctor.fromResponse(response.data.user);
            user.accessToken = response.data.authToken;
            this.authService.setCurrentUser(user);
          }
          else if (stringUserRole == "Secretary") {
            user = DoctorSecretary.fromResponse(response.data.user);
            user.accessToken = response.data.authToken;
            this.authService.setCurrentUser(user);
          }
          else if (stringUserRole == 'Pharmacist') {
            user = User.fromResponse(response.data.user);
            user.role = EUserRole.PHARMACIST;
            user.accessToken = response.data.authToken;
            this.authService.setCurrentUser(user);

          } else {
            user = User.fromResponse(response.data.user);
            user.accessToken = response.data.authToken;
            this.authService.setCurrentUser(user);
          }

          resolve(this.patientAppointment);
        },
        (error) => {
          this.isLoading = false;
          switch (error.status) {
            case 400: {
              this.toaster.error('Service error !', 'Invalid login!');
            }; break;
            case 403: {
              this.f.userPassword.setErrors({ invalidPassword: true });
            } break;
            case 404: {
              this.f.userName.setErrors({ notFound: true });
            } break;
            case 408: {
              this.toaster.error('Connectivity error!', 'Invalid login!');
            }; break;
            case 500: {
              this.toaster.error('Server error!', 'Invalid login!');

            }; break;
            default: {
              this.toaster.error('Login errror', 'Invalid login!');
            }
          }
          reject()
          //this.toastr.error('', 'Invalid login!');
        }
      );
    })
  }

}
