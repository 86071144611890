import { EDalyTimeSlotLocation } from "../daly-time-slot.entity/daly-time-slot";

export enum AppointmentInvoiceTransactionStatus {
    UNKNOWN,
    PENDING,
    COMPLETED
}

export interface IDoctorAppointmentBill {
    id: string;
    appointmentId: string;
    cnamRef: string;
    insurance: string;
    insuranceOrganization: string;
    insuranceRef: string;
    consultationAmount: number;
    regularConsultationAmount: number;
    consultationEntriesAmount: number;
    regularConsultationEntriesAmount: number;
    totalAmount: number;
    regularAmount: number;
    isRegulated: boolean;
    note: string;
    paymentMethod: number
    paidAmount: number;
    apcis: Array<number>;
    transactionStatus: AppointmentInvoiceTransactionStatus;
    transactionUrl?: string;
    transactionId?: string;
    location: EDalyTimeSlotLocation;


}

export class DoctorAppointmentBill implements IDoctorAppointmentBill {
    id: string;
    appointmentId: string;
    cnamRef: string;
    insurance: string;
    insuranceOrganization: string;
    insuranceRef: string;
    consultationAmount: number;
    regularConsultationAmount: number;
    consultationEntriesAmount: number;
    regularConsultationEntriesAmount: number;
    totalAmount: number;
    regularAmount: number;
    isRegulated: boolean;
    note: string;
    paymentMethod: number;
    paidAmount: number;
    apcis: Array<number>;
    location: EDalyTimeSlotLocation;

    transactionStatus: AppointmentInvoiceTransactionStatus;
    transactionUrl?: string;
    transactionId?: string;

    get restToPay(): number {
        return this.regularAmount - this.paidAmount;
    }

    constructor(other?: Partial<IDoctorAppointmentBill>) {
        this.id = other?.id || '';
        this.isRegulated = other?.isRegulated || false;
        this.appointmentId = other?.appointmentId || '';
        this.cnamRef = other?.cnamRef || '';
        this.insurance = other?.insurance || '';
        this.insuranceRef = other?.insuranceRef || '';
        this.insuranceOrganization = other?.insuranceOrganization || '';
        this.consultationAmount = other?.consultationAmount || 0;
        this.consultationEntriesAmount = other?.consultationEntriesAmount || 0;
        this.totalAmount = other?.totalAmount || 0;
        this.note = other?.note || '';
        this.paymentMethod = other?.paymentMethod || 0;
        this.regularConsultationEntriesAmount = this.isRegulated ? other?.regularConsultationEntriesAmount || 0 : this.consultationEntriesAmount;
        this.regularAmount = this.isRegulated ? other?.regularAmount || 0 : this.totalAmount;
        this.paidAmount = other?.paidAmount || 0;

        this.regularConsultationAmount = other?.regularConsultationAmount || 0;
        this.apcis = other?.apcis || []
        this.location = other?.location || EDalyTimeSlotLocation.ATSITE;

        this.transactionStatus = other?.transactionStatus || AppointmentInvoiceTransactionStatus.UNKNOWN;
        this.transactionUrl = other?.transactionUrl;
        this.transactionId = other?.transactionId;

    }

    static fromResponse(res: any): DoctorAppointmentBill {
        
        return new DoctorAppointmentBill({

            id: res.id,
            appointmentId: res.appointmentId,
            cnamRef: res.cnamRef,
            insurance: res.insurance,
            insuranceRef: res.insuranceRef,
            insuranceOrganization: res.insuranceOrganization,
            consultationAmount: res.regularAmount,
            consultationEntriesAmount: res.consultationEntriesAmount,
            regularConsultationEntriesAmount: res.regularConsultationEntriesAmount,
            totalAmount: res.totalAmount,
            regularAmount: res.regularAmount,
            isRegulated: res.regulated,
            paidAmount: res.paidAmount,
            regularConsultationAmount: res.regularConsultationAmount,
            note: res.note,
            paymentMethod: res.paymentMethod,
            apcis: res.apcis,
            location: res.location,
            transactionId: res.transactionId,
            transactionUrl: res.transactionUrl,
            transactionStatus: res.transactionStatus
        });
    }
}

export interface IPatientAppointmentBill {
    id: string;
    appointmentId: string;
    consultationAmount: number;
    consultationEntriesAmount: number;
    totalAmount: number;
    paidAmount: number;
    transactionStatus: AppointmentInvoiceTransactionStatus;
    transactionUrl?: string;
    transactionId?: string;

    totalAmountUSD: number;
    totalAmountEUR: number;
    totalAmountTND: number;
}

export class PatientAppointmentBill implements IPatientAppointmentBill {
    id: string;
    appointmentId: string;
    consultationAmount: number;
    consultationEntriesAmount: number;
    totalAmount: number;
    paidAmount: number;

    transactionStatus: AppointmentInvoiceTransactionStatus;
    transactionUrl: string;
    transactionId: string;

    totalAmountUSD: number;
    totalAmountEUR: number;
    totalAmountTND: number;

    get restToPay(): number {
        return this.totalAmount - this.paidAmount;
    }

    constructor(other?: Partial<IPatientAppointmentBill>) {
        this.id = other?.id || '';
        this.appointmentId = other?.appointmentId || '';
        this.consultationAmount = other?.consultationAmount || 0;
        this.consultationEntriesAmount = other?.consultationEntriesAmount || 0;
        this.totalAmount = other?.totalAmount || 0;
        this.paidAmount = other?.paidAmount || 0;

        this.transactionStatus = other?.transactionStatus || AppointmentInvoiceTransactionStatus.UNKNOWN;
        this.transactionUrl = other?.transactionUrl || '';
        this.transactionId = other?.transactionId || '';

        this.totalAmountUSD = other?.totalAmountUSD || 0;
        this.totalAmountEUR = other?.totalAmountEUR || 0;
        this.totalAmountTND = other?.totalAmountTND || 0;


    }

    static fromResponse(res: any): PatientAppointmentBill {
        return new PatientAppointmentBill({
            id: res.id,
            appointmentId: res.appointmentId,
            consultationAmount: res.consultationAmount,
            consultationEntriesAmount: res.consultationEntriesAmount,
            totalAmount: res.totalAmount,
            paidAmount: res.paidAmount,
            transactionId: res.transactionId,
            transactionUrl: res.transactionUrl,
            transactionStatus: res.transactionStatus,

            totalAmountUSD: res.totalAmountUSD,
            totalAmountEUR: res.totalAmountEUR,
            totalAmountTND: res.totalAmountTND

        });
    }
}
