export default [
  {
    name: "Tunis",
    nameArabe:"تونس"
  },
  {
    name: "Zaghouan",
    nameArabe:"زغوان"

  },
  {
    name: "Ariana",
    nameArabe:"أريانة"

  },
  {
    name: "Béja",
    nameArabe:"باجة"

  },
  {
    name: "Ben Arous",
    nameArabe:"بن عروس"

  },
  {
    name: "Gabès",
    nameArabe:"قابس"

  },
  {
    name: "Bizerte",
    nameArabe:"بنزرت"

  },
  {
    name: "Gafsa",
    nameArabe:"قفصة"

  },
  {
    name: "Tozeur",
    nameArabe:"توزر"

  },
  {
    name: "Tataouine",
    nameArabe:"تطاوين"

  },
  {
    name: "Sousse",
    nameArabe:"سوسة"

  },
  {
    name: "Siliana",
    nameArabe:"سليانة"

  },
  {
    name: "Sfax",
    nameArabe:"صفاقس"

  },
  {
    name: "Nabeul",
    nameArabe:"نابل"

  },
  {
    name: "Monastir",
    nameArabe:"المنستير"

  },
  {
    name: "Médenine",
    nameArabe:"مدنين"

  },
  {
    name: "Manouba",
    nameArabe:"منوبة"

  },
  {
    name: "Mahdia",
    nameArabe:"المهدية"

  },
  {
    name: "Kef",
    nameArabe:"الكاف"

  },
  {
    name: "Kébili",
    nameArabe:"قبلي"

  },
  {
    name: "Kasserine",
    nameArabe:"القصرين"

  },
  {
    name: "Kairouan",
    nameArabe:"القيروان"

  },
  {
    name: "Jendouba",
    nameArabe:"جندوبة"

  },
];
