import { Prescription } from "..";
import { Appointment } from "../../appointment.entity";
import { DoctorAppointment } from "../../appointment.entity/doctor-appointment.entity/doctor-appointment";
import { User } from "../../user.entity";
import { BioPrescription } from "../bio-prescription.entity/bio-prescription";
import { MedicPrescription } from "../medic-prescription.entity/medic-prescription";
import { RadioPrescription } from "../radio-prescription.entity/radio-prescription";

export class DoctorPrescription extends Prescription {
    appointment: DoctorAppointment;
    patient: User;
    constructor(other?: Partial<DoctorPrescription>) {
        super(other)
        this.appointment = other?.appointment || new DoctorAppointment();
        this.patient = other?.patient || new User();
    }

    public static fromResponse(res: any): DoctorPrescription {
        return new DoctorPrescription({
            id: res.id || res.prescriptionId,
            date: new Date(res.date),
            medics: Array.from(res.medics || res.prescriptionMedicaments, (elm: any) => MedicPrescription.fromResponse(elm)),
            biologies: Array.from(res.biologies || res.prescriptionBiologies, (elm: any) => BioPrescription.fromResponse(elm)),
            radiologies: Array.from(res.radiologies || res.prescriptionRadiologies, (elm: any) => RadioPrescription.fromResponse(elm)),
            appointment: res.appointment ? DoctorAppointment.fromResponse(res.appointment) as any : null,
            patient:res.patient?User.fromResponse(res.patient):new User()
        })
    }
}
