import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dashboradPaiement'
})
export class DashboradPaiementPipe implements PipeTransform {

  transform(dashboradPaiement: number ): any {
    if (typeof dashboradPaiement === 'number' && !isNaN(dashboradPaiement)) {
      // Retourne directement le nombre si c'est un entier
      if (Number.isInteger(dashboradPaiement)) {
        return dashboradPaiement;
      }
      // Sinon, applique toFixed(3)
      return dashboradPaiement.toFixed(3);
    }
    // Retourne 0 si la valeur n'est pas un nombre valide
    return 0;
  }

}
