import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCurrency'
})
export class FilterCurrencyPipe implements PipeTransform {

  transform(currency: string): string {
    let currencyFilter=""
    currency=='TND'?currencyFilter="DT":currency=="EUR"?currencyFilter="EUR":currencyFilter="USD"
    return currencyFilter;
  }

}
