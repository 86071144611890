import { AfterViewInit, Directive, ElementRef, EmbeddedViewRef, EventEmitter, Input, OnInit, Optional, Output, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { HeaderOptions, PageRequestFn, SortOrder } from '../../interfaces';


@Directive({
  selector: '[dataTableHead]'
})
export class DataTableHeadDirective implements AfterViewInit, OnInit {
  @Input() set dataTableHead(options: Partial<HeaderOptions>) {
    this.options = options;
  }
  @Input() options: Partial<HeaderOptions> = {};
  @Output() onSort: EventEmitter<DataTableHeadDirective> = new EventEmitter();
  //@ContentChild('sorter', { static: true }) sorterTemplateRef!: TemplateRef<any>;
  @Input() sortOrder: SortOrder = 'none';
  viewRef!: EmbeddedViewRef<any>;
  constructor(
    private viewContainer: ViewContainerRef, private elementRef: ElementRef, private rendrer: Renderer2, @Optional() private templateRef?: TemplateRef<any>) { }
  ngOnInit(): void {
    if (this.templateRef) {
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, { sortOrder: this.sortOrder });
      this.rendrer.listen(this.viewRef.rootNodes.values().next().value, 'click', ($event: Event) => {
        this.onSortRequested();
        $event.stopPropagation()
      })
    } else {
      this.rendrer.listen(this.elementRef!.nativeElement, 'click', ($event: Event) => {
        this.onSortRequested();
        $event.stopPropagation()
      })
    }
  }

  ngAfterViewInit(): void {

  }

  onSortRequested(): void {
    this.onSort.emit(this);
  }

  sort(order: SortOrder): void {
    this.sortOrder = order;
    if (this.templateRef) {
      this.viewContainer.clear()
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, { sortOrder: this.sortOrder });
      this.rendrer.listen(this.viewRef.rootNodes.values().next().value, 'click', ($event: Event) => {
        this.onSortRequested();
        $event.stopPropagation()
      })
    }
  }

}
