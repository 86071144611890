  <div class="card" *ngIf="creationPatient==false || filter==false" style="height: 60vh;">
    <div class="card-body">
      <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 15px;">
          <select-drop-down
            [items]="doctorPatients"
            [(ngModel)]="userTarget"
            [placeHolder]="'Chercher ici Patient'"
            [options]="{ filter:{ byKey:'fullName',with:'containe' } }"
            style="width: 97%;"
            >
            <ng-template let-item="item">
              <div class="user-item">
                <img [src]="item | avatarSrc" />
                <span>{{item.getFullName()}}</span>
              </div>
            </ng-template>
          </select-drop-down>
          <button class="btn btn-primary button" style="height: 40px;" (click)="creationPatient=true;filter=!filter">
            <i class="feather-plus"></i>
          </button>
        </div>

          <div style="display: flex; flex-direction: column;">

          <div class="divRow">
            <label class="mt-1 label">Date Rdv</label>
            <input type="text" class="form-control" [value]="date" />
          </div>
          <div class="divRow">
            <!-- <label class="mt-1 label">Localtion</label>
            <select [(ngModel)]="location" class="form-control">
              <option [value]="0">En Cabinet</option>
              <option [value]="1">A Distance</option>
            </select> -->
            <label class="mt-1 label">Localtion</label>
      
              <div class="form-group">
      
                  <label for="gender" style="color: black;" >En Cabinet </label>
                  <input type="radio" name="gender" [(ngModel)]="location"  [value]="0" style="margin:8px">
                  <label for="gender" style="color: black;" >A Distance</label>
                  <input type="radio" name="gender" [(ngModel)]="location" [value]="1" style="margin:8px">
              </div>
          </div>
          <div class="divRow">
            <label class="mt-1 label">Heure Rdv:</label>
            <select [(ngModel)]="disponibilite" (change)="change($event)" class="form-control">
              <option [ngValue]="disponibilite" *ngIf="disponibiliteDefalut==0 && changeValueDisponibilte==false" disabled selected
                >Choisir une option</option
              >
              <option [ngValue]="disponibilite" *ngIf="disponibiliteDefalut!==0 && changeValueDisponibilte==false" disabled selected
                >{{disponibiliteDefalut |time}}</option
              >
              <option *ngFor="let dispo  of disponibilites |disponibiliteFilter:location " [ngValue]="dispo"
                ><span>{{dispo.startTime |time }}</span>
              </option>
              <option *ngIf="(disponibilites | disponibiliteFilter:location)?.length === 0" [ngValue]="null" disabled>
                Aucune disponibilité
              </option>
            </select>
          </div>
          <div class="divRow">
            <label class="mt-1 label">Motif:</label>
            <select [(ngModel)]="doctorAppointment.motif" name="patientPays" class="form-control">
              <option [value]="'Consultation Régulière'" selected>Consultation Régulière</option>
              <option [value]="'Consultation Controle'" selected>Consultation Controle</option>
              <option *ngFor="let motif of consultationMotifs" [value]="motif.text" selected [ngStyle]="{'color':motif.color || '#820040'}"
                ><span [ngStyle]="{'color':motif.color || '#820040'}">{{motif.text}}</span></option
              >
            </select>
          </div>
          <div class="submit-section" style="margin-bottom: 25px; position: absolute; bottom: 5px; right: 5px;">
            <button class="btn btn-primary submit-btn" (click)="sumbit()">Enregistrer</button>
          </div>
        </div>
       
    </div>
  </div>
<app-create-patient-secretary
  *ngIf="creationPatient==true && filter==true "
  (completed)="refresh($event)"
  [(isFilter)]="creationPatient"
></app-create-patient-secretary>
