import { Component, Input, OnInit } from '@angular/core';
import { Antecedent } from 'src/app/entities/atced.entity/antecedent';
import { DoctorPatientAntecedentsService } from 'src/app/services/doctor-patient-antecedents.services/doctor-patient-antecedent.service';

@Component({
  selector: 'app-patient-antecedents-details',
  templateUrl: './patient-antecedents-details.component.html',
  styleUrls: ['./patient-antecedents-details.component.css']
})
export class PatientAntecedentsDetailsComponent implements OnInit {

  @Input() doctorPatientId!: string;
  @Input() doctorPatientAntecedents!: Antecedent;

  constructor(private doctorPatientAntecedentsService: DoctorPatientAntecedentsService) { }

  ngOnInit(): void {
    if (this.doctorPatientId && !this.doctorPatientAntecedents) this.doctorPatientAntecedentsService.one(this.doctorPatientId).subscribe(res => {
      this.doctorPatientAntecedents = Antecedent.fromResponse(res);
    })
  }

  get antcedents(): Array<{ label: string, isActivated: boolean, text: string }> {
    let antcedents: Array<{ label: string, isActivated: boolean, text: string }> = new Array();
    if (this.doctorPatientAntecedents) {
      if (this.doctorPatientAntecedents.allergieAlert)
        antcedents.push({ isActivated: true, label: 'Allergie', text: this.doctorPatientAntecedents.allergie });
      if (this.doctorPatientAntecedents.familiauxAlert)
        antcedents.push({ isActivated: true, label: 'Familiaux', text: this.doctorPatientAntecedents.familiaux });
      if (this.doctorPatientAntecedents.medicauxAlert)
        antcedents.push({ isActivated: true, label: 'Médicaux', text: this.doctorPatientAntecedents.medicaux });
      if (this.doctorPatientAntecedents.pharmacieAlert)
        antcedents.push({ isActivated: true, label: 'Médications', text: this.doctorPatientAntecedents.pharmacie });
      if (this.doctorPatientAntecedents.chirugicauxAlert)
        antcedents.push({ isActivated: true, label: 'Chirugicaux', text: this.doctorPatientAntecedents.chirugicaux });
      if (this.doctorPatientAntecedents.intoreranceAlert)
        antcedents.push({ isActivated: true, label: 'Intorerance', text: this.doctorPatientAntecedents.intorerance });
      if (this.doctorPatientAntecedents.rhumatoAlert)
        antcedents.push({ isActivated: true, label: 'Rhumato', text: this.doctorPatientAntecedents.rhumato });
    }
    return antcedents;
  }


}
