import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import * as moment from 'moment';
import { DoctorDashboardService } from 'src/app/services/doctor-dashboard.service/doctor-dashboard.service';

@Component({
  selector: 'app-chart-rdv-by-periode',
  templateUrl: './chart-rdv-by-periode.component.html',
  styleUrls: ['./chart-rdv-by-periode.component.css']
})
export class ChartRdvByPeriodeComponent implements OnInit {
  constructor(private doctorDashboardService: DoctorDashboardService) {}
  public chart!: Chart;
  startDate = new Date(new Date().getFullYear(), new Date().getMonth()-1);
  finDate: Date =  new Date(new Date().getFullYear(), new Date().getMonth() , 0);

  public rdvs: Array<number> = [];

  ngOnInit() {
    this.staticRdv();
  }

 
  staticRdv() {
    const startDate = new Date(this.startDate).getTime();
    const endDate = new Date(this.finDate).getTime();
    this.doctorDashboardService.staticRdvs(startDate, endDate).subscribe((res) => {
     
      if (this.chart) {


        this.chart.destroy()
      }
      this.chart = new Chart('rdv-peroide', {
        type: "line",

        data: {
          labels: res.map((elm) => moment(new Date(elm.creationDate)).format("DD/MM/YYYY")),
          datasets: [
            {
              label: `Nombre de Rendez-vous Entre ${moment(startDate).format("DD/MM/YYYY")} Et ${moment(endDate).format("DD/MM/YYYY")}`,
              data: res.map((elm)=>elm.pCount),
              fill:true,
              backgroundColor: [
              "transparent"
              ],
              borderColor: [
                "rgba(69, 204, 206,1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
              tension:0.6
            },
          ],
        },
        options: {
          responsive: true
          
        },
      });
     
    });
  }

}
