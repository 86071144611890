import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ITagInputItem } from 'src/app/common/components/tags-input.component/tags-input.component';
import { APCIS } from 'src/app/common/data/apcis';
import { DoctorPatient } from 'src/app/entities/doctor-patient.entity/doctor-patient';
import { EUserGender } from 'src/app/entities/user.entity';
import { DoctorSecretaryPatientsService } from 'src/app/services/doctor-secretary-appointments.service/doctor-secretary-patients.service/doctor-secretary-patients.service';
import countries from 'src/app/common/data/countries';

@Component({
  selector: 'app-create-patient-secretary',
  templateUrl: './create-patient-secretary.component.html',
  styleUrls: ['./create-patient-secretary.component.css']
})
export class CreatePatientSecretaryComponent implements OnInit {
  patientProfileForm!: FormGroup;
  patientAssuranceForm!: FormGroup;
  patientAccompanistForm!: FormGroup;
  imageUrl: string = '';
  isLoading: boolean = false;
  isSubmitted: boolean = false;
  assurance: string = '';
  public imageFile!: File;
  @Output() completed: EventEmitter<DoctorPatient> = new EventEmitter();
  @Input() isFilter: boolean = false;
  @Output() isFilterChange:EventEmitter<boolean>= new EventEmitter()
  file: any;
  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private doctorSecretaryPatientsService: DoctorSecretaryPatientsService,
    private router: Router
  ) { }

  get countries(): Array<any> {
    return countries;
  }  get PhonePrependHtml(): string { return `<span>${this.currentCountry?.dialCode || '+216'}</span>` }
  get currentCountry(): any { return this.patientProfileForm.controls.country.value }
  hasAssurance: boolean = true;
  hasAccompanist: boolean = true;

  get currentDate(): Date {
    return new Date();
  }

  get apicsSource(): Array<ITagInputItem> {
    return APCIS.map(e => {
      return {
        id: String(e.index),
        value: e.index,
        text: `${e.index + 1}.${e.value}`,
      }
    });
  }
  ngOnInit(): void {
    window.scrollBy(100, 0);

    this.patientProfileForm = this.formBuilder.group({
      ref: [''],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      country: [{ name: "Tunisie", dialCode: "+216", isoCode: "TN", flag: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg" }, [Validators.required]],
      address: [''],
      email: [null],
      birthDate: [null, [Validators.required]],
      gender: [EUserGender.HOMME],
      mobilePhone1: [""],
      socialCivility: [0],
      sanguineGroup: [0],
      cnamCode: [''],
      cnamAssociation: [0],
      apcis: [[]],
      patientNote:[''],
      government: ['']
    });
    this.patientAssuranceForm = this.formBuilder.group({
      //  assuranceType: ['STAR', [Validators.required]],
      //  assuranceName: ['', [Validators.required]],
      //  assuranceReference: ['', [Validators.required]],
      assuranceType: ['',],
      assuranceName: [''],
      assuranceReference: [''],
    });
    this.patientAccompanistForm = this.formBuilder.group({
      accompanistUserName: [''],
    })
  }

  get isValid(): boolean {
    return this.patientProfileForm.valid && (this.hasAccompanist ? this.patientAccompanistForm.valid : true) && (this.hasAssurance ? this.patientAssuranceForm.valid : true);
  }

  onFileSelect(event: any) {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event) =>
      (this.imageUrl = event.target?.result?.toString()!);
    this.imageFile = event.target.files[0];
  }
  get f(): any { return this.patientProfileForm.controls; }

  get patientModel(): any {
    var patientModal: Object = {
      firstName: this.patientProfileForm.controls.firstName.value,
      ref: this.patientProfileForm.controls.ref.value,
      lastName: this.patientProfileForm.controls.lastName.value,
      email: (this.patientProfileForm.controls.email.value) ? this.patientProfileForm.controls.email.value.toLocaleLowerCase() : null,
      mobilePhone1: this.patientProfileForm.controls.mobilePhone1.value,
      gender: this.patientProfileForm.controls.gender.value,
      sanguineGroup: this.patientProfileForm.controls.sanguineGroup.value,
      birthDate: new Date(this.patientProfileForm.controls.birthDate.value).getTime(),
      socialCivility: this.patientProfileForm.controls.socialCivility.value,
      country: this.patientProfileForm.controls.country.value.name,
      address: this.patientProfileForm.controls.address.value,
      government: this.patientProfileForm.controls.government.value,
      cnamCode: this.patientProfileForm.controls.cnamCode.value,
      cnamAssociation: this.patientProfileForm.controls.cnamAssociation.value,
      apcis: (this.patientProfileForm.controls.apcis.value as Array<ITagInputItem>).map(e => e.id),
      note:this.patientProfileForm.controls.patientNote.value
    }

    if (this.hasAssurance) {
      patientModal = {
        assuranceType: this.patientAssuranceForm.controls.assuranceType.value,
        assuranceName: this.patientAssuranceForm.controls.assuranceName.value,
        assuranceReference: this.patientAssuranceForm.controls.assuranceReference.value,
        assuranceOrganization: this.patientAssuranceForm.controls.assuranceType.value,
        ...patientModal
      }
    }
    return patientModal;
  }

  onSubmit(): void {
    if (this.patientAssuranceForm.controls.assuranceType.value != 'Autre') this.patientAssuranceForm.controls.assuranceName.setValue(this.patientAssuranceForm.controls.assuranceType.value);
    this.isSubmitted = true;
    if (!this.isValid) return;
    const formData = new FormData();
    formData.append('photo', this.imageFile);
    formData.append('patient', JSON.stringify(this.patientModel));
    this.isLoading = true;
    this.doctorSecretaryPatientsService.add(formData, !this.patientAccompanistForm.controls.accompanistUserName.errors && String(this.patientAccompanistForm.controls.accompanistUserName.value).length > 0 ? this.patientAccompanistForm.controls.accompanistUserName.value : null).subscribe(
      (res) => {
        this.completed.emit(DoctorPatient.fromResponse(res))
        this.toastrService.success('Patient créé avec succès');
        this.isFilter = false
        this.isFilterChange.emit(this.isFilter);        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
        if (error == 'EntityNotFoundException') {
          this.patientAccompanistForm.controls.accompanistUserName.setErrors({ notFound: true });
        }
        else if (error == 'EntityExistsException') {
          this.toastrService.warning(`Patient exists déja !`)
        } else this.toastrService.error('Problème de création de patient !')
      }
    );
  }


}
