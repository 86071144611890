
<div class="table-responsive">

  <table class="table table-hover table-center">
    <thead>
      <tr>
        <th *ngFor="let header of headers;let i = index">
          {{header.label}}
          <!-- <i class="sorter feather" [ngClass]="{'feather-chevron-up':true,'feather-chevron-down':false}"></i> -->
        </th>
        <!-- <th *ngIf="hasActions" class="text-center">Actions</th> -->
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="paiements.length;else empty">
        <tr *ngFor="let paiement of paiements;let i = index">
          <td>{{paiement.ref || '..'}}</td>
          <td>{{paiement.invoiceDate | date:'dd/MM/YYYY'}}</td>

          <td>
            <h2 class="table-avatar">
              <a *ngIf="actions.badge " class="btn btn-sm  " style="margin-right: 5px;" [ngStyle]="{'color':paiement.createdAt==date?'#ffbc34':'#badfdc'}">
                <i class="feather-star" style="font-weight: bold; font-size: 15px;"></i>
              </a>
             
              <a (click)="onUpdateRequest.emit(paiement)" style="font-family: 'Gotham Rounded Bold';font-weight: Bold;">
                {{ paiement.firstName + ' ' +  paiement.lastName }}
              </a>
            </h2>
          </td>
          <td>{{ paiement.regularConsultationAmount | dashboradPaiement}}</td>
          <td>{{ paiement.regularConsultationEntriesAmount | dashboradPaiement }}</td>
          
          <td>{{paiement.regularConsultationAmount + paiement.regularConsultationEntriesAmount | dashboradPaiement}}</td>
          <td>{{ paiement.paidAmount | dashboradPaiement }}</td>
          <td>{{ paiement.regularConsultationAmount + paiement.regularConsultationEntriesAmount - paiement.paidAmount | dashboradPaiement }}</td>
          <!-- <td *ngIf="hasActions">
            <div class="table-action">
  
              <a *ngIf="actions.edit" title="Modifier Profil" (click)="onUpdateRequest.emit(paiement)" class="btn btn-sm bg-info-light">
                <i class="feather-edit"> </i>
              </a>
              <a *ngIf="actions.assignAppointment" title="Ajouter Rdv" (click)="onAssignAppointmentRequest.emit(paiement)" routerLinkActive="router-link-active"
                class="btn btn-sm bg-success-light">
                <i class="feather-plus"></i>
              </a>
              <a *ngIf="actions.delete" title="Supprimer Patient" (click)="onDelete(i)" routerLinkActive="router-link-active" class="btn btn-sm bg-danger-light">
                <i class="feather-trash"></i>
              </a>
             
            </div>
          </td> -->
        </tr>
      </ng-container>
      <ng-template #empty>
        <tr>
          <td colspan="8" style="text-align: center;">{{emptyMessage || 'Aucun paiement trouvé !'}}</td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>

  <app-pagination [pageRequest]="pageRequest" [refresh]="onRefresh" (content)="paiements = $event"></app-pagination>