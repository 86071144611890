<div class="row row-grid" *ngIf="doctorAppointments.length;else empty">
  <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let appointment of doctorAppointments | paginate: { itemsPerPage: perPage, currentPage: currentPage }">
    <div class="card widget-profile pat-widget-profile">
      <div class="card-body">
        <div class="pro-widget-content">
          <div class="profile-info-widget">
            <a class="booking-doc-img" [routerLink]="['../','patients',appointment.patient.id]">
              <img [src]="appointment.patient | avatarSrc" [alt]="appointment.patient.getFullName()" image />
            </a>
            <div class="profile-det-info">
              <h3><a class="label" [routerLink]="['../','patients',appointment.patient.id]">{{appointment.patient.getFullName(true)}}

                </a></h3>
              <div class="patient-details">
                <h5><b>Date Rdv:</b> {{ appointment.date | date:'dd/MM/yyyy'}}</h5>
                <h5 class="mb-0">
                  {{appointment.motif}}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div class="patient-info">
          <ul>
            <li>location: <span>{{appointment.location? "A Distance": "Présentielle"}}</span></li>
            <li>Rdv <span>{{appointment.startTime |time}}</span></li>
            <!-- <li>Blood Group <span>AB+</span></li> -->
          </ul>
        </div>
        <div style="justify-content: center;margin-top: 15px;margin-left: 80px;">
          <a *ngIf="appointment.status == 0" href="javascript:void(0);" class="btn btn-sm bg-warning-light">
            <i class="feather-clock"></i>
            En Attente
          </a>
          <a *ngIf="appointment.status == 1" href="javascript:void(0);" class="btn btn-sm bg-success-light">
            <i class="feather-check-circle"></i>
            Confirmé
          </a>
          <a *ngIf="appointment.status == 2" href="javascript:void(0);" class="btn btn-sm bg-danger-light">
            <i class="feather-x-circle"></i>
            Annulé
          </a>
          <a *ngIf="appointment.status == 3" href="javascript:void(0);" class="btn btn-sm bg-info-light">
            <i class="feather-check"></i>
            Cloturé
          </a>
        </div>
        <div class="table-action">
          <ng-container *ngIf="!appointment.isOld else oldAppointment">
            <a *ngIf="((appointment.status == 1  && !(appointment.hasConsultation || appointment.hasPrescription))) && actions.edit" title="Mise à Jour"
              class="btn btn-sm bg-warning-light" (click)="onEditRequest.emit(appointment)">
              <i class="feather-edit"></i>
            </a>
            <a *ngIf="appointment.status == 1 && actions.payementsView" (click)="onBillingRequest.emit(appointment)" title="Paiement" class="btn btn-sm bg-primary-light">
              <i class="feather-credit-card"></i>
            </a>
            <a *ngIf="((appointment.status == 0 || appointment.status == 1  )) && !(appointment.hasConsultation || appointment.hasPrescription) && actions.reject" title="Annuler"
              class="btn btn-sm bg-danger-light" (click)="onReject.emit(appointment)">
              <i class="feather-x"></i>
            </a>
            <a *ngIf="appointment.status == 0 && actions.accept && appointment.patient.id!==''" title="Confirmé" (click)="onAccept.emit(appointment)"
              class="btn btn-sm bg-success-light">
              <i class="feather-check-circle"></i>
            </a>
            <a *ngIf="appointment.status == 0 && actions.affect && appointment.patient.id==''" title="Assigner" (click)="onAffect.emit(appointment)"
              class="btn btn-sm bg-primary-light">
              <i class="feather-user"></i>
            </a>
            <a *ngIf="canOpenTeleconsultation(appointment)" title="Tele-consultation" (click)="onViewTeleConsultationRequest.emit(appointment)" class="btn btn-sm bg-success-light">
              <i class="feather-play-circle"></i>
            </a>
            <app-drop-down-menu *ngIf="(actions.consultations || actions.prescription || actions.attachmentsView)&& doctor.packPro">
              <a title="Ajouter Rdv" routerLinkActive="router-link-active" class="btn btn-sm bg-success-light" #trigger trigger>
                <i class="feather feather-menu"></i>
              </a>
              <ul class="menu" #content content>
                <li *ngIf="appointment.status == 1 && actions.consultations">
                  <a (click)="appointment.hasConsultation?onViewConsultationRequest.emit(appointment):onAssignConsultationRequest.emit(appointment)"
                    routerLinkActive="router-link-active">
                    <i class="feather feather-book-open"></i>
                    <span>
                      {{appointment.hasConsultation?
                      'List consultations':
                      'Ajout consultation'}}
                    </span>
                  </a>
                </li>
                <li *ngIf="appointment.status == 1 && actions.prescription">
                  <a (click)="appointment.hasPrescription?onViewPrescriptionRequest.emit(appointment):onAssignPrescriptionRequest.emit(appointment)">
                    <i class="{{appointment.hasPrescription?'feather-eye':'feather-clipboard'}}"></i><span>
                      {{appointment.hasPrescription?'Prescriptions':'Ajout prescription'}}
                    </span>
                  </a>
                </li>
                <li *ngIf="appointment.status == 1 && actions.attachmentsView">
                  <a title="Attacher un document medical" (click)="onViewAttachmentsRequest.emit(appointment)">
                    <i class="feather-file-plus"></i><span>Ajout document</span>
                  </a>
                </li>
                <li *ngIf="actions.attestation">
                  <a title="Ajout Attestation" (click)="onViewAttestationsRequest.emit(appointment)">
                    <i class="feather-plus-circle"></i><span>Ajout Attestation</span>
                  </a>
                </li>
              </ul>
            </app-drop-down-menu>
          </ng-container>
          <ng-template #oldAppointment>
            <a title="View details" class="btn btn-sm bg-warning-light">
              <i class="feather-eye"></i>
            </a>
          </ng-template>
        </div>
      </div>
    </div>

  </div>

</div>
<ng-template #empty>
  <tr>
    <td colspan="8" style="text-align: center;">
      <span style="text-align: center; width: 100%;">
        Pas de rendez-vous !
      </span>
    </td>
  </tr>
</ng-template>

<app-pagination [pageRequest]="pageRequest" (content)="doctorAppointments = $event"></app-pagination>