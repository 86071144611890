import * as moment from "moment";
import { EGroupSanguin, EUserSocialCivility, User, EUserGender } from "../user.entity";
import { formatDate } from "@angular/common";

export interface IDoctorPatient {
  id: string;
  ref: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  mobilePhone1: string;
  mobilePhone2: string;
  birthDate: Date;
  avatar: string;
  appointments_amount: number;
  prescriptions_amount: number;
  consultations_amount: number;
  last_appointment?: Date;
  last_consultation?: Date;
  last_prescription?: Date;
  note: string;
  country: string;
  address: string;
  government: string;
  gender: EUserGender;
  socialCivility: EUserSocialCivility;
  sanguineGroup: EGroupSanguin;
  assuranceType: string;
  assuranceName: string;
  assuranceReference: string;
  accompanist: User | null;
  cnamCode: string;
  cnamAssociation: number;
  uniqueCode: string;
  createdAt: number;
  updatedAt: number
  apcis: Array<number>

}
export class DoctorPatient implements IDoctorPatient {

  id: string;
  ref: string;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  mobilePhone1: string;
  mobilePhone2: string;
  birthDate: Date;
  avatar: string;
  appointments_amount: number;
  prescriptions_amount: number;
  consultations_amount: number;
  last_appointment?: Date;
  last_consultation?: Date;
  last_prescription?: Date;
  public nbreJour: number = 0;
  country: string;
  address: string;
  government: string;
  gender: EUserGender;
  socialCivility: EUserSocialCivility;
  sanguineGroup!: EGroupSanguin;
  note: string;
  assuranceType!: string;
  assuranceName!: string;
  assuranceReference!: string;
  accompanist: User | null;
  uniqueCode: string;
  cnamCode: string;
  cnamAssociation: number;
  createdAt: number;
  updatedAt: number
  apcis: Array<number> = new Array();

  getFullName(inverted?: boolean): string {
    return inverted ? this.lastName + ' ' + this.firstName : this.firstName + ' ' + this.lastName;
  }

  constructor(other?: Partial<IDoctorPatient>) {
    this.id = other?.id || '';
    this.ref = other?.ref || '';
    this.firstName = other?.firstName || '';
    this.lastName = other?.lastName || '';
    this.country = other?.country || '';
    this.address = other?.address || '';
    this.government = other?.government || '';
    this.gender = other?.gender || EUserGender.HOMME;
    this.socialCivility = other?.socialCivility || EUserSocialCivility.SIGNLE;
    this.sanguineGroup = other?.sanguineGroup!;
    this.assuranceType = other?.assuranceType!;
    this.assuranceName = other?.assuranceName!;
    this.assuranceReference = other?.assuranceReference!;
    this.accompanist = other?.accompanist || null;
    this.role = other?.role || '';
    this.email = other?.email || '';
    this.mobilePhone1 = other?.mobilePhone1 || '';
    this.mobilePhone2 = other?.mobilePhone2 || '';
    this.birthDate = other?.birthDate || new Date();
    this.avatar = other?.avatar || '';
    this.appointments_amount = other?.appointments_amount || 0;
    this.consultations_amount = other?.consultations_amount || 0;
    this.prescriptions_amount = other?.prescriptions_amount || 0;
    this.last_appointment = other?.last_appointment;
    this.last_consultation = other?.last_consultation;
    this.last_prescription = other?.last_prescription;
    this.cnamCode = other?.cnamCode || '';
    this.cnamAssociation = other?.cnamAssociation || 0;
    this.uniqueCode = other?.uniqueCode || '';
    this.note = other?.note || "";
    this.apcis = other?.apcis || [];
    this.createdAt = other?.createdAt || 0;
    this.updatedAt = other?.updatedAt || 0
  }

  get old(): { years: number, months: number, days: number } {
    const birthDateObj = new Date(this.birthDate);
    const currentDate = new Date();

    let years = currentDate.getFullYear() - birthDateObj.getFullYear();
    let months = currentDate.getMonth() - birthDateObj.getMonth();
    let days = currentDate.getDate() - birthDateObj.getDate();

    // If birth month is after current month or birth day is after current day
    if (months < 0 || (months === 0 && days < 0)) {
      years--;
      months += 12;
    }

    // Adjust months and days if days are negative
    if (days < 0) {
      months--;
      const tempDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      days += tempDate.getDate();
    }

    return { years, months, days };
  }

  public static fromResponse(res: any): DoctorPatient {
    return new DoctorPatient({
      id: res.id,
      firstName: res.firstName,
      lastName: res.lastName,
      role: res.role,
      email: res.email,
      mobilePhone1: res.mobilePhone1,
      mobilePhone2: res.mobilePhone2,
      birthDate: new Date(res.birthDate),
      avatar: res.avatar,
      appointments_amount: Number(res.appointmentsAmount),
      prescriptions_amount: Number(res.prescriptionsAmount),
      consultations_amount: Number(res.consultationsAmount),
      last_appointment: res.lastAppointment ? new Date(res.lastAppointment) : undefined,
      last_consultation: res.lastConsultation ? new Date(res.lastConsultation) : undefined,
      last_prescription: res.lastPrescription ? new Date(res.lastPrescription) : undefined,
      ref: res.ref,
      country: res.country,
      address: res.address,
      government: res.government,
      gender: res.gender,
      socialCivility: res.socialCivility,
      sanguineGroup: res.sanguineGroup,
      assuranceType: res.assuranceType,
      assuranceName: res.assuranceName,
      assuranceReference: res.assuranceReference,
      accompanist: res.accompanist ? User.fromResponse(res.accompanist) : null,
      uniqueCode: res.uniqueCode,
      cnamCode: res.cnamCode,
      cnamAssociation: res.cnamAssociation,
      note: res.note,
      apcis: res.apcis,
      createdAt: (new Date(new Date(res.createdAt).getFullYear(), new Date(res.createdAt).getMonth(), new Date(res.createdAt).getDate())).getTime(),
      updatedAt: res.updatedAt
    });
  }
  get fullName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}