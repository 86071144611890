import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PatientAppointment } from 'src/app/entities/appointment.entity/patient-appointment.entity/patient-appointment';
import { User } from 'src/app/entities/user.entity';
import { Doctor } from 'src/app/entities/user.entity/doctor.entity';
import { AuthService } from 'src/app/services/auth.service/auth.service';
import { AppointmentPatientLoginComponent } from '../appointment-patient-login.component/appointment-patient-login.component';
import { PatientRequestedAppointment } from 'src/app/entities/appointment.entity';
import { PublicApisService } from 'src/app/services/public-apis.service/public-apis.service';
import { Country, LocalizationService } from 'src/app/services/localization.service/localization.service';

@Component({
  selector: 'app-appointment-booking-verification',
  templateUrl: './appointment-booking-verification.component.html',
  styleUrls: ['./appointment-booking-verification.component.css']
})
export class AppointmentBookingVerificationComponent implements OnInit {

  @Input() doctor!: Doctor;
  @Input() patientAppointment!: PatientAppointment;
  @Input() isSubmited: boolean = false;
  @Input() dir: string = "fr";
  @Output() complete: EventEmitter<PatientAppointment> = new EventEmitter();
  @ViewChild(AppointmentPatientLoginComponent, { read: AppointmentPatientLoginComponent, static: false }) appointmentPatientLoginComponent!: AppointmentPatientLoginComponent;
  appointmentRequestForm: FormGroup;
  countries: Array<Country> = new Array();
  get valid(): boolean {
    return this.patientAppointment.requested ? (this.appointmentRequestForm.valid) : (this.appointmentPatientLoginComponent?.valid)
  }
  get patient(): User | null {
    return this.authService.getCurrentUser();
  }
  defaultCountry: Country = {
    name: "Tunisie",
    dialCode: "+216",
    isoCode: "TN",
    flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
    timeOffset: 1
  };
  constructor(private authService: AuthService, private publicApiService: PublicApisService, private localizationService: LocalizationService, private formBuilder: FormBuilder) {
    this.appointmentRequestForm = this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ['', [Validators.required]],
      mobilePhone: ['', [Validators.required]],
      birthDate: [new Date(1970, 1, 1), [Validators.required]],
      country: [this.defaultCountry, [Validators.required]],
      email: [''],
    });
    this.countries = this.localizationService.getAllCountries()
  }

  ngOnInit(): void {
    this.patientAppointment.requested = true;
  }

  get f() {
    return this.appointmentRequestForm.controls;
  }

  onCountryChanged(country?: Country): void {
    this.dialCode = country ? country.dialCode : '';
  }
  dialCode: string = this.defaultCountry.dialCode;

  submit(): Promise<PatientAppointment> {
    this.isSubmited = true;
    if (this.patientAppointment.requested)
      return new Promise((resolve, reject) => {
        if (this.appointmentRequestForm.valid) {
          this.patientAppointment.patientFirstName = this.appointmentRequestForm.controls.firstName.value;
          this.patientAppointment.patientLastName = this.appointmentRequestForm.controls.lastName.value;
          this.patientAppointment.patientMobilePhone = this.dialCode + " " + this.appointmentRequestForm.controls.mobilePhone.value;
          this.patientAppointment.patientBirthDate = this.appointmentRequestForm.controls.birthDate.value;
          this.patientAppointment.patientEmail = this.appointmentRequestForm.controls.email.value;
          this.patientAppointment.patientCountry = this.appointmentRequestForm.controls.country.value.name;
          this.patientAppointment.requested = true;
          console.log(this.patientAppointment.patientEmail.length == 0)
          if (this.patientAppointment.patientCountry.toLowerCase() != 'tunisie' && this.patientAppointment.patientEmail.length == 0) {
            this.appointmentRequestForm.controls.email.setErrors({ required: true })
            return;
          }
          this.complete.emit(this.patientAppointment);
          this.publicApiService.requestAppointment(this.doctor?.id!, new PatientRequestedAppointment(this.patientAppointment)).subscribe(response => {
            response.doctor = this.doctor;
            response.requested = true;
            resolve(PatientAppointment.fromResponse(response))
          }, error => reject(error))
        } else reject()
      });
    else return this.appointmentPatientLoginComponent.onSubmit();
  }

}
