import { EUserRole, User } from "..";

export class Biologist extends User {
  constructor(other?: Partial<Biologist>) {
    super(other)
    this.role = EUserRole.BIOLOGIST;
  }

  static fromResponse(res: any): Biologist {
    return new Biologist({ ...User.fromResponse(res) });
  }
}
