import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthentificatedGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private activatedRoute: ActivatedRoute, private router: Router, private jwtHelper: JwtHelperService) { }
  canActivate(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.isLogedIn() || this.jwtHelper.isTokenExpired(this.authService.getCurrentUser()?.accessToken)) {
      this.authService.logOut()
      return this.router.navigate(['auth', 'login'], { queryParams: this.activatedRoute.queryParams });
    } else return true;
  }

  canActivateChild(
    activatedRouteSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.authService.isLogedIn() || this.jwtHelper.isTokenExpired(this.authService.getCurrentUser()?.accessToken)) {
      this.authService.logOut()
      return this.router.navigate(['auth', 'login'], { queryParams: this.activatedRoute.queryParams });
    } else return true;
  }

}
