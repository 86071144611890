<app-data-table [pageRequest]="pageRequest" [perPage]="10" [onRefresh]="onRefresh" [page]="{pageOffset:0,pageSize:10}">
  <table class="table table-hover table-center">
    <thead>
      <tr>
        <th *dataTableHead="{index:'0',sortbal: true, label: 'ref', sortKey: 'ref'};let order = sortOrder">
          Dossier N° <i class="fa fa-sort" *ngIf="order == 'none'"></i>
          <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
        </th>
        <th *dataTableHead="{index:'0',sortbal: true, label: 'fullName', sortKey: 'fullName'};let order = sortOrder" class="text-center">
          Patient
          <!-- <i *ngIf="order == 'asc'">Up</i>
                          <i *ngIf="order == 'desc'">Down</i> -->
          <i class="fa fa-sort" *ngIf="order == 'none'"></i>
          <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
        </th>

        <th *dataTableHead="{index:'2',sortbal: true, label: 'mobilePhone1', sortKey: 'mobilePhone1'};let order = sortOrder">
          Télèphone
          <i class="fa fa-sort" *ngIf="order == 'none'"></i>
          <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
        </th>
        <th *dataTableHead="{index:'3',sortbal: true, label: 'birthDate', sortKey: 'birthDate'};let order = sortOrder">
          Date Naissance
          <i class="fa fa-sort" *ngIf="order == 'none'"></i>
          <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
        </th>
        <th *dataTableHead="{index:'3',sortbal: true, label: 'government', sortKey: 'government'};let order = sortOrder">Ville
          <i class="fa fa-sort" *ngIf="order == 'none'"></i>
          <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
        </th>
        <th>Accompagnant</th>
        <th class="text-center">RDV</th>
        <th *ngIf="hasActions" class="text-center">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *dataTableItem="let doctorPatient = item;let i=index">
        <td class="ref"><a>{{doctorPatient.ref || '..'}}</a></td>
        <td>
          <h2 class="table-avatar">
            <a *ngIf="actions.badge " class="btn btn-sm" style="margin-right: 5px;" [ngStyle]="{'color':doctorPatient.createdAt==date?'#ffbc34':'#badfdc'}">
              <i class="feather-star" style="font-weight: bold; font-size: 15px;"></i>
            </a>
            <a (click)="onUpdateRequest.emit(doctorPatient)" class="avatar avatar-sm me-2">
              <img class="avatar-img rounded-circle" [src]="doctorPatient | avatarSrc" [alt]="doctorPatient.getFullName()" image />
            </a>
            <a (click)="onUpdateRequest.emit(doctorPatient)" style="font-family: 'Gotham Rounded Bold'; font-weight: Bold;">
              {{ doctorPatient.getFullName() }}
            </a>
          </h2>
        </td>

        <td>
          {{ doctorPatient.mobilePhone1 || "---" }}
        </td>
        <td style="font-weight: 600; color: #000;">
          {{ doctorPatient.birthDate? (doctorPatient.birthDate | date: "dd/MM/yyyy"):'---' }}
        </td>
        <td>
          {{(doctorPatient.country || '').length>0?doctorPatient.country + (doctorPatient.government?' /':''):''}} {{
          doctorPatient.government }}
        </td>
        <td>
          <a>{{ doctorPatient.accompanist?doctorPatient.accompanist.getFullName():'---' }}</a>
        </td>
        <td class="text-center">
          {{ doctorPatient.last_appointment?(doctorPatient.last_appointment | date: "dd/MM/yyyy"):'---' }}
        </td>
        <td *ngIf="hasActions">
          <div class="table-action">
            <a *ngIf="actions.edit" title="Modifier Profil" (click)="onUpdateRequest.emit(doctorPatient)" class="btn btn-sm bg-info-light">
              <i class="feather-edit"> </i>
            </a>
            <a *ngIf="actions.assignAppointment" title="Ajouter Rdv" (click)="onAssignAppointmentRequest.emit(doctorPatient)" routerLinkActive="router-link-active"
              class="btn btn-sm bg-success-light">
              <i class="feather-plus"></i>
            </a>
            <a *ngIf="actions.delete" title="Supprimer Patient" (click)="onDelete(doctorPatient)" routerLinkActive="router-link-active" class="btn btn-sm bg-danger-light">
              <i class="feather-trash"></i>
            </a>
            <app-drop-down-menu *ngIf="(actions.antecentendsView || actions.prescriptionsView || actions.consultationsView)&& doctor.packPro">
              <a class="btn btn-sm bg-success-light" #trigger trigger>
                <i class="feather feather-menu"></i>
              </a>
              <ul class="menu" #content content>
                <li *ngIf="actions.antecentendsView">
                  <a (click)="onAntecedentsRequest.emit(doctorPatient)" routerLinkActive="router-link-active"><i class="feather feather-book-open"></i> <span>Ajout
                      Antecedent</span></a>
                </li>

                <li *ngIf="actions.prescriptionsView">
                  <a (click)="onPrescriptionsRequest.emit(doctorPatient)"><i class="feather feather-user-plus"></i> <span>Patient prescriptions</span></a>
                </li>
                <li *ngIf="actions.consultationsView">
                  <a (click)="onConsultationsRequest.emit(doctorPatient)"><i class="feather feather-activity"></i> <span>Patient consultations</span></a>
                </li>
              </ul>
            </app-drop-down-menu>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</app-data-table>
