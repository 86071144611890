import { Component, DoCheck, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ToggleSwitchComponent)
  }]
})
export class ToggleSwitchComponent implements OnInit, ControlValueAccessor, DoCheck {
  @Output() switched: EventEmitter<boolean> = new EventEmitter();
  @Input() required: boolean = false;

  @Input() yes: {
    color?: string;
    label?: string;
  } = { color: 'green' }
  @Input() no: {
    color?: string;
    label?: string;
  } = { color: 'red' }
  private _checked!: boolean;
  private _disabled!: boolean;
  errors?: string[];
  private onChange!: (v: boolean) => void;
  constructor() { }



  @Input() set checked(v: boolean) {
    this._checked = v !== false;
  }

  get checked() {
    return this._checked;
  }

  @Input() set disabled(v: boolean) {
    this._disabled = v !== false;
  }

  get disabled() {
    return this._disabled;
  }
  ngOnInit(): void {
  }


  writeValue(obj: any): void {
    this.checked = !!obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    //this.focus()
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  ngDoCheck(): void {
    if (this.checked && this.required) this.errors = ['required'];
    else this.errors = []
  }

  onClick($event: Event): void {
    this.checked = !this.checked;
    this.onChange && this.onChange(this.checked);
    $event.stopPropagation();
    $event.preventDefault();
    this.switched.emit(this.checked)
  }
}
