import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import Countries from 'src/app/common/data/countries';
import ArabicCountries from 'src/app/common/data/countries-arab';
import { TN_CITIES } from 'src/app/common/data/cities';
import Villes from "src/app/common/data/villes";

export class Country {
  name: string;
  dialCode: string;
  isoCode: string;
  flagUrl: string;
  timeOffset: number;

  constructor(other?: Partial<Country>) {
    this.name = other?.name || '';
    this.dialCode = other?.dialCode || '';
    this.isoCode = other?.isoCode || '';
    this.flagUrl = other?.flagUrl || '';
    this.timeOffset = other?.timeOffset || 0;
  }

}

export type Language = "fr" | "ar";

export const LANGUAGE_LOCAL_STORAGE_KEY = 'language';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  cities: Array<{ ville: string, city: string, id: string }> = TN_CITIES;
  villes: Array<{ nameArabe: string, name: string }> = Villes;
  _language: BehaviorSubject<Language>;
  constructor() {
    this._language = new BehaviorSubject((localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY) || 'fr') as Language);
  }

  setLanguage(language: Language) {
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, language);
    this._language.next(language);
  }

  getLanguage(): Observable<Language> {
    return this._language.asObservable();
  }

  getAllCountries(language?: Language): Array<Country> {
    return (language == 'ar' ? ArabicCountries : Countries).map(c => new Country(c));
  }

  getCountryByISOCode(isoCode: string, language?: Language): Country | null {
    return this.getAllCountries(language).find(m => m.isoCode == isoCode.toUpperCase()) || null;
  }

  getCountryByDialCode(dialCode: string, language?: Language): Country | null {
    return this.getAllCountries(language).find(m => m.dialCode == dialCode) || null;
  }

  getCitiesByVille(ville: string): Array<string> {
    return this.cities.filter(e => e.ville.toUpperCase().trim() === ville.toUpperCase().trim()).map(e => e.city);
  }

  getVilles(): Array<{ nameArabe: string, name: string }> {
    return this.villes;
  }


  getCitiesByGovernment(government: string): Array<{ name: string, id: number }> {
    return TN_CITIES.filter(c => c.ville.toLowerCase().trim() === government.trim().toLowerCase()).map(e => ({ name: e.city, id: Number(e.id || 0) }));
  }

}
