import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PageRequest, PaginationPage } from "src/app/common/modules/data-table.module";
import { User } from "src/app/entities/user.entity";
import { AuthService } from "src/app/services/auth.service/auth.service";
@Component({
  selector: "app-dashborad-paiement-table-sortbal",
  templateUrl: "./dashborad-paiement-table-sortbal.component.html",
  styleUrls: ["./dashborad-paiement-table-sortbal.component.css"],
})
export class DashboradPaiementTableSortbalComponent implements OnInit {
  @Input() headers: Array<{ label: string; sorted?: { key: string; order: number } }> = [
    { label: "Dossier N° " },
    { label: "Date" },
    { label: "Patient" },
    { label: "Consultation" },
    { label: "Actes" },
    { label: "Total" },
    { label: "Payé" },
    { label: "Reste" },
  ];

  @Input() currentPage: number = 0;
  @Input() perPage: number = 10;
  @Input() pageRequest!: (pageRequest: PageRequest) => Promise<PaginationPage<any>>;
  @Output() onAssignAppointmentRequest: EventEmitter<any> = new EventEmitter();
  @Output() onAntecedentsRequest: EventEmitter<any> = new EventEmitter();
  @Output() onUpdateRequest: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteRequest: EventEmitter<any> = new EventEmitter();
  @Output() onPrescriptionsRequest: EventEmitter<any> = new EventEmitter();
  @Output() onConsultationsRequest: EventEmitter<any> = new EventEmitter();

  @Output() onRefresh: EventEmitter<PageRequest> = new EventEmitter();

  @Input() actions: Partial<{
    edit: boolean;
    delete: boolean;
    assignAppointment: boolean;
    prescriptionsView: boolean;
    consultationsView: boolean;
    antecentendsView: boolean;
    payementsView: boolean;
    badge: boolean;
  }> = {};
  @Input() emptyMessage?: string;

  date: number = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime();
  paiements: Array<any> = new Array();

  sortedheadIndex: number = -1;

  get hasActions(): boolean {
    return Object.values(this.actions).some((e) => e == true);
  }
  constructor(private authService: AuthService) {}

  get currentUser(): User {
    return this.authService.getCurrentUser()!;
  }

  ngOnInit(): void {
    this.currentPage = 0;
  }

  onSortRequested(headIndex: number, key: string): void {
    if (headIndex == this.sortedheadIndex) {
      this.sortedheadIndex = -1;
      this.onRefresh.emit();
    } else {
      this.sortedheadIndex = headIndex;
    }
  }

  onDelete(index: number): void {}
}
