import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service/api.service';
import { environment } from 'src/environments/environment';
//import { environment } from 'src/environments/environment';

export interface KonnectRequestPayload {
  receiverWalletId: string,
  token: "TND" | "EUR" | "USD",
  amount: number,
  type: "immediate" | "partial",
  description: string,
  acceptedPaymentMethods: Array<"wallet" | "bank_card" | "e-DINAR" | "flouci">,
  lifespan: number,
  checkoutForm: boolean,
  addPaymentFeesToAmount: boolean,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
  orderId: string,
  webhook: string,
  silentWebhook: boolean,
  // "successUrl": string,
  //"failUrl": string,
  theme: "dark" | "light",
}


export interface KonnectResponsePayload {
  "payUrl": string,
  "paymentRef": string
}

@Injectable({
  providedIn: 'root'
})
export class KonnectPaymentService extends ApiService {
  initTransaction(request: KonnectRequestPayload): Observable<KonnectResponsePayload> {
    request.amount *= 1000;
    request.receiverWalletId = environment.KONNECT_RECEIVER_WALLET_ID;
    return this.http.post<any>(`${environment.KONNECT_API_URL}/payments/init-payment`, request, { headers: { 'Content-Type': 'application/json', 'x-api-key': `${environment.KONNECT_API_KEY}` } });
  }
}
