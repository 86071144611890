<app-data-table [pageRequest]="pageRequest" [perPage]="10" [page]="{pageOffset:0,pageSize:10}">
  <div class="table-responsive">
    <table class="table table-hover">
      <!-- <thead>
        <tr>
            <th>Date</th>
            <th>Nom</th>
            <th>Télèphone</th>
            <th>Description</th>
            <th>Type</th>
            <th>Statut</th>
            <th *ngIf="actions.payementsView">M. Total</th>
            <th *ngIf="actions.payementsView">M. Payé</th>
            <th *ngIf="actions.payementsView">Reste</th>
            <th *ngIf="actions.payementsView">Paiement</th>
            <th>Actions</th>
        </tr>
    </thead> -->
      <thead>
        <tr>
          <th *dataTableHead="{index:'0',sortbal: true, label: 'date', sortKey: 'date'};let order = sortOrder">
            Date <i class="fa fa-sort" *ngIf="order == 'none'"></i>
            <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
          </th>
          <th
            *dataTableHead="{index:'0',sortbal: true, label: 'patient.firstName', sortKey: 'patient.firstName'};let order = sortOrder"
            class="text-center"
          >
            Nom
            <!-- <i *ngIf="order == 'asc'">Up</i>
                          <i *ngIf="order == 'desc'">Down</i> -->
            <i class="fa fa-sort" *ngIf="order == 'none'"></i>
            <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
          </th>

          <th
            *dataTableHead="{index:'2',sortbal: true, label: 'patient.mobilePhone1', sortKey: 'patient.mobilePhone1'};let order = sortOrder"
          >
            Télèphone
            <i class="fa fa-sort" *ngIf="order == 'none'"></i>
            <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
          </th>
          <th *dataTableHead="{index:'3',sortbal: true, label: 'motif', sortKey: 'motif'};let order = sortOrder">
            Description <i class="fa fa-sort" *ngIf="order == 'none'"></i>
            <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
          </th>
          <th *dataTableHead="{index:'3',sortbal: true, label: 'location', sortKey: 'location'};let order = sortOrder">
            Type <i class="fa fa-sort" *ngIf="order == 'none'"></i>
            <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
          </th>

          <th *dataTableHead="{index:'3',sortbal: true, label: 'status', sortKey: 'status'};let order = sortOrder">
            Statut
            <i class="fa fa-sort" *ngIf="order == 'none'"></i>
            <i class="sorter feather" [ngClass]="{'feather-chevron-up':order == 'asc','feather-chevron-down':order == 'desc'}"></i>
          </th>
          <th *ngIf="actions.payementsView">M. Total</th>
          <th *ngIf="actions.payementsView">M. Payé</th>
          <th *ngIf="actions.payementsView">Reste</th>
          <th *ngIf="actions.payementsView">Paiement</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *dataTableItem="let appointment = item;let i=index"
          [ngStyle]="{'background-color':appointment.status==0?'#FEF9E7':appointment.status==2?'#ffe0db':'#fff'}"
        >
          <td>
            {{ appointment.date | date:'dd/MM/yyyy'}}
            <span class="d-block apt-time" [ngStyle]="{'color':appointment.startTime!==0?'#48b9b2':'red'}"
              >{{appointment.startTime!==0?(appointment.startTime |time) :'--:--'}}
            </span>
          </td>
          <td style="text-align: flex-start; width: 20%;">
            <a (click)="onPatientProfileRequest.emit(appointment)" class="avatar avatar-sm" style="float: left;">
              <img
                class="avatar-img rounded-circle"
                [src]="appointment.patient | avatarSrc"
                [alt]="appointment.patient.getFullName()"
                image
              />
            </a>
            <div style="float: right; margin: 0px; width: 80%;">
              <div>
                <span style="font-family: 'Gotham Rounded Bold'; font-weight: Bold;"
                  >{{appointment.patient.firstName!==""?appointment.patient.getFullName():appointment.patientFirstName + ' '+
                  appointment.patientLastName}}</span
                >
              </div>
              <div>
                <span style="font-size: 12px;"
                  >{{(appointment.patient.birthDate| date:"dd/MM/yyyy")!=='01/01/1970'?(appointment.patient.birthDate
                  |date:"dd/MM/yyyy"):(appointment.patientBirthDate |date:"dd/MM/yyyy")}}</span
                >
              </div>
            </div>
          </td>
          <td>{{ appointment.patient.mobilePhone1 !==""?appointment.patient.mobilePhone1:appointment.patientMobilePhone || '---' }}</td>
          <td>{{ appointment.motif || '---' }}</td>
          <td>
            {{appointment.location? "A Distance": "Présentielle"}}
          </td>
          <td>
            <a *ngIf="appointment.status == 0" href="javascript:void(0);" class="btn btn-sm bg-warning-light">
              <i class="feather-clock"></i>
              En Attente
            </a>
            <a *ngIf="appointment.status == 1" href="javascript:void(0);" class="btn btn-sm bg-success-light">
              <i class="feather-check-circle"></i>
              Confirmé
            </a>
            <a *ngIf="appointment.status == 2" href="javascript:void(0);" class="btn btn-sm bg-danger-light">
              <i class="feather-x-circle"></i>
              Annulé
            </a>
            <a *ngIf="appointment.status == 3" href="javascript:void(0);" class="btn btn-sm bg-info-light">
              <i class="feather-check"></i>
              Cloturé
            </a>
          </td>
          <td *ngIf="actions.payementsView">
            {{appointment.regularAmount}}
          </td>
          <td *ngIf="actions.payementsView">
            {{appointment.paidAmount}}
          </td>
          <td *ngIf="actions.payementsView">
            {{appointment.regularAmount-appointment.paidAmount}}
          </td>
          <td *ngIf="actions.payementsView">
            <span
              [ngStyle]="{
                        'color':appointment.isPaid?'#41B883':'#e63c3c',
                        'font-family':'Gotham Rounded Bold',
                        'font-size':'16px'
                    }"
              >{{appointment.isPaid?'Payé':'Non payé'}}</span
            >
          </td>
          <td class="text-start">
            <div class="table-action">
              <ng-container>
                <a
                  *ngIf="(((appointment.status == 1 || appointment.patient.id!==null)  && !(appointment.hasConsultation || appointment.hasPrescription))) && actions.edit"
                  title="Mise à Jour"
                  class="btn btn-sm bg-warning-light"
                  (click)="onEditRequest.emit(appointment)"
                >
                  <i class="feather-edit"></i>
                </a>
                <a
                  *ngIf="appointment.status == 1 && actions.payementsView"
                  (click)="onBillingRequest.emit(appointment)"
                  title="Paiement"
                  class="btn btn-sm bg-primary-light"
                >
                  <i class="feather-credit-card"></i>
                </a>
                <a
                  *ngIf="((appointment.status == 0 || appointment.status == 1  )) && !(appointment.hasConsultation || appointment.hasPrescription) && actions.reject"
                  title="Annuler"
                  class="btn btn-sm bg-danger-light"
                  (click)="onReject.emit(appointment)"
                >
                  <i class="feather-x"></i>
                </a>
                <a
                  *ngIf="appointment.status == 0 && actions.accept && appointment.patient.id!==''"
                  title="Confirmé"
                  (click)="onAccept.emit(appointment)"
                  class="btn btn-sm bg-success-light"
                >
                  <i class="feather-check-circle"></i>
                </a>
                <a
                  *ngIf="appointment.status == 0 && actions.affect && appointment.patient.id==''"
                  title="Assigner"
                  (click)="onAffect.emit(appointment)"
                  class="btn btn-sm bg-primary-light"
                >
                  <i class="feather-user"></i>
                </a>
                <a
                  *ngIf="canOpenTeleconsultation(appointment)"
                  [title]="appointment.isPaid?'Commencer la teleconsultation':'En attend de paiement client'"
                  [ngClass]="{disabled:!appointment.isPaid}"
                  (click)="onViewTeleConsultationRequest.emit(appointment)"
                  class="btn btn-sm bg-success-light"
                >
                  <i class="feather-play-circle"></i>
                </a>
                <app-drop-down-menu
                  *ngIf="(actions.consultations || actions.prescription || actions.attachmentsView) && appointment.status === 1"
                >
                  <a title="Ajout" routerLinkActive="router-link-active" class="btn btn-sm bg-success-light" #trigger trigger>
                    <i class="feather feather-menu"></i>
                  </a>
                  <ul class="menu" #content content>
                    <li *ngIf="actions.consultations">
                      <a
                        (click)="appointment.hasConsultation?onViewConsultationRequest.emit(appointment):onAssignConsultationRequest.emit(appointment)"
                        routerLinkActive="router-link-active"
                      >
                        <i class="feather feather-book-open"></i>
                        <span>
                          {{appointment.hasConsultation? 'List consultations': 'Ajout consultation'}}
                        </span>
                      </a>
                    </li>
                    <li *ngIf="actions.prescription">
                      <a
                        (click)="appointment.hasPrescription?onViewPrescriptionRequest.emit(appointment):onAssignPrescriptionRequest.emit(appointment)"
                      >
                        <i class="{{appointment.hasPrescription?'feather-eye':'feather-clipboard'}}"></i
                        ><span>
                          {{appointment.hasPrescription?'Prescriptions':'Ajout prescription'}}
                        </span>
                      </a>
                    </li>
                    <li *ngIf="actions.attachmentsView">
                      <a title="Attacher un document medical" (click)="onViewAttachmentsRequest.emit(appointment)">
                        <i class="feather-file-plus"></i><span>Ajout document</span>
                      </a>
                    </li>
                    <li *ngIf="actions.attestation">
                      <a title="Ajout Attestation" (click)="onViewAttestationsRequest.emit(appointment)">
                        <i class="feather-plus-circle"></i><span>Ajout Attestation</span>
                      </a>
                    </li>
                  </ul>
                </app-drop-down-menu>
              </ng-container>
              <ng-template #oldAppointment>
                <a title="View details" class="btn btn-sm bg-warning-light">
                  <i class="feather-eye"></i>
                </a>
              </ng-template>
            </div>
          </td>
        </tr>
        <ng-template #empty>
          <tr>
            <td colspan="8" style="text-align: center;">
              <span style="text-align: center; width: 100%;">
                {{emptyMessage || ' Pas de rendez-vous !'}}
              </span>
            </td>
          </tr>
        </ng-template>
      </tbody>
    </table>
  </div>
</app-data-table>
