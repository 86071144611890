import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { LiteralDays } from "src/app/common/pipes/french-date.pipe/french-date.pipe";
import { DalyTimeSlot, EDalyTimeSlotLocation } from "src/app/entities/daly-time-slot.entity/daly-time-slot";
import { TimeBoarding } from "src/app/entities/time-boarding.entity/time-boarding";
import { Doctor } from "src/app/entities/user.entity/doctor.entity";
import { PublicApisService } from "src/app/services/public-apis.service/public-apis.service";
import { MILLISECONDS_IN_DAY } from "src/app/utils/date.util";

@Component({
  selector: "app-doctor-days-calander",
  templateUrl: "./doctor-days-calander.component.html",
  styleUrls: ["./doctor-days-calander.component.css"],
})
export class DoctorDaysCalanderComponent implements OnInit {
  DAYS_IN_WEEK = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

  MONTHS = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

  @Input() month: Date = new Date();
  @Input() location: EDalyTimeSlotLocation = EDalyTimeSlotLocation.ATSITE;
  @Input() doctor: Doctor = new Doctor();
  @Input() day?: Date;
  @Output() dayChange: EventEmitter<Date> = new EventEmitter();
  @Output() dayTimeSlotsChange: EventEmitter<Array<DalyTimeSlot>> = new EventEmitter();
  @Input() dir: string = "fr";
  @Input() mode: "DATE_TIME_SLOT" | "DATE" = "DATE_TIME_SLOT";
  get date(): Date {
    return this._date;
  }
  get currentDate(): Date {
    return new Date();
  }

  get width(): number {
    return window.screen.width;
  }

  private _date: Date = new Date();

  timeBoarding!: TimeBoarding;

  selectedTimeSlot!: DalyTimeSlot;

  calender: Array<Array<{ date: Date; isAvailable: boolean }>> = new Array();

  @Output() timeSlotChange: EventEmitter<DalyTimeSlot> = new EventEmitter();
  constructor(private publicApiService: PublicApisService, private elementRef: ElementRef) { }

  ngOnInit(): void {
    this.publicApiService.getDoctorActualTimeBoarding(this.doctor.id).subscribe(
      (dalyTimeSlots) => {
        this.timeBoarding = new TimeBoarding({ timeSlots: Array.from(dalyTimeSlots, (e) => DalyTimeSlot.fromResponse(e)) });
        this.fillCalander();
      },
      (error) => {
        console.error(error);
      }
    );

    if (this.dir == "fr") {
      this.MONTHS = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];
      this.DAYS_IN_WEEK = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

    } else {
      (this.MONTHS = ["جانفي", "فيفري", "مارس", "أفريل", "ماي", "جوان", "جويلية", "أوت", "سبتمبر", "اكتوبر", "نوفمبر", "ديسمبر"]),
        (this.DAYS_IN_WEEK = ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", 'الخميس', "الجمعة", "السبت"]
        );
    }
  }

  currentMonth: Date = new Date();

  fillCalander(): Array<Array<{ date: Date; isAvailable: boolean }>> {
    let weeks = new Array<Array<{ date: Date; isAvailable: boolean }>>();
    let weekDays = new Array<{ date: Date; isAvailable: boolean }>();
    let currentMonth = this.month.getMonth();
    let monthStartDate: Date = new Date(this._date.getFullYear(), currentMonth, 1);

    for (let i = 1; i < monthStartDate.getDay(); i++) {
      weekDays = [
        {
          date: new Date(monthStartDate.getTime() - i * MILLISECONDS_IN_DAY),
          isAvailable: this.isBussinessWokingDay(new Date(monthStartDate.getTime() - i * MILLISECONDS_IN_DAY).getDate()),
        },
        ...weekDays,
      ];
    }

    for (let i = 1; i <= 8 - monthStartDate.getDay(); i++) {
      weekDays.push({
        date: new Date(monthStartDate.getFullYear(), monthStartDate.getMonth(), i),
        isAvailable: this.isBussinessWokingDay(new Date(monthStartDate.getFullYear(), monthStartDate.getMonth(), i).getDate()),
      });
    }

    let currentCalanderDateCounter = weekDays[weekDays.length - 1].date;
    weeks.push(weekDays);

    while (new Date(currentCalanderDateCounter.getTime() + MILLISECONDS_IN_DAY).getMonth() <= monthStartDate.getMonth()) {
      weekDays = [];
      for (let i = 1; i <= 7; i++) {
        currentCalanderDateCounter = new Date(currentCalanderDateCounter.getTime() + MILLISECONDS_IN_DAY);
        weekDays.push({
          date: currentCalanderDateCounter,
          isAvailable: this.isBussinessWokingDay(currentCalanderDateCounter.getDate()),
        });
      }
      weeks.push(weekDays);
    }
    this.calender = weeks;
    return this.calender;
  }

  onDaySelected(date: Date): void {
    this.day = date;
    this.dayChange.emit(date);
    this.dayTimeSlotsChange.emit(this.dayTimeSlots);
  }

  isSelected(date: Date): boolean {
    return date?.getTime() == this.day?.getTime();
  }

  onSelectedTimeSlot(selectedTimeSlot: DalyTimeSlot): void {
    this.selectedTimeSlot = selectedTimeSlot;
    this.timeSlotChange.emit(selectedTimeSlot);
  }

  get dayTimeSlots(): Array<DalyTimeSlot> {
    return this.timeBoarding.timeSlots.filter((e) => e.dayIndex == this.day?.getDay()).sort((a, b) => a.startTime - b.startTime);
  }

  __moveToNext(): void {
    this.month = new Date(
      this.month.getMonth() + 1 == 12 ? this.month.getFullYear() + 1 : this.month.getFullYear(),
      this.month.getMonth() + 1 == 12 ? 0 : this.month.getMonth() + 1,
      1
    );
    this.fillCalander();
  }

  __moveToBack(): void {
    this.month = new Date(
      this.month.getMonth() - 1 < 0 ? this.month.getFullYear() - 1 : this.month.getFullYear(),
      this.month.getMonth() - 1 < 0 ? 11 : this.month.getMonth() - 1,
      1
    );
    this.fillCalander();
  }

  __getLiteralDay(i: number): string {
    return LiteralDays[i];
  }

  getDayTimeSlots(dayIndex: number) {
    return this.timeBoarding.timeSlots.filter((e) => e.dayIndex == dayIndex).sort((a, b) => a.startTime - b.startTime);
  }

  isBussinessWokingDay(dayIndex: number) {
    return this.getDayTimeSlots(dayIndex).length > 0;
  }
}
