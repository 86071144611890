import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PageRequest } from "src/app/common/modules/data-table.module";
import { ApiResponse } from "src/app/core/entities/api-response.entity/api-response";
import { ApiService } from "src/app/core/services/api.service/api.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DoctorDashboardService extends ApiService {
  dashboard(): Observable<any> {
    return this.http
      .get<ApiResponse>(`${environment.API_URL}/doctor/dashboard/consultations`, { responseType: "json" })
      .pipe(this.responsePipe);
  }

  totalAppointments(): Observable<any> {
    return this.http
      .get<ApiResponse>(`${environment.API_URL}/doctor/dashboards/appointments/total`, { responseType: "json" })
      .pipe(this.responsePipe);
  }

  totalPrescriptions(): Observable<any> {
    return this.http
      .get<ApiResponse>(`${environment.API_URL}/doctor/dashboards/prescriptions/total`, { responseType: "json" })
      .pipe(this.responsePipe);
  }

  totalPatients(): Observable<any> {
    return this.http
      .get<ApiResponse>(`${environment.API_URL}/doctor/dashboards/patients/total`, { responseType: "json" })
      .pipe(this.responsePipe);
  }

  sumPayments(): Observable<any> {
    return this.http
      .get<ApiResponse>(`${environment.API_URL}/doctor/dashboards/payments/sum`, { responseType: "json" })
      .pipe(this.responsePipe);
  }
  staticPatient(startDate: number, endDate: number): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/dashboards/patients/new?startDate=${startDate}&endDate=${endDate}`).pipe(this.responsePipe);
  }
  // projection patient
  projectionPatients(startDate: number, endDate: number): Observable<any> {
    return this.http.get<ApiResponse>(
      `${environment.API_URL}/doctor/dashboards/patients/forecast?startDate=${startDate}&endDate=${endDate}`
    ).pipe(this.responsePipe);
  }
  // projection rdv
  projectionRDVs(startDate: number, endDate: number): Observable<any> {
    return this.http.get<ApiResponse>(
      `${environment.API_URL}/doctor/dashboards/appointments/forecast?startDate=${startDate}&endDate=${endDate}`
    ).pipe(this.responsePipe);
  }

  staticRdvs(startDate: number, endDate: number): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/dashboards/appointments/new?startDate=${startDate}&endDate=${endDate}`).pipe(this.responsePipe);
  }
  staticConsultations(startDate: any, endDate: any): Observable<Array<{ id: string, consultations: number, color: string, label: string }>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/dashboards/consultations/motifs?from=${startDate}&to=${endDate}`).pipe(this.responsePipe);
  }
  paiementStatic(pageRequest:PageRequest,startDate:number,endDate:number):Observable<any>{

      let params = new HttpParams();
      if (pageRequest!.pageOffset) params = params.append("page", pageRequest!.pageOffset);
      if (pageRequest!.pageSize) params = params.append("size", pageRequest!.pageSize);
      if (pageRequest!.sort?.order) {
        // const sortParam = `${pageRequest.sort.key},${pageRequest.sort.order}`;
        let order = 0;
        if (pageRequest.sort.order == "desc") {
          order = 1;
          params = params.append("order", order);
          params = params.append("sortBy", pageRequest.sort.key);

        } else if (pageRequest.sort.order == "asc"){
          order = 0;
          params = params.append("order", order);
          params = params.append("sortBy", pageRequest.sort.key);

        }
      }
      return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/invoices/search?before=${startDate}&after=${endDate}`, { params: params }).pipe(this.responsePipe);

    
  }
  searchPaiement( query: string, pageRequest: PageRequest,startDate:number,endDate:number) {
    let params = new HttpParams();
  
    if (query.length != 0) params = params.set("query", query);
    if (pageRequest!.pageOffset) params = params.append("page", pageRequest!.pageOffset);
    if (pageRequest!.pageSize) params = params.append("size", pageRequest!.pageSize);
    return      this.http.get<ApiResponse>(`${environment.API_URL}/doctor/invoices/search?before=${startDate}&after=${endDate}`, { params: params }).pipe(this.responsePipe);

  }
}
