export default [
    {
        name: "تونس",
        dialCode: "+216",
        isoCode: "TN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
        timeOffset: 1
    },
    {
        name: "الجزائر",
        dialCode: "+213",
        isoCode: "DZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
        timeOffset: 1
    },
    {
        name: "ليبيا",
        dialCode: "+218",
        isoCode: "LY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
        timeOffset: 2
    },
    {
        name: "موريتانيا",
        dialCode: "+222",
        isoCode: "MR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
        timeOffset: 4.5
    },
    {
        name: "مالي",
        dialCode: "+223",
        isoCode: "ML",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+225",
        isoCode: "CI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
        timeOffset: 4.5,
        name: "ساحل العاج"
    },
    {
        name: "أفغانستان",
        dialCode: "+93",
        isoCode: "AF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+358",
        isoCode: "AX",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ax.svg",
        timeOffset: 4.5,
        name: "جزر أولاند"
    },
    {
        name: "ألبانيا",
        dialCode: "+355",
        isoCode: "AL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
        timeOffset: 2
    },
    {
        name: "أميركا ساموا",
        dialCode: "+1684",
        isoCode: "AS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/as.svg",
        timeOffset: 4.5
    },
    {
        name: "أندورا",
        dialCode: "+376",
        isoCode: "AD",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
        timeOffset: 1
    },
    {
        name: "أنغولا",
        dialCode: "+244",
        isoCode: "AO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
        timeOffset: 1
    },
    {
        name: "أنتيغوا وبربودا",
        dialCode: "+1264",
        isoCode: "AI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ai.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+672",
        isoCode: "AQ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/aq.svg",
        timeOffset: 4.5,
        name: "أنتاركتيكا"
    },
    {
        dialCode: "+1268",
        isoCode: "AG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
        timeOffset: -4,
        name: "أنتيغوا وبربودا"
    },
    {
        name: "الأرجنتين",
        dialCode: "+54",
        isoCode: "AR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
        timeOffset: -3
    },
    {
        name: "أرمينيا",
        dialCode: "+374",
        isoCode: "AM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/am.svg",
        timeOffset: 4
    },
    {
        name: "أروبا",
        dialCode: "+297",
        isoCode: "AW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/aw.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+247",
        isoCode: "AC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ac.svg",
        timeOffset: 4.5,
        name: "جزيرة أسينشين"
    },
    {
        name: "أستراليا",
        dialCode: "+61",
        isoCode: "AU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
        timeOffset: 10
    },
    {
        name: "النمسا",
        dialCode: "+43",
        isoCode: "AT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
        timeOffset: 1
    },
    {
        name: "أذربيجان",
        dialCode: "+994",
        isoCode: "AZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/az.svg",
        timeOffset: 4
    },
    {
        name: "الباهاماس",
        dialCode: "+1242",
        isoCode: "BS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
        timeOffset: -5
    },
    {
        name: "البحرين",
        dialCode: "+973",
        isoCode: "BH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bh.svg",
        timeOffset: 3
    },
    {
        name: "بنغلاديش",
        dialCode: "+880",
        isoCode: "BD",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bd.svg",
        timeOffset: 6
    },
    {
        name: "باربادوس",
        dialCode: "+1246",
        isoCode: "BB",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bb.svg",
        timeOffset: -4
    },
    {
        name: "بيلاروس",
        dialCode: "+375",
        isoCode: "BY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/by.svg",
        timeOffset: 3
    },
    {
        name: "بلجيكا",
        dialCode: "+32",
        isoCode: "BE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
        timeOffset: 1
    },
    {
        name: "بليز",
        dialCode: "+501",
        isoCode: "BZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
        timeOffset: -6
    },
    {
        name: "بنين",
        dialCode: "+229",
        isoCode: "BJ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
        timeOffset: 1
    },
    {
        name: "برمودا",
        dialCode: "+1441",
        isoCode: "BM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bm.svg",
        timeOffset: 4.5
    },
    {
        name: "بوتان",
        dialCode: "+975",
        isoCode: "BT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bt.svg",
        timeOffset: 6
    },
    {
        name: "بوليفيا",
        dialCode: "+591",
        isoCode: "BO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
        timeOffset: -4
    },
    {
        name: "البوسنة والهرسك",
        dialCode: "+387",
        isoCode: "BA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ba.svg",
        timeOffset: 1
    },
    {
        name: "بوتسوانا",
        dialCode: "+267",
        isoCode: "BW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bw.svg",
        timeOffset: 2
    },
    {
        name: "البرازيل",
        dialCode: "+55",
        isoCode: "BR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
        timeOffset: -3
    },
    {
        dialCode: "+246",
        isoCode: "IO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/io.svg",
        timeOffset: 4.5,
        name: "الإقليم البريطاني في المحيط الهندي"
    },
    {
        name: "بروناي",
        dialCode: "+673",
        isoCode: "BN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bn.svg",
        timeOffset: 8
    },
    {
        name: "بلغاريا",
        dialCode: "+359",
        isoCode: "BG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bg.svg",
        timeOffset: 2
    },
    {
        name: "بوركينا فاسو",
        dialCode: "+226",
        isoCode: "BF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
        timeOffset: 4.5
    },
    {
        name: "بوروندي",
        dialCode: "+257",
        isoCode: "BI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bi.svg",
        timeOffset: 2
    },
    {
        name: "كمبوديا",
        dialCode: "+855",
        isoCode: "KH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
        timeOffset: 7
    },
    {
        name: "الكاميرون",
        dialCode: "+237",
        isoCode: "CM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
        timeOffset: 1
    },
    {
        name: "كندا",
        dialCode: "+1",
        isoCode: "CA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
        timeOffset: -8
    },
    {
        name: "الرأس الأخضر",
        dialCode: "+238",
        isoCode: "CV",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cv.svg",
        timeOffset: -1
    },
    {
        name: "جزر كايمان",
        dialCode: "+1345",
        isoCode: "KY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ky.svg",
        timeOffset: 4.5
    },
    {
        name: "جمهورية إفريقيا الوسطى",
        dialCode: "+236",
        isoCode: "CF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
        timeOffset: 1
    },
    {
        name: "تشاد",
        dialCode: "+235",
        isoCode: "TD",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
        timeOffset: 1
    },
    {
        name: "شيلي",
        dialCode: "+56",
        isoCode: "CL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
        timeOffset: -4
    },
    {
        name: "الصين",
        dialCode: "+86",
        isoCode: "CN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
        timeOffset: 8
    },
    {
        dialCode: "+61",
        isoCode: "CX",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cx.svg",
        timeOffset: 4.5,
        name: "جزيرة الكريسماس"
    },
    {
        dialCode: "+61",
        isoCode: "CC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cc.svg",
        timeOffset: 4.5,
        name: "جزيرة كوك"
    },
    {
        name: "كولومبيا",
        dialCode: "+57",
        isoCode: "CO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
        timeOffset: -5
    },
    {
        name: "جزر القمر",
        dialCode: "+269",
        isoCode: "KM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/km.svg",
        timeOffset: 3
    },
    {
        name: "جمهورية الكونغو",
        dialCode: "+242",
        isoCode: "CG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
        timeOffset: 1
    },
    {
        name: "جزر كوك",
        dialCode: "+682",
        isoCode: "CK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ck.svg",
        timeOffset: 4.5
    },
    {
        name: "كوستاريكا",
        dialCode: "+506",
        isoCode: "CR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
        timeOffset: -6
    },
    {
        name: "كرواتيا",
        dialCode: "+385",
        isoCode: "HR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
        timeOffset: 1
    },
    {
        name: "كوبا",
        dialCode: "+53",
        isoCode: "CU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
        timeOffset: -5
    },
    {
        name: "قبرص",
        dialCode: "+357",
        isoCode: "CY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cy.svg",
        timeOffset: 2
    },
    {
        name: "التشيك",
        dialCode: "+420",
        isoCode: "CZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
        timeOffset: 1
    },
    {
        name: "جمهورية الكونغو الديمقراطية",
        dialCode: "+243",
        isoCode: "CD",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
        timeOffset: 1
    },
    {
        name: "الدنمارك",
        dialCode: "+45",
        isoCode: "DK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
        timeOffset: 1
    },
    {
        name: "جيبوتي",
        dialCode: "+253",
        isoCode: "DJ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/dj.svg",
        timeOffset: 3
    },
    {
        name: "دومينيكا",
        dialCode: "+1767",
        isoCode: "DM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
        timeOffset: -4
    },
    {
        name: "جمهورية الدومينيكان",
        dialCode: "+1849",
        isoCode: "DO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
        timeOffset: -4
    },
    {
        name: "الإكوادور",
        dialCode: "+593",
        isoCode: "EC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
        timeOffset: -5
    },
    {
        name: "مصر",
        dialCode: "+20",
        isoCode: "EG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
        timeOffset: 2
    },
    {
        name: "السلفادور",
        dialCode: "+503",
        isoCode: "SV",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
        timeOffset: -6
    },
    {
        name: "غينيا الاستوائية",
        dialCode: "+240",
        isoCode: "GQ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
        timeOffset: 1
    },
    {
        name: "إريتريا",
        dialCode: "+291",
        isoCode: "ER",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/er.svg",
        timeOffset: 3
    },
    {
        name: "إستونيا",
        dialCode: "+372",
        isoCode: "EE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
        timeOffset: 2
    },
    {
        name: "إسواتيني",
        dialCode: "+268",
        isoCode: "SZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sz.svg",
        timeOffset: 2
    },
    {
        name: "إثيوبيا",
        dialCode: "+251",
        isoCode: "ET",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
        timeOffset: 3
    },
    {
        dialCode: "+500",
        isoCode: "FK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/fk.svg",
        timeOffset: 4.5,
        name: "جزر الفوكلاين"
    },
    {
        dialCode: "+298",
        isoCode: "FO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/fo.svg",
        timeOffset: 4.5,
        name: "جزر فارو"
    },
    {
        name: "فيجي",
        dialCode: "+679",
        isoCode: "FJ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
        timeOffset: 12
    },
    {
        name: "فنلندا",
        dialCode: "+358",
        isoCode: "FI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
        timeOffset: 2
    },
    {
        name: "فرنسا",
        dialCode: "+33",
        isoCode: "FR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
        timeOffset: 1
    },
    {
        dialCode: "+594",
        isoCode: "GF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gf.svg",
        timeOffset: 4.5,
        name: "غويانا الفرنسية"
    },
    {
        dialCode: "+689",
        isoCode: "PF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pf.svg",
        timeOffset: 4.5,
        name: "بولينزيا الفرنسية"
    },
    {
        name: "الغابون",
        dialCode: "+241",
        isoCode: "GA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
        timeOffset: 1
    },
    {
        name: "غامبيا",
        dialCode: "+220",
        isoCode: "GM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
        timeOffset: 4.5
    },
    {
        name: "جورجيا",
        dialCode: "+995",
        isoCode: "GE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
        timeOffset: 4
    },
    {
        name: "ألمانيا",
        dialCode: "+49",
        isoCode: "DE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
        timeOffset: 1
    },
    {
        name: "غانا",
        dialCode: "+233",
        isoCode: "GH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+350",
        isoCode: "GI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gi.svg",
        timeOffset: 4.5,
        name: "جبل طارق"
    },
    {
        name: "اليونان",
        dialCode: "+30",
        isoCode: "GR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
        timeOffset: 2
    },
    {
        dialCode: "+299",
        isoCode: "GL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
        timeOffset: 4.5,
        name: "غرينلاند"
    },
    {
        name: "غرينادا",
        dialCode: "+1473",
        isoCode: "GD",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gd.svg",
        timeOffset: -4
    },
    {
        dialCode: "+590",
        isoCode: "GP",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gp.svg",
        timeOffset: 4.5,
        name: "غوادلوب"
    },
    {
        dialCode: "+1671",
        isoCode: "GU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gu.svg",
        timeOffset: 4.5,
        name: "غوام"
    },
    {
        name: "غواتيمالا",
        dialCode: "+502",
        isoCode: "GT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
        timeOffset: -6
    },
    {
        dialCode: "+44-1481",
        isoCode: "GG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gg.svg",
        timeOffset: 4.5,
        name: "غيرنزي"
    },
    {
        name: "غينيا",
        dialCode: "+224",
        isoCode: "GN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
        timeOffset: 4.5
    },
    {
        name: "غينيا بيساو",
        dialCode: "+245",
        isoCode: "GW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
        timeOffset: 4.5
    },
    {
        name: "غويانا",
        dialCode: "+592",
        isoCode: "GY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
        timeOffset: -4
    },
    {
        name: "هايتي",
        dialCode: "+509",
        isoCode: "HT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
        timeOffset: -5
    },
    {
        name: "الفاتيكان",
        dialCode: "+379",
        isoCode: "VA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/va.svg",
        timeOffset: 1
    },
    {
        name: "هندوراس",
        dialCode: "+504",
        isoCode: "HN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
        timeOffset: -6
    },
    {
        dialCode: "+852",
        isoCode: "HK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
        timeOffset: 4.5,
        name: "هونغ كونغ"
    },
    {
        name: "المجر",
        dialCode: "+36",
        isoCode: "HU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
        timeOffset: 1
    },
    {
        name: "أيسلندا",
        dialCode: "+354",
        isoCode: "IS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
        timeOffset: 4.5
    },
    {
        name: "الهند",
        dialCode: "+91",
        isoCode: "IN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
        timeOffset: 5.5
    },
    {
        name: "إندونيسيا",
        dialCode: "+62",
        isoCode: "ID",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
        timeOffset: 7
    },
    {
        name: "إيران",
        dialCode: "+98",
        isoCode: "IR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ir.svg",
        timeOffset: 3.5
    },
    {
        name: "العراق",
        dialCode: "+964",
        isoCode: "IQ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/iq.svg",
        timeOffset: 3
    },
    {
        name: "أيرلندا",
        dialCode: "+353",
        isoCode: "IE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+44-1624",
        isoCode: "IM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/im.svg",
        timeOffset: 4.5,
        name: "جزيرة مان"
    },
    {
        name: "إيطاليا",
        dialCode: "+39",
        isoCode: "IT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
        timeOffset: 1
    },
    {
        name: "جامايكا",
        dialCode: "+1876",
        isoCode: "JM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
        timeOffset: -5
    },
    {
        name: "اليابان",
        dialCode: "+81",
        isoCode: "JP",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
        timeOffset: 9
    },
    {
        dialCode: "+44-1534",
        isoCode: "JE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/je.svg",
        timeOffset: 4.5,
        name: "جيرسي"
    },
    {
        name: "الأردن",
        dialCode: "+962",
        isoCode: "JO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/jo.svg",
        timeOffset: 3
    },
    {
        name: "كازاخستان",
        dialCode: "+77",
        isoCode: "KZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/kz.svg",
        timeOffset: 6
    },
    {
        name: "كينيا",
        dialCode: "+254",
        isoCode: "KE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
        timeOffset: 3
    },
    {
        name: "كيريباتي",
        dialCode: "+686",
        isoCode: "KI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ki.svg",
        timeOffset: 12
    },
    {
        name: "كوريا الشمالية",
        dialCode: "+850",
        isoCode: "KP",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/kp.svg",
        timeOffset: 9
    },
    {
        name: "كوريا الجنوبية",
        dialCode: "+82",
        isoCode: "KR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
        timeOffset: 9
    },
    {
        dialCode: "+383",
        isoCode: "XK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/xk.svg",
        timeOffset: 4.5,
        name: "كوسوفو"
    },
    {
        name: "الكويت",
        dialCode: "+965",
        isoCode: "KW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/kw.svg",
        timeOffset: 3
    },
    {
        name: "قرغيزستان",
        dialCode: "+996",
        isoCode: "KG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/kg.svg",
        timeOffset: 6
    },
    {
        name: "لاوس",
        dialCode: "+856",
        isoCode: "LA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/la.svg",
        timeOffset: 7
    },
    {
        name: "لاتفيا",
        dialCode: "+371",
        isoCode: "LV",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/lv.svg",
        timeOffset: 2
    },
    {
        name: "لبنان",
        dialCode: "+961",
        isoCode: "LB",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/lb.svg",
        timeOffset: 3
    },
    {
        name: "ليسوتو",
        dialCode: "+266",
        isoCode: "LS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ls.svg",
        timeOffset: 2
    },
    {
        name: "ليبيريا",
        dialCode: "+231",
        isoCode: "LR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
        timeOffset: 4.5
    },
    {
        name: "ليبيا",
        dialCode: "+218",
        isoCode: "LY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
        timeOffset: 2
    },
    {
        name: "ليختنشتاين",
        dialCode: "+423",
        isoCode: "LI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
        timeOffset: 1
    },
    {
        name: "ليتوانيا",
        dialCode: "+370",
        isoCode: "LT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
        timeOffset: 2
    },
    {
        name: "لوكسمبورغ",
        dialCode: "+352",
        isoCode: "LU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
        timeOffset: 1
    },
    {
        dialCode: "+853",
        isoCode: "MO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mo.svg",
        timeOffset: 4.5,
        name: "ماكاو"
    },
    {
        name: "مدغشقر",
        dialCode: "+261",
        isoCode: "MG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
        timeOffset: 3
    },
    {
        name: "مالاوي",
        dialCode: "+265",
        isoCode: "MW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mw.svg",
        timeOffset: 2
    },
    {
        name: "ماليزيا",
        dialCode: "+60",
        isoCode: "MY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/my.svg",
        timeOffset: 8
    },
    {
        name: "مالديف",
        dialCode: "+960",
        isoCode: "MV",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mv.svg",
        timeOffset: 5
    },
    {
        name: "مالطا",
        dialCode: "+356",
        isoCode: "MT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mt.svg",
        timeOffset: 1
    },
    {
        name: "جزر المالديف",
        dialCode: "+692",
        isoCode: "MH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mh.svg",
        timeOffset: 12
    },
    {
        dialCode: "+596",
        isoCode: "MQ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mq.svg",
        timeOffset: 4.5,
        name: "مارتينيك"
    },
    {
        name: "موريتانيا",
        dialCode: "+222",
        isoCode: "MR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
        timeOffset: 4.5
    },
    {
        name: "موريشيوس",
        dialCode: "+230",
        isoCode: "MU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mu.svg",
        timeOffset: 4
    },
    {
        dialCode: "+262",
        isoCode: "YT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/yt.svg",
        timeOffset: 4.5,
        name: "مايوت"
    },
    {
        name: "المكسيك",
        dialCode: "+52",
        isoCode: "MX",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
        timeOffset: -6
    },
    {
        name: "ولايات ميكرونيسيا",
        dialCode: "+691",
        isoCode: "FM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/fm.svg",
        timeOffset: 10
    },
    {
        name: "مولدوفا",
        dialCode: "+373",
        isoCode: "MD",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/md.svg",
        timeOffset: 2
    },
    {
        name: "موناكو",
        dialCode: "+377",
        isoCode: "MC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
        timeOffset: 1
    },
    {
        name: "منغوليا",
        dialCode: "+976",
        isoCode: "MN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
        timeOffset: 8
    },
    {
        name: "الجبل الأسود",
        dialCode: "+382",
        isoCode: "ME",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/me.svg",
        timeOffset: 1
    },
    {
        dialCode: "+1664",
        isoCode: "MS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ms.svg",
        timeOffset: 4.5,
        name: "مونتسيرات"
    },
    {
        name: "المغرب",
        dialCode: "+212",
        isoCode: "MA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
        timeOffset: 1
    },
    {
        name: "موزمبيق",
        dialCode: "+258",
        isoCode: "MZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
        timeOffset: 2
    },
    {
        name: "ميانمار",
        dialCode: "+95",
        isoCode: "MM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
        timeOffset: 6.5
    },
    {
        name: "ناميبيا",
        dialCode: "+264",
        isoCode: "NA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/na.svg",
        timeOffset: 2
    },
    {
        name: "ناورو",
        dialCode: "+674",
        isoCode: "NR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/nr.svg",
        timeOffset: 12
    },
    {
        name: "نيبال",
        dialCode: "+977",
        isoCode: "NP",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
        timeOffset: 5.75
    },
    {
        name: "هولندا",
        dialCode: "+31",
        isoCode: "NL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
        timeOffset: 1
    },
    {
        dialCode: "+599",
        isoCode: "AN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/an.svg",
        timeOffset: 4.5,
        name: "جزر الأنتيل الهولندية"
    },
    {
        dialCode: "+687",
        isoCode: "NC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/nc.svg",
        timeOffset: 4.5,
        name: "كاليدونيا الجديدة"
    },
    {
        name: "نيوزيلندا",
        dialCode: "+64",
        isoCode: "NZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
        timeOffset: 12
    },
    {
        name: "نيكاراغوا",
        dialCode: "+505",
        isoCode: "NI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
        timeOffset: -6
    },
    {
        name: "النيجر",
        dialCode: "+227",
        isoCode: "NE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
        timeOffset: 1
    },
    {
        name: "نيجيريا",
        dialCode: "+234",
        isoCode: "NG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
        timeOffset: 1
    },
    {
        dialCode: "+683",
        isoCode: "NU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/nu.svg",
        timeOffset: 4.5,
        name: "نيوي"
    },
    {
        dialCode: "+672",
        isoCode: "NF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/nf.svg",
        timeOffset: 4.5,
        name: "جزيرة نورفوك"
    },
    {
        dialCode: "+389",
        isoCode: "MK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mk.svg",
        timeOffset: 1,
        name: "مقدونيا الشمالية"
    },
    {
        dialCode: "+1670",
        isoCode: "MP",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mp.svg",
        timeOffset: 4.5,
        name: "جزر ماريانا الشمالية"
    },
    {
        name: "النرويج",
        dialCode: "+47",
        isoCode: "NO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
        timeOffset: 1
    },
    {
        name: "عمان",
        dialCode: "+968",
        isoCode: "OM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/om.svg",
        timeOffset: 4
    },
    {
        name: "باكستان",
        dialCode: "+92",
        isoCode: "PK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pk.svg",
        timeOffset: 5
    },
    {
        name: "بالاو",
        dialCode: "+680",
        isoCode: "PW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pw.svg",
        timeOffset: 9
    },
    {
        name: "فلسطين",
        dialCode: "+970",
        isoCode: "PS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ps.svg",
        timeOffset: 4.5
    },
    {
        name: "بنما",
        dialCode: "+507",
        isoCode: "PA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
        timeOffset: -5
    },
    {
        name: "بابوا غينيا الجديدة",
        dialCode: "+675",
        isoCode: "PG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
        timeOffset: 10
    },
    {
        name: "باراغواي",
        dialCode: "+595",
        isoCode: "PY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
        timeOffset: -4
    },
    {
        dialCode: "+51",
        isoCode: "PE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
        timeOffset: -5,
        name: "بيرو"
    },
    {
        dialCode: "+63",
        isoCode: "PH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
        timeOffset: 8,
        name: "الفلبين"
    },
    {
        dialCode: "+872",
        isoCode: "PN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pn.svg",
        timeOffset: 4.5,
        name: "جزيرة بوفيه"
    },
    {
        dialCode: "+48",
        isoCode: "PL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
        timeOffset: 1,
        name: "بولندا"
    },
    {
        name: "البرتغال",
        dialCode: "+351",
        isoCode: "PT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+1939",
        isoCode: "PR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
        timeOffset: 4.5,
        name: "بورتو ريكو"
    },
    {
        name: "قطر",
        dialCode: "+974",
        isoCode: "QA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
        timeOffset: 3
    },
    {
        dialCode: "+262",
        isoCode: "RE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/re.svg",
        timeOffset: 4.5,
        name: "ريونيون"
    },
    {
        name: "رومانيا",
        dialCode: "+40",
        isoCode: "RO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
        timeOffset: 2
    },
    {
        name: "روسيا",
        dialCode: "+7",
        isoCode: "RU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
        timeOffset: 3
    },
    {
        name: "رواندا",
        dialCode: "+250",
        isoCode: "RW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/rw.svg",
        timeOffset: 2
    },
    {
        dialCode: "+590",
        isoCode: "BL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/bl.svg",
        timeOffset: 4.5,
        name: "سان بارتيلمي"
    },
    {
        dialCode: "+290",
        isoCode: "SH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sh.svg",
        timeOffset: 4.5,
        name: "سانت هيلena"
    },
    {
        name: "سانت كيتس ونيفيس",
        dialCode: "+1869",
        isoCode: "KN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/kn.svg",
        timeOffset: -4
    },
    {
        name: "سانت لوسيا",
        dialCode: "+1758",
        isoCode: "LC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
        timeOffset: -4
    },
    {
        dialCode: "+590",
        isoCode: "MF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/mf.svg",
        timeOffset: 4.5,
        name: "سانت مارتن"
    },
    {
        dialCode: "+508",
        isoCode: "PM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/pm.svg",
        timeOffset: 4.5,
        name: "سان بيير وميكلون"
    },
    {
        name: "سانت فينسنت والغرينادين",
        dialCode: "+1784",
        isoCode: "VC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
        timeOffset: -4
    },
    {
        name: "ساموا",
        dialCode: "+685",
        isoCode: "WS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ws.svg",
        timeOffset: 13
    },
    {
        name: "سان مارينو",
        dialCode: "+378",
        isoCode: "SM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sm.svg",
        timeOffset: 1
    },
    {
        dialCode: "+239",
        isoCode: "ST",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
        timeOffset: 1,
        name: "ساو تومي وبرينسيبي"
    },
    {
        name: "السعودية",
        dialCode: "+966",
        isoCode: "SA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg",
        timeOffset: 3
    },
    {
        name: "السنغال",
        dialCode: "+221",
        isoCode: "SN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
        timeOffset: 4.5
    },
    {
        name: "صربيا",
        dialCode: "+381",
        isoCode: "RS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
        timeOffset: 1
    },
    {
        name: "سيشيل",
        dialCode: "+248",
        isoCode: "SC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sc.svg",
        timeOffset: 4
    },
    {
        name: "سيراليون",
        dialCode: "+232",
        isoCode: "SL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
        timeOffset: 4.5
    },
    {
        name: "سنغافورة",
        dialCode: "+65",
        isoCode: "SG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
        timeOffset: 8
    },
    {
        dialCode: "+1721",
        isoCode: "SX",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sx.svg",
        timeOffset: 4.5,
        name: "سينت مارتن"
    },
    {
        name: "سلوفاكيا",
        dialCode: "+421",
        isoCode: "SK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
        timeOffset: 1
    },
    {
        name: "سلوفينيا",
        dialCode: "+386",
        isoCode: "SI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
        timeOffset: 1
    },
    {
        name: "جزر سليمان",
        dialCode: "+677",
        isoCode: "SB",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sb.svg",
        timeOffset: 11
    },
    {
        name: "الصومال",
        dialCode: "+252",
        isoCode: "SO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/so.svg",
        timeOffset: 3
    },
    {
        name: "جنوب أفريقيا",
        dialCode: "+27",
        isoCode: "ZA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
        timeOffset: 2
    },
    {
        dialCode: "+500",
        isoCode: "GS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gs.svg",
        timeOffset: 4.5,
        name: "جزر جورجيا الجنوبية"
    },
    {
        dialCode: "+211",
        isoCode: "SS",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ss.svg",
        timeOffset: 3,
        name: "جنوب السودان"
    },
    {
        name: "إسبانيا",
        dialCode: "+34",
        isoCode: "ES",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
        timeOffset: 1
    },
    {
        name: "سريلانكا",
        dialCode: "+94",
        isoCode: "LK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/lk.svg",
        timeOffset: 5.5
    },
    {
        name: "السودان",
        dialCode: "+249",
        isoCode: "SD",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sd.svg",
        timeOffset: 2
    },
    {
        name: "سورينام",
        dialCode: "+597",
        isoCode: "SR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
        timeOffset: -3
    },
    {
        dialCode: "+47",
        isoCode: "SJ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sj.svg",
        timeOffset: 4.5,
        name: "جزيرة سبيك"
    },
    {
        name: "السويد",
        dialCode: "+46",
        isoCode: "SE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
        timeOffset: 1
    },
    {
        name: "سويسرا",
        dialCode: "+41",
        isoCode: "CH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
        timeOffset: 1
    },
    {
        name: "سوريا",
        dialCode: "+963",
        isoCode: "SY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/sy.svg",
        timeOffset: 3
    },
    {
        name: "تايوان",
        dialCode: "+886",
        isoCode: "TW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
        timeOffset: 8
    },
    {
        name: "طاجيكستان",
        dialCode: "+992",
        isoCode: "TJ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tj.svg",
        timeOffset: 5
    },
    {
        name: "تنزانيا",
        dialCode: "+255",
        isoCode: "TZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tz.svg",
        timeOffset: 3
    },
    {
        name: "تايلاند",
        dialCode: "+66",
        isoCode: "TH",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
        timeOffset: 7
    },
    {
        name: "تيمور الشرقية",
        dialCode: "+670",
        isoCode: "TL",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
        timeOffset: 9
    },
    {
        name: "توغو",
        dialCode: "+228",
        isoCode: "TG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
        timeOffset: 4.5
    },
    {
        dialCode: "+690",
        isoCode: "TK",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tk.svg",
        timeOffset: 4.5,
        name: "توكيلاو"
    },
    {
        name: "تونغا",
        dialCode: "+676",
        isoCode: "TO",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/to.svg",
        timeOffset: 13
    },
    {
        name: "ترينيداد وتوباغو",
        dialCode: "+1868",
        isoCode: "TT",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tt.svg",
        timeOffset: -4
    },
    {
        name: "تركيا",
        dialCode: "+90",
        isoCode: "TR",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
        timeOffset: 3
    },
    {
        name: "تركمانستان",
        dialCode: "+993",
        isoCode: "TM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tm.svg",
        timeOffset: 5
    },
    {
        dialCode: "+1649",
        isoCode: "TC",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tc.svg",
        timeOffset: 4.5,
        name: "جزر الترك وكايكوس"
    },
    {
        name: "توفالو",
        dialCode: "+688",
        isoCode: "TV",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/tv.svg",
        timeOffset: 12
    },
    {
        name: "أوغندا",
        dialCode: "+256",
        isoCode: "UG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
        timeOffset: 3
    },
    {
        name: "أوكرانيا",
        dialCode: "+380",
        isoCode: "UA",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
        timeOffset: 3
    },
    {
        name: "الإمارات العربية المتحدة",
        dialCode: "+971",
        isoCode: "AE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg",
        timeOffset: 4
    },
    {
        name: "المملكة المتحدة",
        dialCode: "+44",
        isoCode: "GB",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
        timeOffset: 4.5
    },
    {
        name: "الولايات المتحدة",
        dialCode: "+1",
        isoCode: "US",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
        timeOffset: -5
    },
    {
        dialCode: "+246",
        isoCode: "UMI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/umi.svg",
        timeOffset: 4.5,
        name: "جزر الولايات المتحدة البعيدة"
    },
    {
        name: "الأوروغواي",
        dialCode: "+598",
        isoCode: "UY",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
        timeOffset: -3
    },
    {
        name: "أوزبكستان",
        dialCode: "+998",
        isoCode: "UZ",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/uz.svg",
        timeOffset: 5
    },
    {
        name: "فانواتو",
        dialCode: "+678",
        isoCode: "VU",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/vu.svg",
        timeOffset: 11
    },
    {
        name: "فنزويلا",
        dialCode: "+58",
        isoCode: "VE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ve.svg",
        timeOffset: -4
    },
    {
        name: "فيتنام",
        dialCode: "+84",
        isoCode: "VN",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/vn.svg",
        timeOffset: 7
    },
    {
        dialCode: "+1284",
        isoCode: "VG",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/vg.svg",
        timeOffset: 4.5,
        name: "جزر العذراء البريطانية"
    },
    {
        dialCode: "+1340",
        isoCode: "VI",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/vi.svg",
        timeOffset: 4.5,
        name: "جزر العذراء الأمريكية"
    },
    {
        dialCode: "+681",
        isoCode: "WF",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/wf.svg",
        timeOffset: 4.5,
        name: "واليس وفوتونا"
    },
    {
        name: "اليمن",
        dialCode: "+967",
        isoCode: "YE",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/ye.svg",
        timeOffset: 3
    },
    {
        name: "زامبيا",
        dialCode: "+260",
        isoCode: "ZM",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/zm.svg",
        timeOffset: 2
    },
    {
        name: "زيمبابوي",
        dialCode: "+263",
        isoCode: "ZW",
        flagUrl: "https://cdn.kcak11.com/CountryFlags/countries/zw.svg",
        timeOffset: 2
    }
]