import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppCommonModule } from '../common/common.module';
import { AssignLocationBothComponent } from './components/assign-location-both.component/assign-location-both.component';
import { DoctorAppointmentSuccessComponent } from './components/doctor-appointment-success.component/doctor-appointment-success.component';
import { DoctorAppointmentsCardComponent } from './components/doctor-appointments-card.component/doctor-appointments-card.component';
import { DoctorAppointmentsGridComponent } from './components/doctor-appointments-grid.component/doctor-appointments-grid.component';
import { DoctorAppointmentsListComponent } from './components/doctor-appointments-list.component/doctor-appointments-list.component';
import { DoctorAppointmentsTableRestComponent } from './components/doctor-appointments-table-rest/doctor-appointments-table-rest.component';
import { DoctorAppointmentsTableComponent } from './components/doctor-appointments-table.component/doctor-appointments-table.component';
import { DoctorConsultationsTableComponent } from './components/doctor-consultations-table.component/doctor-consultations-table.component';
import { DoctorHistoriqueComponent } from './components/doctor-historique/doctor-historique.component';
import { DoctorPatientHistoricsComponent } from './components/doctor-patient-historics.component/doctor-patient-historics.component';
import { DoctorPatientsCardComponent } from './components/doctor-patients-card.component/doctor-patients-card.component';
import { DoctorPatientsGridComponent } from './components/doctor-patients-grid.component/doctor-patients-grid.component';
import { DoctorPatientsListComponent } from './components/doctor-patients-list.component/doctor-patients-list.component';
import { DoctorPatientsTableComponent } from './components/doctor-patients-table.component/doctor-patients-table.component';
import { DoctorPrescriptionsTableComponent } from './components/doctor-prescriptions-table.component/doctor-prescriptions-table.component';
import { DoctorSecretaryAppointmentSuccessComponent } from './components/doctor-secretary-appointment-success.component/doctor-secretary-appointment-success.component';
import { DocumentsGridExplorerComponent } from "./components/documents-grid-explorer.component/documents-grid-explorer.component";
import { NavbarComponent } from './components/navbar.component/navbar.component';
import { PatientAntecedentsDetailsComponent } from './components/patient-antecedents-details.component/patient-antecedents-details.component';
import { PatientAppointmentSuccessComponent } from './components/patient-appointment-success.component/patient-appointment-success.component';
import { PatientAppointmentsListComponent } from './components/patient-appointments-list.component/patient-appointments-list.component';
import { PatientAppointmentsTableComponent } from './components/patient-appointments-table.component/patient-appointments-table.component';
import { PatientPrescriptionsTableComponent } from './components/patient-prescriptions-table/patient-prescriptions-table.component';
import { ActsFormModule } from './modules/acts-form.module/acts-form.module';
import { AppointmentFormModule } from './modules/appointment-form.module/appointment-form.module';
import { BiologyFormModule } from './modules/biology-form.module/biology-form.module';
import { ConsultationFormsModule } from './modules/consultation-forms.module/consultation-forms.module';
import { MedicFormModule } from './modules/medic-form.module/medic-form.module';
import { ModelPaymentsModule } from './modules/model-payments.module/model-payments.module';
import { ParametreSanteFormModule } from './modules/parametre-sante-form-module/parametre-sante-form-module';
import { PrescriptionFormModule } from './modules/prescription-form.module/prescription-form.module';
import { RadiologyFormModule } from './modules/radiology-form.module/radiology-form.module';
import { TextFillingsFormModule } from './modules/text-fillings-form.module/text-fillings-form.module';
import { VaccinationFormModule } from './modules/vaccination-from.module/vaccination-form.module';

import { DoctorFilterPipe } from './pipes/doctor-filter.pipe/filter-doctor.pipe';
import { FileCoverPipe } from './pipes/file-cover.pipe/file-cover.pipe';
import { FilterConsultationMotifPipe } from './pipes/filter-consultation-motif.pipe/filter-consultation-motif.pipe';
import { FilterConsultationPipe } from './pipes/filter-consultation.pipe/filter-consultation.pipe';
import { FilterSpecialtyPipe } from './pipes/filter.specialty.pipe/filter-specialty.pipe';
import { ImageActePipe } from './pipes/image-act.pipe/image-acte.pipe';
import { SearchConsultationDatePipe } from './pipes/search-consultation-date.pipe/search-consultation-date.pipe';
import { DoctorPatientSortPipe } from "./pipes/sort-me-patient.pipe/doctor-patient-sort.pipe";
import { SpecialtyFilterPipe } from './pipes/specialty-filter.pipe/specialty-filter.pipe';
import { UserRolePipe } from './pipes/user-role.pipe/user-role.pipe';

import { AddHeureRdvComponent } from './components/add-heure-rdv/add-heure-rdv.component';
import { BMIChartComponent } from './components/bmi-chart.component/bmi-chart.component';
import { DialogeAffectComponent } from './components/dialoge-affect.component/dialoge-affect.component';
import { DoctorAppointmentsCardRestComponent } from './components/doctor-appointments-card-rest/doctor-appointments-card-rest.component';
import { DoctorConsultationCardComponent } from './components/doctor-consultation-card.compoent/doctor-consultation-card.component';
import { DoctorPrescriptionsCardComponent } from './components/doctor-prescriptions-card.component/doctor-prescriptions-card.component';
import { DocumentFormComponent } from './components/document-form.component/document-form.component';
import { DocumentsTableComponent } from './components/documents-table.component/documents-table.component';
import { MailBoxComponent } from './components/mail-box.component/mail-box.component';
import { PatientGrowthChartComponent } from './components/patient-growth-chart.component/patient-growth-chart.component';
import { ChartConsultationComponent } from './components/progress-bar/chart-consultation.component/chart-consultation.component';
import { ChartPatientPeriodeComponent } from './components/progress-bar/chart-patient-periode.component/chart-patient-periode.component';
import { ChartPatientComponent } from './components/progress-bar/chart-patient.component/chart-patient.component';
import { ChartRdvByPeriodeComponent } from './components/progress-bar/chart-rdv-by-periode.component/chart-rdv-by-periode.component';
import { ChartRdvComponent } from './components/progress-bar/chart-rdv.component/chart-rdv.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar/progress-bar.component';
import { RounderPatientComponent } from './components/progress-bar/rounder-patient.component/rounder-patient.component';
import { RounderRdvComponent } from './components/progress-bar/rounder-rdv.component.ts/rounder-rdv.component';
import { RdvUrgenceFormsComponent } from './components/rdv-urgence-forms.component/rdv-urgence-forms.component';
import { GrowthChartModule } from './modules/growth-chart/growth-chart.module';
import { ListeNotesSharedComponent } from './modules/liste-notes-shared/liste-notes-shared.component';
import { VideoCallModule } from './modules/video-call.module/video-call.module';
import { ListeDoctorComponent } from './components/liste-doctor.component/liste-doctor.component';
import { DoctorsListComponent } from './components/doctors-list.component/doctors-list.component';
import { AppointmentBookingModule } from './modules/appointment-booking.module/appointment-booking.module';
import { DoctorAvailabilitiesFilterPipe } from './pipes/doctor-disponibilities-filter.pipe/doctor-availabilities-filter.pipe';
import { DoctorCalendarModule } from './modules/doctor-calendar.module/doctor-calendar.module';
import { ListFillingsComponent } from './components/list-fillings.component/list-fillings.component';
import { TextFillingsPipePipe } from './components/text-fillings-pipe.pipe';
import { UserAvatarFormComponent } from './components/user-avatar-form.component/user-avatar-form.component';
import { DoctorPatientsTableSortbalComponent } from './components/doctor-patients-table-sortbal.component/doctor-patients-table-sortbal.component';
import { FilterCurrencyPipe } from './pipes/filter-curreny.pipe/filter-currency.pipe';
import { DashboradPaiementTableSortbalComponent } from './components/dashborad-paiement-table-sortbal/dashborad-paiement-table-sortbal.component';

@NgModule({
    declarations: [

        AssignLocationBothComponent,
        DoctorAppointmentSuccessComponent,
        PatientAppointmentSuccessComponent,
        DoctorSecretaryAppointmentSuccessComponent,
        NavbarComponent,
        //DoctorPatientNavbarComponent,
        DoctorFilterPipe,
        FilterConsultationMotifPipe,
        FilterConsultationPipe,
        FilterSpecialtyPipe,
        ImageActePipe,
        SearchConsultationDatePipe,
        DoctorPatientSortPipe,
        SpecialtyFilterPipe,
        FileCoverPipe,
        UserRolePipe,
        DoctorAppointmentsTableComponent,
        DoctorPrescriptionsTableComponent,
        DoctorConsultationsTableComponent,
        DoctorAppointmentsGridComponent,
        DoctorAppointmentsListComponent,
        DoctorPatientsGridComponent,
        DoctorPatientsTableComponent,
        DoctorPatientsListComponent,
        PatientAppointmentsListComponent,
        PatientAppointmentsTableComponent,
        PatientPrescriptionsTableComponent,
        DoctorPatientHistoricsComponent,
        DoctorHistoriqueComponent,
        DocumentsGridExplorerComponent,
        DoctorPatientsCardComponent,
        PatientAntecedentsDetailsComponent,
        DoctorAppointmentsCardComponent,
        DoctorAppointmentsTableRestComponent,
        ListeNotesSharedComponent,
        DoctorConsultationCardComponent,
        DoctorPrescriptionsCardComponent,
        DoctorAppointmentsCardRestComponent,
        MailBoxComponent,
        DocumentFormComponent,
        BMIChartComponent,
        RdvUrgenceFormsComponent,
        DialogeAffectComponent,
        AddHeureRdvComponent,
        PatientGrowthChartComponent,
        ProgressBarComponent,
        RounderPatientComponent,
        RounderRdvComponent,
        ChartPatientComponent,
        ChartRdvComponent,
        ChartConsultationComponent,
        ChartRdvByPeriodeComponent,
        ChartPatientPeriodeComponent,
        DocumentsTableComponent,
        ListeDoctorComponent,
        DoctorsListComponent,
        UserAvatarFormComponent,
        DoctorAvailabilitiesFilterPipe,
        ListFillingsComponent,
        TextFillingsPipePipe,
        DoctorPatientsTableSortbalComponent,
        FilterCurrencyPipe,
        DashboradPaiementTableSortbalComponent
    ],
    imports: [
        AppCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // forms
        ActsFormModule,
        DoctorCalendarModule,
        AppointmentFormModule,
        BiologyFormModule,
        ConsultationFormsModule,
        MedicFormModule,
        ParametreSanteFormModule,
        PrescriptionFormModule,
        RadiologyFormModule,
        VaccinationFormModule,
        TextFillingsFormModule,
        ModelPaymentsModule,
        VideoCallModule,
        GrowthChartModule,
        AppointmentBookingModule
    ],
    exports: [
        DoctorAppointmentsTableComponent,
        DoctorPrescriptionsTableComponent,
        DoctorPatientsTableComponent,
        DoctorConsultationsTableComponent,
        DialogeAffectComponent,
        DoctorPatientsGridComponent,
        DoctorPatientsTableComponent,
        DoctorPatientsListComponent,
        DoctorPatientsCardComponent,
        DoctorAppointmentsGridComponent,
        DoctorAppointmentsListComponent,
        DoctorAppointmentsCardComponent,
        PatientAppointmentsListComponent,
        PatientAppointmentsTableComponent,
        DoctorAppointmentsTableRestComponent,
        AppCommonModule,
        AssignLocationBothComponent,
        NavbarComponent,
        PatientGrowthChartComponent,
        // DoctorPatientNavbarComponent,
        DoctorFilterPipe,
        FilterConsultationMotifPipe,
        FilterConsultationPipe,
        FilterSpecialtyPipe,
        ImageActePipe,
        SearchConsultationDatePipe,
        DoctorPatientSortPipe,
        SpecialtyFilterPipe,
        DoctorAvailabilitiesFilterPipe,
        DoctorPatientHistoricsComponent,
        RdvUrgenceFormsComponent,
        // forms
        AppointmentBookingModule,
        ActsFormModule,
        AppointmentFormModule,
        BiologyFormModule,
        ConsultationFormsModule,
        MedicFormModule,
        ParametreSanteFormModule,
        PrescriptionFormModule,
        RadiologyFormModule,
        VaccinationFormModule,
        FileCoverPipe,
        UserRolePipe,
        DoctorAppointmentSuccessComponent,
        PatientAppointmentSuccessComponent,
        DoctorSecretaryAppointmentSuccessComponent,
        TextFillingsFormModule,
        DocumentsGridExplorerComponent,
        PatientAntecedentsDetailsComponent,
        ModelPaymentsModule,
        GrowthChartModule,
        MailBoxComponent,
        ListeNotesSharedComponent,
        DoctorConsultationCardComponent,
        DoctorPrescriptionsCardComponent,
        DoctorAppointmentsCardRestComponent,
        DocumentFormComponent,
        BMIChartComponent,
        AddHeureRdvComponent,
        VideoCallModule,
        ProgressBarComponent,
        RounderPatientComponent,
        DoctorCalendarModule,
        RounderRdvComponent,
        ChartPatientComponent,
        ChartRdvComponent,
        ChartConsultationComponent,
        ChartRdvByPeriodeComponent,
        ChartPatientPeriodeComponent,
        DocumentsTableComponent,
        ListeDoctorComponent,
        DoctorsListComponent,
        ListFillingsComponent,
        UserAvatarFormComponent,
        TextFillingsPipePipe,
        DoctorPatientsTableSortbalComponent,
        FilterCurrencyPipe,
        DashboradPaiementTableSortbalComponent
    ]
})
export class SharedModule { }