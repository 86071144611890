import { Appointment } from "../appointment.entity";

export class PatientRequestedAppointment extends Appointment {
    firstName: string;
    lastName: string;
    mobilePhone: string;
    birthDate: Date;
    country: string;
    email: string;
    constructor(other?: Partial<PatientRequestedAppointment>) {
        super(other)
        this.firstName = other?.firstName || '';
        this.lastName = other?.lastName || '';
        this.mobilePhone = other?.mobilePhone || "0";
        this.birthDate = other?.birthDate || new Date();
        this.country = other?.country || other?.patientCountry || '';
        this.email = other?.email || other?.patientEmail || '';
    }


    public static fromResponse(response: any): PatientRequestedAppointment {
        return new PatientRequestedAppointment({
            id: response.id,
            startTime: response.startTime,
            duration: response.duration,
            location: response.location,
            status: response.status,
            motif: response.motif,
            date: new Date(response.date),
            isEmergency: response.isEmergency,
            hasConsultation: response.hasConsultation,
            hasPrescription: response.hasPrescription,
            historicalStatus: response.historicalStatus,
            regularAmount: response.regularAmount,
            paidAmount: response.paidAmount,
            requested: response.requested,
            confirmed: response.confirmed,
            patientBirthDate: response.patientBirthDate,
            patientFirstName: response.patientFirstName,
            patientLastName: response.patientLastName,
            patientMobilePhone: response.patientMobilePhone,

            birthDate: response.patientBirthDate,
            firstName: response.patientFirstName,
            lastName: response.patientLastName,
            mobilePhone: response.patientMobilePhone,
            country: response.patientCountry,
            email: response.patientEmail,
            requestDuration: response.requestDuration,
            language: response.language
        });
    }

    requestPayload(): any {
        return {
            firstName: this.patientFirstName,
            lastName: this.patientLastName,
            mobilePhone: this.patientMobilePhone,
            birthDate: this.patientBirthDate.getTime(),
            date: this.date.getTime(),
            startTime: this.startTime,
            duration: this.duration,
            location: this.location,
            motif: this.motif,
            language: this.language,
            email: this.email,
            country: this.country,
        }
    }
}
