<div class="row row-grid"*ngIf="appointmentsData.length;else empty" >
    <div class="col-md-6 col-lg-4 col-xl-3"  *ngFor="let appointment of appointmentsData">
      <div class="card widget-profile pat-widget-profile">
        <div class="card-body">
          <div class="pro-widget-content">
            <div class="profile-info-widget">
              <!-- <a  class="booking-doc-img"  >
                <img [src]="avatarSrc"alt="User Image" />
              </a> -->
              <div class="profile-det-info">
                <h3 ><a class="label" >Dossier N°:{{ appointment.ref }}
                    
                </a></h3>
                <h3 ><a class="label" >{{ appointment.firstName }} {{ appointment.lastName }}
                    
                </a></h3>
               
                
                <div class="patient-details">
                  <h5 *ngIf="appointment.email"><b>Email:</b> {{appointment.email}}</h5>
                  <h5 class="mb-0" *ngIf="appointment.mobilePhone1">
                    <b>Telephone:</b>  {{appointment.mobilePhone1 }}
                  </h5>
               
                </div>
         
                
              </div>
            </div>
          </div>
          <div class="paiement">
            <h5 class="mb-0" >
                <b>Consultations:</b>  {{ appointment.regularConsultationAmount | dashboradPaiement }} DT
              </h5><h5 class="mb-0" >
                <b>Actes:</b>  {{ appointment.regularConsultationEntriesAmount | dashboradPaiement}} DT
              </h5><h5 class="mb-0" >
                <b>Total:</b>  {{appointment.regularConsultationAmount + appointment.regularConsultationEntriesAmount | dashboradPaiement}} DT
              </h5><h5 class="mb-0" >
                <b>Payé:</b>  {{ appointment.paidAmount | dashboradPaiement }} DT
              </h5><h5 class="mb-0" >
                <b >Reste:</b>  <span [ngStyle]="{'color': (appointment.regularConsultationAmount + appointment.regularConsultationEntriesAmount)-appointment.paidAmount < 0 ? 'green' : 'red' }" style="font-family: 'Gotham Rounded Bold'">{{(appointment.regularConsultationAmount + appointment.regularConsultationEntriesAmount)-appointment.paidAmount | dashboradPaiement}} </span> DT
              </h5>

        </div>
        
           
             
        </div>
      </div>
    
    </div>
    
    </div>
    <ng-template #empty>
      <tr>
          <td colspan="8" style="text-align: center;">
              <span style="text-align: center; width: 100%;">
                  Pas de Paiement !
              </span>
          </td>
      </tr>
  </ng-template>

