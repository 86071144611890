import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreatePatientComponent } from './create-patient.component/create-patient.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from 'src/app/common/common.module';
import { CreateRendezVousComponent } from './create-rendez-vous.component/create-rendez-vous.component';
import { DisponibiliteFilterPipe } from './disponibilite-filter.pipe';
import { CreateRendezVousSecretaryComponent } from './sec-calendar/create-rendez-vous-secretary.component/create-rendez-vous-secretary.component';
import { CreatePatientSecretaryComponent } from './sec-calendar/create-patient-secretary.component/create-patient-secretary.component';
import { UpdatePatientSecretaryComponent } from './sec-calendar/update-patient-secretary.component/update-patient-secretary.component';
import { UpdateRendezVousComponent } from './update-rendez-vous.component/update-rendez-vous.component';



@NgModule({
  declarations: [
    CreatePatientComponent,
    CreateRendezVousComponent,
    DisponibiliteFilterPipe,
    CreateRendezVousSecretaryComponent,
    CreatePatientSecretaryComponent,
    UpdatePatientSecretaryComponent,
    UpdateRendezVousComponent
  ],
  imports: [
    CommonModule,
    AppCommonModule,
    FormsModule,
    ReactiveFormsModule,
    
  ],
  exports:[
    CreatePatientComponent,
    CreateRendezVousComponent,
    CreateRendezVousSecretaryComponent,
    CreatePatientSecretaryComponent,
    UpdatePatientSecretaryComponent,
    UpdateRendezVousComponent
  ]
})
export class DoctorCalendarModule { }
