import { Pipe, PipeTransform } from '@angular/core';
import { EDalyTimeSlotLocation } from 'src/app/entities/daly-time-slot.entity/daly-time-slot';
import { DoctorAvailability } from 'src/app/entities/doctor-disponiblity.entity/doctor-availability';

@Pipe({
  name: 'disponibiliteFilter'
})
export class DisponibiliteFilterPipe implements PipeTransform {

  transform(listDisponibilite: Array<DoctorAvailability>, disponiblitieLocation: EDalyTimeSlotLocation): Array<DoctorAvailability> {
    
    return listDisponibilite.filter(e => e.location == disponiblitieLocation || e.location == EDalyTimeSlotLocation.BOTH);
  }

}
