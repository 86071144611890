<div>
    <input type="text" class="form-control my-2"   [(ngModel)]="searchText"  placeholder="Rechercher {{filter}}">
    <nav class="tree-nav scroll" >

        <div class="flex" *ngFor="let filter of listeFilter |textFillingsPipe:searchText ;let i= index" >
            <span class="label">{{filter.label}}</span>
            <input type="checkbox" name="" id=""  class="checkbox" (change)="changed(filter,$event,i)"   >
        </div>
        </nav>
        <button class="btn btn-primary ajouter" (click)="close()">Ajouter</button>
</div>
