import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../../core/entities/api-response.entity/api-response';
import { ApiService } from '../../core/services/api.service/api.service';
import { Doctor } from '../../entities/user.entity/doctor.entity/index';
import { PageRequest } from 'src/app/common/modules/data-table.module/interfaces';

@Injectable({
  providedIn: 'root'
})
export class DoctorsService extends ApiService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  add(data: any): Observable<Doctor> {
    return this.http.post<ApiResponse>(`${environment.API_URL}/manage/doctors`, data, { responseType: 'json' }).pipe(this.responsePipe);
  }

  tags(): Observable<Array<{ id_tag: string, tag_name: string }>> {
    return this.http.get<Array<{ id_tag: string, tag_name: string }>>(`${environment.API_URL}/tags`, { responseType: 'json' });
  }

  getMotifs(): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctor/tags/motif`).pipe(this.responsePipe)
  }

  updateProfile(data: FormData): Observable<Doctor> {
    return this.http.put<ApiResponse>(`${environment.API_URL}/doctor`, data, { responseType: 'json' }).pipe(this.responsePipe);
  }

  searchDoctor(name: String): Observable<Array<Doctor>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/search?q=${name}`, { responseType: 'json' }).pipe(this.responsePipe)
  }

  one(id: string): Observable<any> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/${id}`).pipe(this.responsePipe)
  }
  // all(): Observable<any> {
  //   return this.http.get<ApiResponse>(`${environment.API_URL}/doctors}`).pipe(this.responsePipe)
  // }


  getAllsubSpecialites(): Observable<Array<any>> {
    return this.http.get<ApiResponse>(`${environment.API_URL}/backoffice/specialites/sub`).pipe(this.responsePipe)
  }

  // get all
  allDoctor(pageRequest: PageRequest): Observable<any> {
    let params = new HttpParams();
    if (pageRequest!.pageOffset) params = params.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) params = params.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/search`, { params: params, responseType: 'json' }).pipe(this.responsePipe);
  }
  // search
  search(query?: string, speciality?: string, pageRequest?: PageRequest): Observable<Array<any>> {
    let queryParams: HttpParams = new HttpParams()
    if (query) queryParams = queryParams.append('q', query!);
    if (speciality) queryParams = queryParams.append('spec', speciality!);
    if (pageRequest!.pageOffset) queryParams = queryParams.append('page', pageRequest!.pageOffset)
    if (pageRequest!.pageSize) queryParams = queryParams.append('size', pageRequest!.pageSize);
    return this.http.get<ApiResponse>(`${environment.API_URL}/doctors/search`, {
      params: queryParams
    }).pipe(this.responsePipe);
  }
}
