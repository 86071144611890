import { Pipe, PipeTransform } from '@angular/core';
import { TextFillings } from 'src/app/entities/text-fillings.entity/text-fillings';

@Pipe({
  name: 'textFillingsPipe'
})
export class TextFillingsPipePipe implements PipeTransform {

  transform(TextFillings: TextFillings[], text: string): any {
    if(text){
     return TextFillings.filter((elm)=>elm.label.toLowerCase().includes(text.toLowerCase()))
    }
    return TextFillings;
  }

}
