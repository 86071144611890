import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import * as moment from 'moment';
import { DoctorDashboardService } from 'src/app/services/doctor-dashboard.service/doctor-dashboard.service';

@Component({
  selector: 'app-chart-patient-periode',
  templateUrl: './chart-patient-periode.component.html',
  styleUrls: ['./chart-patient-periode.component.css']
})
export class ChartPatientPeriodeComponent implements OnInit {

  constructor(private doctorDashboardService: DoctorDashboardService) {}
  public chart!: Chart;
  startDate = new Date(new Date().getFullYear(), new Date().getMonth()-1);
  finDate: Date =  new Date(new Date().getFullYear(), new Date().getMonth() , 0);

  public rdvs: Array<number> = [];

  ngOnInit() {
    this.staticPatient();
  }

 
  staticPatient() {
    const startDate = new Date(this.startDate).getTime();
    const endDate = new Date(this.finDate).getTime();
    this.doctorDashboardService.staticPatient(startDate, endDate).subscribe((res) => {
     
      if (this.chart) {


        this.chart.destroy()
      }
      this.chart = new Chart('patient-peroide', {
        type: "line",

        data: {
          labels: res.map((elm) => moment(new Date(elm.creationDate)).format("DD/MM/YYYY")),
          datasets: [
            {
              label: `Nombre de Patients Entre ${moment(startDate).format("DD/MM/YYYY")} Et ${moment(endDate).format("DD/MM/YYYY")}`,
              data: res.map((elm)=>elm.pCount),
              fill:true,
              backgroundColor: [
                "transparent"

              ],
              borderColor: [
                "rgba(168, 50, 117,1)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
                
              ],
              borderWidth: 1,
              tension:0.6
            },
          ],
        },
        options: {
          responsive: true
          
        },
      });
     
    });
  }

}
